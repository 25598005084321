import React, { useEffect, useState } from "react";
import { FaXmark } from "react-icons/fa6";
import { FaPlus } from "react-icons/fa";
import { v4 as uuidv4 } from "uuid";
import { FaRedoAlt } from "react-icons/fa";
import Input from "./Input";

const ApplyOODTimesheet = ({
  tasks,
  setTasks,
  resetTasks,
  errors,
  setErrors,
}) => {
  const addTask = () => {
    setTasks((state) => [
      ...state,
      { id: uuidv4(), description: "", timeHrs: "", timeMins: "" },
    ]);
  };

  const removeTask = (id) => {
    setTasks(tasks.filter((task, i) => id !== task.id));
  };

  const handleChange = (index, id, field, value) => {
    const updatedTasks = tasks.map((task) =>
      task.id === id ? { ...task, [field]: value } : task
    );

    setErrors((prevErrors) => ({
      ...prevErrors,
      [index]: {
        ...prevErrors[index],
        [field]: "",
      },
    }));

    setTasks(updatedTasks);
  };

  const [totalTime, setTotalTime] = useState({
    hours: "",
    minutes: "",
  });

  const calcTotalTime = () => {
    let totalHrs = 0;
    let totalMins = 0;

    tasks?.map((task) => {
      totalHrs += parseInt(task.timeHrs || 0);
      totalMins += parseInt(task.timeMins || 0);
    });

    totalHrs += Math.floor(totalMins / 60);
    totalMins = totalMins % 60;

    const finalTotalTime = {
      hours: totalHrs,
      minutes: totalMins,
    };
    setTotalTime(finalTotalTime);
  };

  useEffect(() => {
    calcTotalTime();
  }, [tasks]);

  return (
    <div className="timesheet apply-timesheet">
      <div className="d-flex mb-3">
        <h2 className="label fs-6">Timesheet</h2>
        <div
          className="timesheet-total-time d-flex gap-2 align-items-center justify-content-center"
          style={{ backgroundColor: "#e5e9f1" }}
        >
          <span>Total Time</span>
          <div className="d-flex">
            <div
              className="d-flex align-items-center justify-content-center"
              style={{ gap: "2px" }}
            >
              <span>{totalTime.hours}</span>
              <span>Hrs</span>
            </div>
            {totalTime.minutes > 0 && (
              <>
                <span className="d-flex align-items-center justify-content-center px-2">
                  :
                </span>
                <div
                  className="d-flex align-items-center justify-content-center"
                  style={{ gap: "2px" }}
                >
                  <span>{totalTime.minutes}</span>
                  <span>Mins</span>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="d-grid">
        <div className="row timesheet-header">
          <div className="col-md-1">S.No.</div>
          <div className="col-md-6">Task</div>
          <div className="col-md-4">Time</div>
          <div className="col-md-1"></div>
        </div>
        <div className="timesheet-content">
          {tasks.map((task, index) => {
            return (
              <div className="row timesheet-row inputs" key={task.id}>
                <div className="col-md-1">{index + 1}</div>
                <div className="col-md-6">
                  <div style={{ position: "relative" }}>
                    <div className="input-group">
                      <textarea
                        style={{ resize: "none" }}
                        value={task.description}
                        onChange={(e) =>
                          handleChange(
                            index,
                            task.id,
                            "description",
                            e.target.value
                          )
                        }
                        required
                      />
                      {errors[index]?.description && (
                        <small className="text-danger">
                          {errors[index]?.description}
                        </small>
                      )}
                    </div>
                    {index === tasks.length - 1 && (
                      <button
                        className="button button-add-timesheet-row"
                        onClick={addTask}
                      >
                        <FaPlus fontSize="0.75rem" />
                      </button>
                    )}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="d-flex flex-column gap-2">
                    <div className="d-flex">
                      <div className="d-flex align-items-center justify-content-center gap-2">
                        <div className="input-group">
                          <Input
                            type="text"
                            value={task.timeHrs}
                            min={1}
                            max={23}
                            required={!task.timeMins}
                            restrict={["space", "special", "alpha"]}
                            onChange={(e) => {
                              let newValue = e.target.value;

                              if (!(newValue === "" || isNaN(newValue))) {
                                if (newValue > 23) {
                                  newValue = 23;
                                }

                                if (newValue < 1) {
                                  newValue = 1;
                                }
                              }

                              handleChange(index, task.id, "timeHrs", newValue);
                            }}
                          />
                        </div>
                        <span>Hrs</span>
                      </div>
                      <span className="d-flex align-items-center justify-content-center px-2">
                        :
                      </span>
                      <div className="d-flex align-items-center justify-content-center gap-2">
                        <div className="input-group">
                          <Input
                            type="text"
                            value={task.timeMins}
                            min={1}
                            max={59}
                            required={!task.timeHrs}
                            restrict={["space", "special", "alpha"]}
                            onChange={(e) => {
                              let newValue = e.target.value;

                              if (!(newValue === "" || isNaN(newValue))) {
                                if (newValue > 59) {
                                  newValue = 59;
                                }

                                if (newValue < 1) {
                                  newValue = 1;
                                }
                              }

                              handleChange(
                                index,
                                task.id,
                                "timeMins",
                                newValue
                              );
                            }}
                          />
                        </div>
                        <span>Mins</span>
                      </div>
                    </div>
                    <div className="d-flex flex-column align-items-center justify-content-center">
                      {errors[index]?.timeHrs && (
                        <small className="text-danger">
                          {errors[index]?.timeHrs}
                        </small>
                      )}
                      {errors[index]?.timeMins && (
                        <small className="text-danger">
                          {errors[index]?.timeMins}
                        </small>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-1">
                  {tasks.length === 1 ? (
                    <button
                      className="button button-reset-timesheet-row"
                      onClick={resetTasks}
                    >
                      <FaRedoAlt fontSize="0.75rem" />
                    </button>
                  ) : (
                    <button
                      className="button button-remove-timesheet-row"
                      onClick={() => removeTask(task.id)}
                    >
                      <FaXmark />
                    </button>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ApplyOODTimesheet;
