import Cookies from "js-cookie";
import React, { useState } from "react";
import { TailSpin } from "react-loader-spinner";
import { putApi } from "../../utils/api";
import { API_APPROVE_REIMBURSEMENT } from "../../config/Endpoints";
import { toast } from "react-toastify";
import parse from "html-react-parser";
import * as Yup from "yup";
import validations from "../../config/validation";

const ApproveReimbursementRequestForm = ({
  reimbursementId,
  selectedReimbursementRequest,
  reimbursementStatusList,
  fetchList,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const accessToken = Cookies.get("accessToken");
  const [formData, setFormData] = useState({
    action: "",
    comment: "",
    reimbursementAmount: "",
  });
  const [errors, setErrors] = useState("");
  const validationSchema = Yup.object().shape({
    comment: validations.COMMENT_VALIDATION,
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await validationSchema.validate(formData, {
        abortEarly: false,
      });

      const maxAmount = selectedReimbursementRequest.reimbursementAmount;

      if (formData.reimbursementAmount <= maxAmount) {
        setIsLoading(true);
        await putApi(
          API_APPROVE_REIMBURSEMENT +
            "?reimbursementRequestId=" +
            reimbursementId +
            "&comment=" +
            encodeURIComponent(formData.comment) +
            "&status=" +
            formData.action +
            "&approvedAmount=" +
            formData.reimbursementAmount,
          {},
          accessToken
        )
          .then((res) => {
            if (res.status === 200) {
              setIsLoading(false);
              toast.success(res.data.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
              fetchList();
              const modalClose =
                document.getElementsByClassName("cancel-modal")[0];
              modalClose.click();
            } else {
              setIsLoading(false);
              toast.error(res.data.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
              const modalClose =
                document.getElementsByClassName("cancel-modal")[0];
              modalClose.click();
            }
          })
          .catch((e) => {
            setIsLoading(false);
            toast.error("Something went wrong please try again.", {
              position: toast.POSITION.TOP_RIGHT,
            });
          });
      } else {
        toast.error("The amount field cannot exceed the requested amount.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      setIsLoading(false);
      const validationErrors = {};
      error.inner.forEach((err) => {
        validationErrors[err.path] = err.message;
      });
      setErrors(validationErrors);
    }
  };

  const handleReset = (e) => {
    e.preventDefault();
    setFormData({
      action: "",
      comment: "",
      reimbursementAmount: "",
    });
    setErrors({});
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

    setErrors((prevFormData) => ({
      ...prevFormData,
      [name]: "",
    }));
  };

  return (
    <>
      {isLoading && (
        <div className="overlay">
          <div className="loader-container">
            <TailSpin
              height="100"
              width="100"
              radius="1"
              color="blue"
              ariaLabel="tail-spin-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
        </div>
      )}
      <div className="modal-header">
        <h5 className="modal-title text-center flex" id="staticBackdropLabel">
          Reimbursement Action
        </h5>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={handleReset}
        ></button>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="modal-body">
          <div className="table-responsive">
            <table className="resume custom">
              {/* Table Headings */}
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Employee Name</th>
                  <th> Type</th>
                  <th>Amount</th>
                  <th>Requested Date</th>
                  <th>Status</th>
                </tr>
              </thead>

              {/* Table Body */}
              <tbody>
                <tr>
                  <td data-title="reimbursementViewId">
                    {selectedReimbursementRequest?.reimbursementViewId}
                  </td>
                  <td data-title="employeeName">
                    {selectedReimbursementRequest?.employeeName}
                  </td>
                  <td data-title="reimbursementType">
                    {selectedReimbursementRequest?.reimbursementType}
                  </td>
                  <td data-title="reimbursementAmount ">
                    {selectedReimbursementRequest?.reimbursementAmount}.00
                  </td>
                  <td data-title="requestDate">
                    {selectedReimbursementRequest?.requestDate}
                  </td>
                  <td data-title="trackingStatus">
                    {selectedReimbursementRequest?.trackingStatus}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="row">
            <div className="col-md-2"></div>
            <div className="col-md-4">
              <label htmlFor="action" className="has-asterisk">
                Action
              </label>
            </div>

            <div className="col-md-4">
              <select
                id="action"
                required
                name="action"
                className="border"
                value={formData.action}
                onChange={handleInputChange}
              >
                <option value="">Select Action</option>
                {reimbursementStatusList?.map((item, index) => (
                  <option value={item} keyy={index}>
                    {item}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-md-2"></div>
          </div>
          <div className="row mt-3">
            <div className="col-md-2"></div>
            <div className="col-md-4">
              <label htmlFor="usercomment">User Comment</label>
            </div>
            <div className="col-md-4">
              {selectedReimbursementRequest?.userComment !== null && (
                <li>{parse(`${selectedReimbursementRequest?.userComment}`)}</li>
              )}
            </div>
            <div className="col-md-2"></div>
          </div>
          {selectedReimbursementRequest?.firstApproverComment !== null && (
            <div className="row mt-3">
              <div className="col-md-2"></div>
              <div className="col-md-4">
                <label htmlFor="comment">First Approver Comment</label>
              </div>
              <div className="col-md-4">
                {selectedReimbursementRequest?.firstApproverComment !==
                  null && (
                  <li>
                    {parse(
                      `${selectedReimbursementRequest?.firstApproverComment}`
                    )}
                  </li>
                )}
              </div>
              <div className="col-md-2"></div>
            </div>
          )}
          {selectedReimbursementRequest?.secondApproverComment !== null && (
            <div className="row mt-3">
              <div className="col-md-2"></div>
              <div className="col-md-4">
                <label htmlFor="comment">Second Approver Comment</label>
              </div>
              <div className="col-md-4">
                {selectedReimbursementRequest?.secondApproverComment !==
                  null && (
                  <li>
                    {parse(
                      `${selectedReimbursementRequest?.secondApproverComment}`
                    )}
                  </li>
                )}
              </div>
              <div className="col-md-2"></div>
            </div>
          )}
          {selectedReimbursementRequest?.thirdApproverComment !== null && (
            <div className="row mt-3">
              <div className="col-md-2"></div>
              <div className="col-md-4">
                <label htmlFor="comment">Third Approver Comment</label>
              </div>
              <div className="col-md-4">
                {selectedReimbursementRequest?.thirdApproverComment !==
                  null && (
                  <li>
                    {parse(
                      `${selectedReimbursementRequest?.thirdApproverComment}`
                    )}
                  </li>
                )}
              </div>
              <div className="col-md-2"></div>
            </div>
          )}
          <div className="row mt-3">
            <div className="col-md-2"></div>
            <div className="col-md-4">
              <label htmlFor="reimbursementAmount" className="has-asterisk">
                Amount
              </label>
            </div>
            <div className="col-md-4">
              <input
                type="tel"
                className="border"
                name="reimbursementAmount"
                onChange={handleInputChange}
                value={formData.reimbursementAmount}
                required={
                  formData.action === "DISCREPANCY" ||
                  formData.action === "REJECTED"
                    ? false
                    : true
                }
                onKeyPress={(e) => {
                  const isDigit = /\d/.test(e.key);
                  if (!isDigit) {
                    e.preventDefault();
                  }
                }}
                onPaste={(e) => {
                  e.preventDefault();
                }}
              />
            </div>
            <div className="col-md-2"></div>
          </div>
          <div className="row mt-3">
            <div className="col-md-2"></div>
            <div className="col-md-4">
              <label htmlFor="comment" className="has-asterisk">
                Comment
              </label>
            </div>
            <div className="col-md-4">
              <textarea
                rows={3}
                cols={5}
                className="border"
                name="comment"
                value={formData.comment}
                onChange={handleInputChange}
                required
              ></textarea>
              {errors.comment && (
                <small className="text-danger">{errors.comment}</small>
              )}
            </div>
            <div className="col-md-2"></div>
          </div>
          <div className="row mt-3">
            <div className="col-md-2"></div>
            <div className="col-md-4">
              <label htmlFor="comment">Attachment</label>
            </div>
            <div className="col-md-4">
              <a
                href={
                  process.env.REACT_APP_IMAGE_URL +
                  selectedReimbursementRequest.filePath
                }
                download
                target="_blank"
              >
                view
              </a>
            </div>
            <div className="col-md-2"></div>
          </div>
        </div>

        <div className="justify-content-center modal-footer">
          <button
            type="submit"
            className={isLoading ? "theme-button disabled " : "theme-button "}
          >
            Submit
          </button>
          <button
            type="button"
            className="theme-button bg-grey mx-3 w-35 cancel-modal"
            data-bs-dismiss="modal"
            onClick={handleReset}
          >
            Cancel
          </button>
        </div>
      </form>
    </>
  );
};

export default ApproveReimbursementRequestForm;
