import React, { useState } from "react";

const Input = ({
  type = "text",
  name,
  value,
  onChange,
  placeholder,
  restrict = [], // can block "space", "number", "alpha", "special"
  whitelist = [], // can allow any character - to be used alongside a blocked category in restrict prop
  className = "",
  style = {},
  required = false,
  disabled,
  autoComplete,
  max,
  min,
  pattern,
  onInput,
}) => {
  const isAllowedChar = (ch) => {
    if (restrict.includes("space") && ch === " " && !whitelist.includes(" ")) {
      return false;
    }
    if (
      restrict.includes("number") &&
      /[0-9]/.test(ch) &&
      !whitelist.includes(ch)
    ) {
      return false;
    }
    if (
      restrict.includes("alpha") &&
      /[a-zA-Z]/.test(ch) &&
      !whitelist.includes(ch)
    ) {
      return false;
    }
    if (
      restrict.includes("special") &&
      /[^a-zA-Z0-9 ]/.test(ch) &&
      !whitelist.includes(ch)
    ) {
      return false;
    }
    return true;
  };

  const handleChange = (e) => {
    let newValue = e.target.value;
    if (type === "number") {
      if (newValue === "" || isNaN(newValue)) {
      } else {
        if (max !== undefined && newValue > max) {
          newValue = max;
        }
        if (min !== undefined && newValue < min) {
          newValue = min;
        }
      }
    }

    if (type === "text") {
      newValue = newValue
        .split("")
        .filter((ch) => isAllowedChar(ch))
        .join("");
    }

    onChange({ target: { name, value: newValue } });
  };

  const handleKeyDown = (e) => {
    if (
      e.key === " " &&
      restrict.includes("space") &&
      !whitelist.includes(" ")
    ) {
      e.preventDefault();
    }
  };

  const handlePaste = (e) => {
    let pastedText = e.clipboardData.getData("text");
    for (let ch of pastedText) {
      if (!isAllowedChar(ch)) {
        e.preventDefault();
        return;
      }
    }
  };

  return (
    <div className="input-wrapper">
      <input
        type={type}
        name={name}
        pattern={pattern}
        onInput={onInput}
        value={value}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onPaste={handlePaste}
        placeholder={placeholder}
        className={`input ${className}`}
        style={style}
        required={required}
        disabled={disabled}
        autoComplete={autoComplete}
      />
    </div>
  );
};

export default Input;
