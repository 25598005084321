import React, { useEffect, useState } from "react";
import SideNavbar from "../components/SideNavbar";
import { Route, Routes, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { TailSpin } from "react-loader-spinner";
import { getApi } from "../utils/api";
import { MENU_USER } from "../config/Endpoints";
import { toast } from "react-toastify";
import { logoutUser } from "../utils/Storage";

import {
  dashboardRouteComponents,
  dbIndependentRoutes,
} from "../config/routeComponents";

function Home() {
  const [menuList, setMenuList] = useState([]);
  const [isLoading, setIsLoading] = useState(null);
  const accessToken = Cookies.get("accessToken");
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    getApi(MENU_USER, accessToken)
      .then((res) => {
        if (res.data.code === "200") {
          setMenuList(res.data.data);
          setIsLoading(false);
        } else if (res.data.code === "500") {
          toast.error("Something went wrong please try again !!", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsLoading(false);
        } else {
          navigate("/");
          setIsLoading(false);
        }
      })
      .catch((e) => {
        toast.error("Something went wrong please try again !!", {
          position: toast.POSITION.TOP_RIGHT,
        });
        logoutUser();
        console.log(e);
        setIsLoading(false);
      });
  }, []);

  return (
    <>
      {isLoading && (
        <div className="overlay">
          <div className="loader-container">
            <TailSpin
              height="100"
              width="100"
              radius="1"
              color="blue"
              ariaLabel="tail-spin-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
        </div>
      )}

      <div className="wrapper">
        {!isLoading && isLoading !== null && (
          <>
            <SideNavbar menu={menuList} />
            <Routes>
              <Route path="/dashboard">
                {menuList?.map((item, index) => {
                  const Component = dashboardRouteComponents[item.achorValue];

                  // Main route (i.e. /dashboard)
                  if (item.achorValue === "/" && Component) {
                    return (
                      <Route
                        key={`main-${index}`}
                        index={true}
                        element={
                          <Component
                            title={item.name}
                            description={item.description}
                          />
                        }
                      />
                    );
                  }

                  // Sub-routes (i.e. /dashboard/*)
                  return (
                    <React.Fragment key={`frag-${index}`}>
                      {item.achorValue && Component && (
                        <Route
                          key={`item-${index}`}
                          path={item.achorValue.slice(1)}
                          element={
                            <Component
                              title={item.name}
                              description={item.description}
                            />
                          }
                        />
                      )}
                      {item.subMenu?.map((subItem, index) => {
                        const Component =
                          dashboardRouteComponents[subItem.achorValue];

                        if (subItem.achorValue && Component) {
                          return (
                            <Route
                              key={`subItem-${index}`}
                              path={subItem.achorValue.slice(1)}
                              element={
                                <Component
                                  title={subItem.name}
                                  description={subItem.description}
                                />
                              }
                            />
                          );
                        }

                        return null;
                      })}
                    </React.Fragment>
                  );
                })}
                {dbIndependentRoutes.map((item, index) => {
                  const { path, title, description } = item;
                  const Component = dashboardRouteComponents[path];

                  return (
                    <Route
                      key={`item-${index}`}
                      path={path.slice(1)}
                      element={
                        <Component title={title} description={description} />
                      }
                    />
                  );
                })}
              </Route>
            </Routes>
          </>
        )}
      </div>
    </>
  );
}

export default Home;
