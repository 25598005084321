import React, { useEffect, useState } from "react";
import { TailSpin, ThreeDots } from "react-loader-spinner";
import { Link } from "react-router-dom";
import { APPLY_LEAVE, LEAVE_TYPE, LEAVE_TYPE_V2 } from "../../config/Endpoints";
import Cookies from "js-cookie";
import { getApi, postApi } from "../../utils/api";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { getUser } from "../../utils/Storage";
import DatePicker from "react-datepicker";
import moment from "moment";
import { formatLeaveType } from "../../utils/helpers";
import validations from "../../config/validation";

const ApplyLeave = ({ fetchList, leaveBalance, fetchLeaveBalance }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isListLoading, setIsListLoading] = useState(false);
  const [leaveTypeList, setLeaveTypeList] = useState([]);
  const [formData, setFormData] = useState({
    selectedLeaveType: "",
    selectedConsiderLeaveType: "",
    startDate: "",
    endDate: "",
    isConsiderLeave: false,
    reasonOfLeave: "",
  });
  const [errors, setErrors] = useState({});
  const userData = JSON.parse(getUser("userData"));
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isLeaveBalanceZero, setIsLeaveBalanceZero] = useState(false);
  const accessToken = Cookies.get("accessToken");

  useEffect(() => {
    fetchLeaveType();
  }, []);

  const fetchLeaveType = async () => {
    setIsListLoading(true);
    await getApi(LEAVE_TYPE_V2, accessToken)
      .then((res) => {
        if (res.status === 200) {
          setLeaveTypeList(res.data.data);
          setIsListLoading(false);
        }
      })
      .catch((e) => {
        console.log(e);
        setIsListLoading(false);
      });
  };

  const handleInputChange = (event) => {
    setErrors({});

    const { name, value, type } = event.target;
    if (type === "date") {
      const year = value.split("-")[0];
      if (year.length <= 4) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: value,
        }));
      }
    } else {
      if (name === "selectedLeaveType") {
        const balance = getLeaveBalance(value);

        if (value === "HalfDayLeave") {
          const balancePrivilege = getLeaveBalance("PrivilegeLeave");
          // Check balance for PrivilegeLeave if halfDayLeave is selected
          const privilegeLeaveBalance = balancePrivilege.find(
            (item) => item.balance === 0
          );
          if (privilegeLeaveBalance && privilegeLeaveBalance.balance === 0) {
            setIsLeaveBalanceZero(true); // Set to true if PrivilegeLeave balance is 0
            setIsConfirmModalOpen(true); // Open confirmation modal
          } else {
            setIsLeaveBalanceZero(false);
          }
        } else {
          // Original check for other leave types
          if (
            balance &&
            balance.every(
              (item) => item.balance === 0 && item.key !== "HalfDayLeave"
            )
          ) {
            setIsLeaveBalanceZero(true); // Set to true if balance is 0
            setIsConfirmModalOpen(true); // Open confirmation modal
          } else {
            setIsLeaveBalanceZero(false);
          }
        }
      }
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
    setErrors((prevFormData) => ({
      ...prevFormData,
      [name]: "",
    }));
  };

  const onChange = (dates) => {
    const [start, end] = dates;
    setFormData((prevFormData) => ({
      ...prevFormData,
      startDate: start,
      endDate: end,
    }));
    setErrors({});
  };

  const handleReset = () => {
    setFormData({
      selectedLeaveType: "",
      selectedConsiderLeaveType: "",
      startDate: "",
      endDate: "",
      isConsiderLeave: false,
      reasonOfLeave: "",
    });
    setErrors({});
    setIsLoading(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setErrors({});
      await ApplyLeaveSchema.validate(
        {
          startDate: formData.startDate,
          endDate: formData.endDate,
          reasonOfLeave: formData.reasonOfLeave,
        },
        { abortEarly: false }
      );
      setIsLoading(true);
      let requestObj = {
        considerLeave: formData.isConsiderLeave,
        considerLeaveType: formData.selectedConsiderLeaveType,
        endDate: moment(formData.endDate).format("YYYY-MM-DD"),
        reasonOfLeave: formData.reasonOfLeave,
        startDate: moment(formData.startDate).format("YYYY-MM-DD"),
        typeOfLeave: formData.selectedLeaveType,
      };

      await postApi(APPLY_LEAVE, requestObj, accessToken)
        .then((res) => {
          setIsLoading(false);
          toast.success(res?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          fetchList();
          fetchLeaveBalance();
          const modalClose =
            document.getElementsByClassName("cancel-modal-apply")[0];
          modalClose.click();
          //   fetchLeaveType();
        })
        .catch((e) => {
          setIsLoading(false);
          toast.error(e?.response?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    } catch (error) {
      const validationErrors = {};
      error.inner.forEach((err) => {
        validationErrors[err.path] = err.message;
      });

      setErrors(validationErrors);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  function getLeaveBalance(selectedLeaveType) {
    const lowercaseSelectedLeaveType = selectedLeaveType.toLowerCase();
    const matches = [];
    for (const key in leaveBalance) {
      if (
        key.toLowerCase() === lowercaseSelectedLeaveType ||
        key.toLowerCase().includes(lowercaseSelectedLeaveType)
      ) {
        matches.push({ balance: leaveBalance[key], key });
      }
    }
    if (matches.length > 0) {
      return matches;
    }
    return null; // or some default value if no match is found
  }
  const handleConfirmYes = () => {
    setIsConfirmModalOpen(false); // Close confirmation modal
  };

  const handleConfirmNo = () => {
    setIsConfirmModalOpen(false);
    setFormData((prevFormData) => ({
      ...prevFormData,
      selectedLeaveType: "",
    }));
  };

  // const handleOk = () => {
  //   const modalClose = document.getElementsByClassName("cancel-modal-apply")[0];
  //   modalClose.click(); // Close apply leave modal
  //   setIsConfirmModalOpen(false); // Close confirmation modal
  // };

  // const currentMonthFirstDay = new Date();
  // currentMonthFirstDay.setDate(1);

  // const currentMonthFirstDay = new Date();
  // const minDate = new Date();
  // minDate.setMonth(11); // December
  // minDate.setDate(1); // Set to the 1st
  // const maxDate = new Date();
  // maxDate.setMonth(maxDate.getMonth() + 2);

  const currentMonthFirstDay = new Date();
  currentMonthFirstDay.setDate(1); // Set to the 1st of the current month

  // const currentMonthLastDay = new Date(currentMonthFirstDay);
  // currentMonthLastDay.setMonth(currentMonthLastDay.getMonth() + 1); // Move to the next month
  // currentMonthLastDay.setDate(0); // Set to the last day of the current month

  return (
    <>
      {isListLoading && (
        <div className="overlay">
          <div className="mt-5">
            <TailSpin
              height="100"
              width="100"
              radius="1"
              color="blue"
              ariaLabel="tail-spin-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
        </div>
      )}
      <form onSubmit={handleSubmit}>
        <div className="modal-header">
          <h5 className="modal-title text-center flex" id="staticBackdropLabel">
            Apply Leave
          </h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={handleReset}
          ></button>
        </div>
        <div className="modal-body">
          <div className="row ">
            <div className="col-md-2"></div>
            <div className="col-md-4">
              <label htmlFor="leave_type_list" className="has-asterisk">
                Type of leave
              </label>
            </div>
            <div className="col-md-4 ">
              <select
                id="leave_type_list"
                value={formData.selectedLeaveType}
                required
                onChange={handleInputChange}
                name="selectedLeaveType"
                className="form-select"
              >
                <option value="">Select leave type</option>
                {leaveTypeList &&
                  leaveTypeList
                    .filter(
                      (list) =>
                        list?.leaveFor ===
                          userData?.gender.charAt(0).toLowerCase() ||
                        list?.leaveFor === "c"
                    )
                    .map((item, index) => (
                      <option value={item.leaveType} key={index}>
                        {item.leaveTypeName}
                      </option>
                    ))}
              </select>
              {formData.selectedLeaveType &&
                formData.selectedLeaveType !== "HalfDayLeave" && (
                  <>
                    {getLeaveBalance(formData.selectedLeaveType)?.map(
                      (item) => (
                        <span className="badge bg-warning text-center mt-2">
                          {formatLeaveType(item.key) + " : " + item.balance}
                        </span>
                      )
                    )}
                  </>
                )}
            </div>
            <div className="col-md-2"></div>
          </div>

          <div className="row mt-2">
            <div className="col-md-2"></div>
            <div className="col-md-4">
              <label htmlFor="startDate" className="has-asterisk">
                Date Range
              </label>
            </div>

            <div className="col-md-4">
              <DatePicker
                toggleCalendarOnIconClick
                placeholderText="mm/dd/yyyy - mm/dd/yyyy"
                selectsRange={true}
                startDate={formData.startDate}
                endDate={formData.endDate}
                onChange={onChange}
                className="border"
                isClearable={formData.startDate && formData.endDate}
                minDate={currentMonthFirstDay}
                // maxDate={currentMonthLastDay}
                showIcon
                required
                // onKeyDown={(e) => e.preventDefault()}
              />

              {errors.startDate && (
                <small className="text-danger">{errors.startDate}</small>
              )}
              {errors.endDate && (
                <small className="text-danger">{errors.endDate}</small>
              )}
              <br />
              <small className="text-danger pt-0">
                Note: The start and end dates are included in the leave.
              </small>
            </div>
            <div className="col-md-2"></div>
          </div>

          <div className="row mt-2">
            <div className="col-md-2"></div>
            <div className="col-md-4">
              <label htmlFor="reasonOfLeave" className="has-asterisk">
                Reason of Leave
              </label>
            </div>
            <div className="col-md-4">
              <textarea
                id="reasonOfLeave"
                name="reasonOfLeave"
                value={formData.reasonOfLeave}
                onChange={handleInputChange}
                rows={2}
                cols={30}
                className="border"
                required
              ></textarea>
              {errors.reasonOfLeave && (
                <small className="text-danger">{errors.reasonOfLeave}</small>
              )}
            </div>
            <div className="col-md-2"></div>
          </div>
        </div>
        <div className="justify-content-center modal-footer">
          <button
            type="submit"
            className={isLoading ? "theme-button disabled " : "theme-button "}
          >
            {isLoading ? (
              <ThreeDots
                height="20"
                width="53"
                radius="9"
                color="white"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />
            ) : (
              "Submit"
            )}
          </button>
          <button
            type="button"
            className="theme-button bg-grey mx-3 w-35 cancel-modal-apply"
            data-bs-dismiss="modal"
            onClick={handleReset}
          >
            Cancel
          </button>
        </div>
      </form>

      {isConfirmModalOpen && (
        <div
          className="modal fade show"
          id="staticBackdropConfirm"
          tabIndex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
          style={{ display: "block" }}
        >
          <div className="modal-dialog modal-full-height modal-right">
            <div className="modal-content">
              <div className="modal-body">
                <h5 className="text-center">
                  {formData.selectedLeaveType === "HalfYearly" &&
                    "You have an insufficient leave balance. Please get in touch with HR."}
                  {formData.selectedLeaveType === "HalfDayLeave" &&
                    // "Your leave balance is zero for the selected leave type. Are you sure you want to proceed?"

                    "Are you sure you want to continue, as your Privilege Leave balance is zero? If you apply for Half Day Leave, the deduction will be made from your salary."}
                  {formData.selectedLeaveType !== "HalfYearly" &&
                    formData.selectedLeaveType !== "HalfDayLeave" &&
                    "Are you sure you want to continue with the selected leave type since your leave balance is zero?"}
                </h5>
              </div>
              {formData.selectedLeaveType === "HalfYearly" && (
                <div className="justify-content-center modal-footer">
                  <button
                    type="button"
                    className="theme-button mx-3 w-35 cancel-modal-dependent-delete-attach"
                    onClick={handleConfirmNo}
                  >
                    OK
                  </button>
                </div>
              )}
              {formData.selectedLeaveType !== "HalfYearly" && (
                <div className="justify-content-center modal-footer">
                  <button
                    type="button"
                    className="theme-button"
                    onClick={handleConfirmYes}
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    className="theme-button bg-grey mx-3 w-35 cancel-modal-dependent-delete-attach"
                    onClick={handleConfirmNo}
                  >
                    No
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ApplyLeave;

const ApplyLeaveSchema = Yup.object().shape({
  startDate: Yup.date().nullable().required("Start Date is required"),
  endDate: Yup.date()
    .nullable()
    .required("End Date is required")
    .when("startDate", (startDate, schema) => {
      return schema.test({
        test: (endDate) => {
          return !startDate || new Date(endDate) >= new Date(startDate);
        },
        message: "'End Date' must be later than or equal to 'Start Date'",
      });
    }),
  reasonOfLeave: validations.REASON_VALIDATION,
});
