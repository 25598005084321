import { Link, useNavigate } from "react-router-dom";
import { FaChevronRight } from "react-icons/fa6";

const Breadcrumbs = ({ items }) => {
  return (
    <div className="d-flex">
      {items.map((item, index) => {
        const { path, label } = item;
        return (
          <span className="d-flex" key={index}>
            {index !== items.length - 1 ? (
              <Link className="h1" to={path}>
                {label}
              </Link>
            ) : (
              <h1 className="h1">{label}</h1>
            )}
            {index !== items.length - 1 && (
              <span className="mx-1">
                <FaChevronRight className="breadcrumbs-separator" />
              </span>
            )}
          </span>
        );
      })}
    </div>
  );
};

export default Breadcrumbs;
