import Cookies from "js-cookie";
import React, { useState } from "react";
import { TailSpin } from "react-loader-spinner";
import { putApi } from "../../utils/api";
import { API_REIMBURSEMENT_WITHDRAW } from "../../config/Endpoints";
import { toast } from "react-toastify";
import parse from "html-react-parser";
import * as Yup from "yup";
import validations from "../../config/validation";

const ReimbursementWithdrawRequest = ({
  reimbursementId,
  selectedReimbursementRequest,
  reimbursementStatusList,
  fetchList,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const accessToken = Cookies.get("accessToken");
  const [formData, setFormData] = useState({
    action: "WITHDRAW",
    comment: "",
  });
  const [errors, setErrors] = useState("");
  const validationSchema = Yup.object().shape({
    comment: validations.COMMENT_VALIDATION,
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      await validationSchema.validate(formData, {
        abortEarly: false,
      });
      await putApi(
        API_REIMBURSEMENT_WITHDRAW +
          "?reimbursementRequestId=" +
          reimbursementId +
          "&comment=" +
          encodeURIComponent(formData.comment) +
          "&status=" +
          formData.action,
        {},
        accessToken
      )
        .then((res) => {
          if (res.status === 200) {
            setIsLoading(false);
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            fetchList();
            const modalClose =
              document.getElementsByClassName("cancel-modal")[0];
            modalClose.click();
          } else {
            setIsLoading(false);
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            const modalClose =
              document.getElementsByClassName("cancel-modal")[0];
            modalClose.click();
          }
        })
        .catch((e) => {
          setIsLoading(false);
          toast.error(
            e?.response?.data?.message
              ? e?.response?.data?.message
              : "Something went wrong please try again.",
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
        });
    } catch (error) {
      setIsLoading(false);
      const validationErrors = {};
      error.inner.forEach((err) => {
        validationErrors[err.path] = err.message;
      });
      setErrors(validationErrors);
    }
  };

  const handleReset = (e) => {
    e.preventDefault();
    setFormData({
      action: "",
      comment: "",
    });
    setErrors({});
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

    setErrors((prevFormData) => ({
      ...prevFormData,
      [name]: "",
    }));
  };

  return (
    <form onSubmit={handleSubmit}>
      {isLoading && (
        <div className="overlay">
          <div className="loader-container">
            <TailSpin
              height="100"
              width="100"
              radius="1"
              color="blue"
              ariaLabel="tail-spin-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
        </div>
      )}
      <div className="modal-header">
        <h5 className="modal-title text-center flex" id="staticBackdropLabel">
          Withdraw Reimbursement
        </h5>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={handleReset}
        ></button>
      </div>
      <div className="modal-body">
        <div className="row">
          <div className="col-md-2"></div>
          <div className="col-md-4">
            <label htmlFor="action" className="has-asterisk">
              Action
            </label>
          </div>

          <div className="col-md-4">
            <select
              id="action"
              required
              name="action"
              className="border"
              value={formData.action}
              onChange={handleInputChange}
              disabled={true}
            >
              {/* <option value="">Select Action</option> */}
              <option value={"WITHDRAW"} selected>
                WITHDRAW
              </option>
            </select>
          </div>
          <div className="col-md-2"></div>
        </div>
        <div className="row mt-3">
          <div className="col-md-2"></div>
          <div className="col-md-4">
            <label htmlFor="usercomment">User Comment</label>
          </div>
          <div className="col-md-4">
            {selectedReimbursementRequest?.userComment !== null && (
              <li>{parse(`${selectedReimbursementRequest?.userComment}`)}</li>
            )}
          </div>
          <div className="col-md-2"></div>
        </div>
        {selectedReimbursementRequest?.firstApproverComment !== null && (
          <div className="row mt-3">
            <div className="col-md-2"></div>
            <div className="col-md-4">
              <label htmlFor="comment">First Approver Comment</label>
            </div>
            <div className="col-md-4">
              {selectedReimbursementRequest?.firstApproverComment !== null && (
                <li>
                  {parse(
                    `${selectedReimbursementRequest?.firstApproverComment}`
                  )}
                </li>
              )}
            </div>
            <div className="col-md-2"></div>
          </div>
        )}
        {selectedReimbursementRequest?.secondApproverComment !== null && (
          <div className="row mt-3">
            <div className="col-md-2"></div>
            <div className="col-md-4">
              <label htmlFor="comment">Second Approver Comment</label>
            </div>
            <div className="col-md-4">
              {selectedReimbursementRequest?.secondApproverComment !== null && (
                <li>
                  {parse(
                    `${selectedReimbursementRequest?.secondApproverComment}`
                  )}
                </li>
              )}
            </div>
            <div className="col-md-2"></div>
          </div>
        )}
        {selectedReimbursementRequest?.thirdApproverComment !== null && (
          <div className="row mt-3">
            <div className="col-md-2"></div>
            <div className="col-md-4">
              <label htmlFor="comment">Third Approver Comment</label>
            </div>
            <div className="col-md-4">
              {selectedReimbursementRequest?.thirdApproverComment !== null && (
                <li>
                  {parse(
                    `${selectedReimbursementRequest?.thirdApproverComment}`
                  )}
                </li>
              )}
            </div>
            <div className="col-md-2"></div>
          </div>
        )}
        <div className="row mt-3">
          <div className="col-md-2"></div>
          <div className="col-md-4">
            <label htmlFor="comment" className="has-asterisk">
              Comment
            </label>
          </div>
          <div className="col-md-4">
            <textarea
              rows={3}
              cols={5}
              className="border"
              name="comment"
              value={formData.comment}
              onChange={handleInputChange}
              required
            ></textarea>
            {errors.comment && (
              <small className="text-danger">{errors.comment}</small>
            )}
          </div>
          <div className="col-md-2"></div>
        </div>
        <div className="row mt-3">
          <div className="col-md-2"></div>
          <div className="col-md-4">
            <label htmlFor="comment">Attachment</label>
          </div>
          <div className="col-md-4">
            <a
              href={
                process.env.REACT_APP_IMAGE_URL +
                selectedReimbursementRequest.filePath
              }
              download
              target="_blank"
            >
              view
            </a>
          </div>
          <div className="col-md-2"></div>
        </div>
      </div>

      <div className="justify-content-center modal-footer">
        <button
          type="submit"
          className={isLoading ? "theme-button disabled " : "theme-button "}
        >
          Withdraw
        </button>
        <button
          type="button"
          className="theme-button bg-grey mx-3 w-35 cancel-modal"
          data-bs-dismiss="modal"
          onClick={handleReset}
        >
          Cancel
        </button>
      </div>
    </form>
  );
};

export default ReimbursementWithdrawRequest;
