import * as Yup from "yup";
import { capitalize } from "../utils/helpers";

const CURRENCY_VALIDATION = Yup.number()
  .typeError("Must be a valid number")
  .min(0, "Amount must be greater than or equal to 0")
  .max(1000000, "Amount must be less than or equal to 10,00,000 (10 Lacs)");

const NULL_VALIDATION = Yup.string()
  .transform((value) => value.trim())
  .required("Field is required");

const REASON_MIN_CHARS = 3;
const REASON_MAX_CHARS = 200;
const REASON_VALIDATION = Yup.string()
  .transform((value) => value.trim())
  .min(
    REASON_MIN_CHARS,
    `Reason must be at least ${REASON_MIN_CHARS} characters long. `
  )
  .max(
    REASON_MAX_CHARS,
    `Reason must not exceed ${REASON_MAX_CHARS} characters`
  )
  .required("Reason is required");

const COMMENT_MAX_CHARS = 200;
const COMMENT_VALIDATION = Yup.string()
  .transform((value) => value.trim())
  .max(
    COMMENT_MAX_CHARS,
    `Comment must not exceed ${COMMENT_MAX_CHARS} characters`
  )
  .required("Comment is required");

const ASSET_SERIAL_NUMBER_MAX_CHARS = 40;
const SERIAL_NUMBER_VALIDATION = Yup.string()
  .transform((value) => (typeof value === "string" ? value.trim() : value))
  .matches(/.*[A-Za-z0-9].*/, "Entry cannot contain only special characters")
  .max(
    ASSET_SERIAL_NUMBER_MAX_CHARS,
    `Serial Number must not exceed ${ASSET_SERIAL_NUMBER_MAX_CHARS} characters.`
  )
  .required("Serial Number is required");

const SPECIAL_AND_NUMBERS_VALIDATION = Yup.string()
  .transform((value) => (typeof value === "string" ? value.trim() : value))
  .matches(
    /.*[A-Za-z].*/,
    "Entry cannot contain only numbers and special characters"
  )
  .required("Field is required");

const ASSET_WARRANTY_YEAR_MAX_VALUE = 10;
const ASSET_WARRANTY_MONTH_MAX_VALUE = ASSET_WARRANTY_YEAR_MAX_VALUE * 12;
const warrantyValidation = (unit) => {
  return Yup.number()
    .nullable()
    .transform((value, originalValue) => {
      return originalValue === "" ? null : Number(originalValue);
    })
    .typeError("Must be a valid number")
    .positive("Value must be positive")
    .integer("Value must be an integer")
    .min(1, "Value must be greater than or equal to 1")
    .max(
      unit === "year"
        ? ASSET_WARRANTY_YEAR_MAX_VALUE
        : ASSET_WARRANTY_MONTH_MAX_VALUE,
      `${capitalize(unit)}s cannot be more than ${
        unit === "year"
          ? ASSET_WARRANTY_YEAR_MAX_VALUE
          : ASSET_WARRANTY_MONTH_MAX_VALUE
      }`
    );
};

const ASSET_MODEL_MAX_CHARS = 40;
const MODEL_VALIDATION = SPECIAL_AND_NUMBERS_VALIDATION.max(
  ASSET_MODEL_MAX_CHARS,
  `Model cannot exceed ${ASSET_MODEL_MAX_CHARS} characters.`
);

const ASSET_MANUFACTURER_MAX_CHARS = 40;
const MANUFACTURER_VALIDATION = SPECIAL_AND_NUMBERS_VALIDATION.max(
  ASSET_MANUFACTURER_MAX_CHARS,
  `Manufacturer cannot exceed ${ASSET_MANUFACTURER_MAX_CHARS} characters.`
);

const ASSET_RAM_MAX_VALUE = 1024;
const RAM_VALIDATION = Yup.number()
  .nullable()
  .transform((value, originalValue) => {
    return originalValue === "" ? null : Number(originalValue);
  })
  .typeError("Must be a valid number")
  .positive("Value must be positive")
  .integer("Value must be an integer")
  .min(1, "Value must be greater than or equal to 1")
  .max(ASSET_RAM_MAX_VALUE, `RAM cannot exceed ${ASSET_RAM_MAX_VALUE}gb`);

const ASSET_STORAGE_TB_MAX_VALUE = 10;
const ASSET_STORAGE_GB_MAX_VALUE = ASSET_STORAGE_TB_MAX_VALUE * 1000;
const storageValidation = (unit) => {
  return Yup.number()
    .nullable()
    .transform((value, originalValue) => {
      return originalValue === "" ? null : Number(originalValue);
    })
    .typeError("Must be a valid number")
    .positive("Value must be positive")
    .integer("Value must be an integer")
    .min(1, "Value must be greater than or equal to 1")
    .max(
      unit === "tb" ? ASSET_STORAGE_TB_MAX_VALUE : ASSET_STORAGE_GB_MAX_VALUE,
      `Value cannot exceed ${
        unit === "tb" ? ASSET_STORAGE_TB_MAX_VALUE : ASSET_STORAGE_GB_MAX_VALUE
      }${unit}`
    );
};

const validations = {
  CURRENCY_VALIDATION,
  NULL_VALIDATION,
  COMMENT_VALIDATION,
  REASON_VALIDATION,
  SERIAL_NUMBER_VALIDATION,
  SPECIAL_AND_NUMBERS_VALIDATION,
  warrantyValidation,
  MODEL_VALIDATION,
  MANUFACTURER_VALIDATION,
  RAM_VALIDATION,
  storageValidation,
};

export default validations;
