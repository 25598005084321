import { TailSpin, ThreeDots } from "react-loader-spinner";
import {
  API_ASSET_RETURNED_LIST,
  API_TOTAL_ASSET,
  ASSET_TYPE_LIST,
} from "../../config/Endpoints";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { getApi } from "../../utils/api";
import { toast } from "react-toastify";
import { formatDate, toggleMenu } from "../../utils/helpers";
import AssetReturnRequestModal from "./AssetReturnRequestModal";
import moment from "moment";
import Pagination from "../../components/Pagination";
import PageTitle from "../../components/PageTitle";
import Breadcrumbs from "../../components/Breadcrumbs";
import SortableTable from "../../components/SortableTable";

const AssetReturnedList = ({ title, description }) => {
  const [assetList, setAssetList] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const [filteredItems, setFilteredItems] = useState();
  const accessToken = Cookies.get("accessToken");
  const [formData, setFormData] = useState({
    filterOwner: "ALL",
    filterAssetType: "0",
  });

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalRequest, setTotalRequest] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const columns = [
    { key: "assetType", label: "Asset Type" },
    { key: "serialNo", label: "Serial Number" },
    { key: "owner", label: "Owner" },
    { key: "manufacture", label: "Manufacture" },
    {
      key: "purchaseDate",
      label: "Purchase Date",
      compare: (a, b) =>
        new Date(a._original.purchaseDate) - new Date(b._original.purchaseDate),
    },
    {
      key: "returnedDate",
      label: "Returned Date",
      compare: (a, b) =>
        new Date(a._original.returnedDate) - new Date(b._original.returnedDate),
    },
    { key: "returnedComment", label: "Returned Comment" },
    { key: "docketNo", label: "Docket Number" },
    { key: "status", label: "Status" },
    {
      key: "action",
      label: "Action",
      render: (item) => (
        <a
          href={process.env.REACT_APP_IMAGE_URL + item._original?.docketPath}
          target="_blank"
        >
          View
        </a>
      ),
      sortable: false,
    },
  ];
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    setTableData(
      filteredItems?.map((item) => ({
        assetType: item.assestType?.name,
        serialNo: item.serialNumber,
        owner: item.owner,
        manufacture: item.manufacture,
        purchaseDate: formatDate(item.purchaseDate),
        returnedDate: formatDate(item.returnedDate),
        returnedComment: item.returnComment,
        docketNo: item.docketNo,
        status: item.status,
        _original: item,
      })) || []
    );
  }, [filteredItems]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  useEffect(() => {
    fetchList();
  }, [currentPage, limit]);

  const handleLimitChange = (e) => {
    setLimit(e.target.value);
    setCurrentPage(1);
  };

  const handleSearch = (e) => {
    const searchValue = e.target.value;
    setSearchTerm(searchValue);
    const filtered = assetList.filter(
      (item) =>
        item.owner.toLowerCase().includes(searchValue.toLowerCase()) ||
        item.assestType.name
          .toLowerCase()
          .includes(searchValue.toLowerCase()) ||
        item.serialNumber.toLowerCase().includes(searchValue.toLowerCase()) ||
        item.docketNo.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredItems(filtered);
  };

  const fetchList = () => {
    setIsLoading(true);
    getApi(
      API_ASSET_RETURNED_LIST + `?pageSize=${limit}&page=${currentPage}`,
      accessToken
    )
      .then((res) => {
        if (res.status === 200) {
          setAssetList(res.data.data);
          setFilteredItems(res.data.data);
          setTotalRequest(res.data.totalRequest);
          setTotalPages(res.data.totalPages);
          setIsLoading(false);
        }
      })
      .catch((e) => {
        console.log(e);
        setIsLoading(false);
      });
  };
  return (
    <div className="wrapper-body">
      {isLoading && (
        <div className="overlay">
          <div className="mt-5">
            <TailSpin
              height="100"
              width="100"
              radius="1"
              color="blue"
              ariaLabel="tail-spin-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
        </div>
      )}
      <div className="page-heading">
        <div id="showMenuBtn" className="collapse-button" onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <Breadcrumbs
          items={[
            { label: "RTO Management", path: "/dashboard/rto-managemrnt" },
            { label: title },
          ]}
        />
        {filteredItems && (
          <div className="total">
            {filteredItems.length == 1 ? "Total Asset" : "Total Assets"} :{" "}
            {filteredItems.length}
          </div>
        )}
      </div>
      <div className="filters">
        {/* <form action="index.html" id="form_filters"> */}
        {/* Search By Company */}
        <div className="search">
          <input
            type="text"
            name="search"
            placeholder="Search By Asset Type, Serial Number, Owner and Docket Number"
            value={searchTerm}
            onChange={handleSearch}
            onKeyPress={(e) => {
              if (e.charCode === 32 && e.target.selectionStart === 0) {
                e.preventDefault(); // Prevent adding space at the beginning
              }
            }}
          />
          <img src="/assets/images/search-ico.svg" width="14" alt="Search" />
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          {!isLoading && filteredItems?.length > 0 && (
            <>
              {
                // <div className="table-responsive">
                //   <table className="resume custom">
                //     {/* Table Headings */}
                //     <thead>
                //       <tr>
                //         <th>S.No.</th>
                //         <th>Asset Type</th>
                //         <th>Owner</th>
                //         <th>Serial Number</th>
                //         <th>Manufacture</th>
                //         <th>Purchase Date</th>
                //         <th>Returned Date</th>
                //         <th>Returned Comment</th>
                //         <th>Docket No</th>
                //         <th>Status</th>
                //         <th>View</th>
                //       </tr>
                //     </thead>
                //     {/* Table Body */}
                //     <tbody>
                //       {filteredItems &&
                //         filteredItems?.map((item, index) => (
                //           <tr key={index}>
                //             <td data-title="Serial_No">
                //               {(currentPage - 1) * limit + index + 1}
                //             </td>
                //             <td>{item.assestType?.name}</td>
                //             <td>{item.owner}</td>
                //             <td>{item.serialNumber}</td>
                //             <td>{item.manufacture}</td>
                //             <td>
                //               {item.purchaseDate
                //                 ? moment(item.purchaseDate).format("DD-MM-yyyy")
                //                 : "-"}
                //             </td>
                //             <td>
                //               {moment(item.returnedDate).format("DD-MM-yyyy")}
                //             </td>
                //             <td>{item.returnComment}</td>
                //             <td>{item.docketNo}</td>
                //             <td>{item.status}</td>
                //             <td>
                //               <a
                //                 href={
                //                   process.env.REACT_APP_IMAGE_URL +
                //                   item?.docketPath
                //                 }
                //                 target="_blank"
                //               >
                //                 View
                //               </a>
                //             </td>
                //           </tr>
                //         ))}
                //     </tbody>
                //   </table>
                // </div>
              }
              <SortableTable
                columns={columns}
                data={tableData}
                currentPage={currentPage}
                limit={limit}
              />
              <div className="row">
                <div className="col-md-3">
                  <div>
                    {/* Dropdown for selecting items per page */}
                    <label htmlFor="itemsPerPage">Items per page </label>
                    <select
                      id="itemsPerPage"
                      onChange={handleLimitChange}
                      value={limit}
                      className="border ms-3 w-25  p-2"
                    >
                      <option value={5}>5</option>
                      <option value={10}>10</option>
                      <option value={20}>20</option>
                      <option value={30}>30</option>
                      <option value={40}>40</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-9">
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                  />
                </div>
              </div>
            </>
          )}{" "}
          {!isLoading && filteredItems?.length === 0 && (
            <div className="fs-4 text-secondary text-center mt-5">
              <h4>No Record Available</h4>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AssetReturnedList;
