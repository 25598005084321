import React, { useState } from "react";
import { TailSpin } from "react-loader-spinner";
import ViewOODTimesheet from "../../../components/ViewOODTimesheet";
import * as Yup from "yup";
import { API_LEAVE_WITHDRAW } from "../../../config/Endpoints";
import { toast } from "react-toastify";
import validations from "../../../config/validation";
import { putApi } from "../../../utils/api";
import Cookies from "js-cookie";

const ViewTimesheetModal = ({
  timesheet,
  selectedRequestId,
  status,
  onClose,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [totalTime, setTotalTime] = useState({
    hours: "",
    minutes: "",
  });
  const formDataInitialState = {
    reason: "",
  };
  const [formData, setFormData] = useState(formDataInitialState);
  const accessToken = Cookies.get("accessToken");

  const [errors, setErrors] = useState({});
  const validationSchema = Yup.object().shape({
    reason: validations.REASON_VALIDATION,
  });

  const withdrawRequest = async (e) => {
    e.preventDefault();

    if (!selectedRequestId) {
      toast.error("Something went wrong, please try again later", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }

    try {
      setIsLoading(true);
      await validationSchema.validate(formData, {
        abortEarly: false,
      });
      const { reason } = formData;
      await putApi(
        API_LEAVE_WITHDRAW +
          `?leaveId=${selectedRequestId}&reason=${encodeURIComponent(reason)}`,
        {},
        accessToken
      )
        .then((res) => {
          setIsLoading(false);
          toast.success(res?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });

          const modalClose =
            document.getElementsByClassName("cancel-modal-OOD")[0];
          modalClose.click();
          onClose();
        })
        .catch((e) => {
          setIsLoading(false);
          toast.error(e?.response?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    } catch (error) {
      const validationErrors = {};
      error.inner.forEach((err) => {
        validationErrors[err.path] = err.message;
      });

      setErrors(validationErrors);
      setIsLoading(false);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setFormData((state) => ({
      ...state,
      [name]: value,
    }));

    setErrors((prevFormData) => ({
      ...prevFormData,
      [name]: "",
    }));
  };

  const handleReset = () => {
    setFormData(formDataInitialState);
    setErrors({});
  };

  return (
    <form>
      {isLoading && (
        <div className="overlay">
          <div className="loader-container">
            <TailSpin
              height="100"
              width="100"
              radius="1"
              color="blue"
              ariaLabel="tail-spin-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
        </div>
      )}
      <div className="modal-header">
        <h5 className="modal-title text-center flex" id="staticBackdropLabel">
          Timesheet
        </h5>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={handleReset}
        ></button>
      </div>
      <div className="modal-body">
        <div className="px-2 pb-4 pt-2 d-flex flex-column gap-4">
          <div className="d-flex">
            <div className="timesheet-total-time d-flex gap-2 align-items-center justify-content-center">
              <span>Total Time</span>
              <div className="d-flex">
                <div
                  className="d-flex align-items-center justify-content-center"
                  style={{ gap: "2px" }}
                >
                  <span>{totalTime.hours}</span>
                  <span>Hrs</span>
                </div>
                {totalTime.minutes > 0 && (
                  <>
                    <span className="d-flex align-items-center justify-content-center px-2">
                      :
                    </span>
                    <div
                      className="d-flex align-items-center justify-content-center"
                      style={{ gap: "2px" }}
                    >
                      <span>{totalTime.minutes}</span>
                      <span>Mins</span>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <ViewOODTimesheet timesheet={timesheet} setTotalTime={setTotalTime} />
          {status !== "WITHDRAW" && status !== "APPROVED" && (
            <div className="row mt-3">
              <div className="col-md-2"></div>
              <div className="col-md-2">
                <label htmlFor="comment" className="has-asterisk">
                  Reason
                </label>
              </div>
              <div className="col-md-6">
                <textarea
                  value={formData.reason}
                  name="reason"
                  rows={3}
                  cols={5}
                  required
                  className="border"
                  onChange={handleInputChange}
                ></textarea>
                {errors.reason && (
                  <small className="text-danger">{errors.reason}</small>
                )}
              </div>
              <div className="col-md-2"></div>
            </div>
          )}
        </div>
      </div>
      <div className="justify-content-center modal-footer">
        <div className="d-flex gap-2">
          <button
            className="theme-button bg-grey cancel-modal-OOD"
            type="button"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={handleReset}
          >
            Cancel
          </button>
          {status !== "WITHDRAW" && status !== "APPROVED" && (
            <button
              className="theme-button bg-danger"
              type="button"
              onClick={withdrawRequest}
            >
              Withdraw
            </button>
          )}
        </div>
      </div>
    </form>
  );
};

export default ViewTimesheetModal;
