import React, { useEffect, useState } from "react";
import { toggleMenu } from "../../utils/helpers";
import Footer from "../../components/Footer";
import PageTitle from "../../components/PageTitle";
import CreateAssetSingle from "./CreateAssetSingle";
import CreateAssetBulk from "./CreateAssetBulk";
import CreateAssetType from "./AssetTypeRequests.jsx";
import ApproveAssetTypeRequests from "./ApproveAssetTypeRequests.jsx";

const CreateAsset = ({ title, description }) => {
  const [assetTypeList, setAssetTypeList] = useState([]);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const user = JSON.parse(localStorage.getItem("userData"));

  const tabs = [
    {
      label: "Create Asset",
      component: CreateAssetSingle,
    },
    {
      label: "Bulk Create Asset",
      component: CreateAssetBulk,
    },
    {
      label: "Asset Type Request",
      component: CreateAssetType,
      // allowedRoles: ["SUPER-ADMIN"]
    },
    {
      label: "Approve Asset Type Request",
      component: ApproveAssetTypeRequests,
    },
  ];


  return (
    <div className="wrapper-body">
      <div className="page-heading">
        <div id="showMenuBtn" className="collapse-button" onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <PageTitle title={title} description={description} />
      </div>
      <div className="card">
        <ul className="nav nav-tabs" id="myTabs" role="tablist">
          {tabs.map((item, index) => {
            const { label } = item;
            const selected = index === 0;

            return (
              <li className="nav-item" role="presentation" key={`tab-${index}`}>
                <button
                  className={`nav-link ${selected ? "active" : ""}`}
                  id={`tab${index}-tab`}
                  data-bs-toggle="tab"
                  data-bs-target={`#tab${index}`}
                  type="button"
                  role="tab"
                  aria-controls={`tab${index}`}
                  aria-selected={selected}
                  onClick={() => setActiveTabIndex(index)}
                >
                  {label}
                </button>
              </li>
            );
          })}
        </ul>

        <div className="tab-content" id="myTabsContent">
          {tabs.map((item, index) => {
            const Component = item.component;
            const selected = index === activeTabIndex;
            let props = {};

            if (Component === CreateAssetSingle) {
              props = { assetTypeList, setAssetTypeList };
            } else if (Component === CreateAssetType) {
              props = { assetTypeList, setAssetTypeList };
            } else {
              props = {};
            }

            return index === activeTabIndex ? (
              <div
                key={`tab-content-${index}`}
                className={`tab-pane fade ${selected ? "show active" : ""}`}
                id={`tab${index}`}
                role="tabpanel"
                aria-labelledby={`tab${index}-tab`}
              >
                <Component {...props} />
              </div>
            ) : null;
          })}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default CreateAsset;
