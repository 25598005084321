export const ownerOptions = [
  { value: "ALL", label: "All" },
  { value: "Caelius", label: "Caelius" },
  { value: "Salesforce", label: "Salesforce" },
  { value: "Consultant", label: "Consultant" },
  { value: "SQE", label: "SQE Labs" },
];

export const statusOptions = [
  { value: "ALL", label: "All" },
  { value: "Assigned", label: "Assigned" },
  { value: "Not Assigned", label: "Not Assigned" },
];

export const availabilityOptions = [
  { value: "ALL", label: "All" },
  { value: "ASSIGNED", label: "Assigned" },
  { value: "AVAILABLE1", label: "Available" },
  { value: "ALLOCATED", label: "Allocated" },
];

export const superOwnerOptions = [
  { value: "ALL", label: "All" },
  { value: "CAELIUS_OWNED", label: "CAELIUS_OWNED" },
  { value: "CLIENT_OWNED", label: "CLIENT_OWNED" },
];

export const warrantyUnitOptions = [
  { value: "year", label: "Year" },
  { value: "month", label: "Month" },
];

export const storageUnitOptions = [
  { value: "gb", label: "GB" },
  { value: "tb", label: "TB" },
];

export const assetCategory = [
  { value: "IT Asset", label: "IT Asset" },
  { value: "Other Asset", label: "Other Asset" },
];
