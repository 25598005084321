import Cookies from "js-cookie";
import React, { useState } from "react";
import { TailSpin } from "react-loader-spinner";
import { postApi } from "../../utils/api";
import { API_ASSET_RETURN } from "../../config/Endpoints";
import { toast } from "react-toastify";
import parse from "html-react-parser";
import Input from "../../components/Input";
import * as Yup from "yup";
import validations from "../../config/validation";

const AssetReturnRequestModal = ({ selectedAsset, fetchList }) => {
  const [isLoading, setIsLoading] = useState(false);
  const accessToken = Cookies.get("accessToken");
  const [formData, setFormData] = useState({
    status: "RETURNED",
    comment: "",
    dateOfReturn: "",
    docketNo: "",
    file: null,
  });
  const [errors, setErrors] = useState({});

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      await validationSchema.validate(formData, {
        abortEarly: false,
      });
      const formdata = new FormData();
      formdata.append("file", formData.file);

      await postApi(
        `${API_ASSET_RETURN}?masterAssetId=${selectedAsset.id}&status=${
          formData.status
        }&comment=${encodeURIComponent(formData.comment)}&dateOfReturn=${
          formData.dateOfReturn
        }&docketNo=${formData.docketNo}`,
        formdata,
        accessToken
      )
        .then((res) => {
          if (res.status === 200) {
            setIsLoading(false);
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            fetchList();
            const modalClose =
              document.getElementsByClassName("cancel-modal")[0];
            modalClose.click();
          } else {
            setIsLoading(false);
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            const modalClose =
              document.getElementsByClassName("cancel-modal")[0];
            modalClose.click();
          }
        })
        .catch((e) => {
          setIsLoading(false);
          toast.error(
            e?.response?.data?.message
              ? e?.response?.data?.message
              : "Something went wrong please try again.",
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
        });
    } catch (error) {
      setIsLoading(false);
      const validationErrors = {};
      error.inner.forEach((err) => {
        validationErrors[err.path] = err.message;
      });
      setErrors(validationErrors);
      console.log(error);
    }
  };

  const handleReset = (e) => {
    e.preventDefault();
    setFormData({
      status: "RETURNED",
      comment: "",
      dateOfReturn: "",
      docketNo: "",
      file: null,
    });
    document.getElementById("file-input").value = "";
    setErrors({});
  };

  const handleInputChange = (event) => {
    const { name, type, value } = event.target;
    if (type === "file") {
      const file = event.target.files[0];
      // Define an array of accepted file types
      const acceptedFileTypes = [
        "application/pdf",
        "image/jpeg",
        "image/png",
        "image/jpeg",
      ];
      const maxFileSize = 8 * 1024 * 1024; // 8 MB in bytes

      if (file) {
        if (file.size > maxFileSize) {
          // File size exceeds the maximum allowed size
          toast.error("File size exceeds the maximum limit of 8MB.", {
            position: toast.POSITION.TOP_RIGHT,
          });
          event.target.value = null; // Clear the file input
        } else if (!acceptedFileTypes.includes(file.type)) {
          // File type is not supported
          toast.error(
            "Unsupported file type. Please upload a valid PDF, JPG, PNG, or JPEG.",
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
          event.target.value = null; // Clear the file input
        } else {
          // File type and size are supported, set the form data
          setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: file,
          }));
        }
      }
    } else if (type === "date") {
      const year = value.split("-")[0];
      if (year.length <= 4) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: value,
        }));
      }
    } else {
      const value = event.target.value;
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }

    setErrors((prevFormData) => ({
      ...prevFormData,
      [name]: "",
    }));
  };

  const validationSchema = Yup.object().shape({
    docketNo:
      formData.status !== "DISPOSED" &&
      validations.SPECIAL_AND_NUMBERS_VALIDATION,
    comment: validations.COMMENT_VALIDATION,
  });

  // Get today's date in the format yyyy-mm-dd
  const today = new Date().toISOString().split("T")[0];
  return (
    <form onSubmit={handleSubmit}>
      {isLoading && (
        <div className="overlay">
          <div className="loader-container">
            <TailSpin
              height="100"
              width="100"
              radius="1"
              color="blue"
              ariaLabel="tail-spin-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
        </div>
      )}
      <div className="modal-header">
        <h5 className="modal-title text-center flex" id="staticBackdropLabel">
          Asset RTO Action
        </h5>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={handleReset}
        ></button>
      </div>
      <div className="modal-body">
        <div className="row">
          <div className="col-md-2"></div>
          <div className="col-md-4">
            <label htmlFor="status" className="has-asterisk">
              Status
            </label>
          </div>

          <div className="col-md-4">
            <select
              id="status"
              required
              name="status"
              className="border"
              value={formData.status}
              onChange={handleInputChange}
              // disabled={true}
            >
              <option value={"RETURNED"} selected>
                RETURNED
              </option>
              {selectedAsset.owner !== "Salesforce" && (
                <option value={"DISPOSED"}>DISPOSED</option>
              )}
              <option value={"UNIDENTIFIED"}>UNIDENTIFIED</option>
            </select>
          </div>
          <div className="col-md-2"></div>
        </div>

        <div className="row mt-3">
          <div className="col-md-2"></div>
          <div className="col-md-4">
            <label htmlFor="dateOfReturn" className="has-asterisk">
              Date Of {formData.status === "DISPOSED" ? "Disposed" : "Return"}
            </label>
          </div>
          <div className="col-md-4">
            <input
              type="date"
              required
              name="dateOfReturn"
              className="border"
              value={formData.dateOfReturn}
              placeholder="08/02/2019"
              onChange={handleInputChange}
              max={today}
            />
          </div>
          <div className="col-md-2"></div>
        </div>
        {formData.status !== "DISPOSED" && (
          <div className="row mt-3">
            <div className="col-md-2"></div>
            <div className="col-md-4">
              <label htmlFor="docketNo" className="has-asterisk">
                Docket No.
              </label>
            </div>
            <div className="col-md-4">
              <Input
                type="text"
                name="docketNo"
                className="border"
                value={formData.docketNo}
                onChange={handleInputChange}
                required
                restrict={["special", "space"]}
                whitelist={["_", "-"]}
              />
              {errors.docketNo && (
                <small className="text-danger">{errors.docketNo}</small>
              )}
            </div>

            <div className="col-md-2"></div>
          </div>
        )}

        <div className="row mt-3">
          <div className="col-md-2"></div>
          <div className="col-md-4">
            <label htmlFor="file" className="has-asterisk">
              Attachment
            </label>
          </div>
          <div className="col-md-4">
            <input
              type="file"
              name="file"
              id="file-input"
              onChange={handleInputChange}
              accept=".pdf,application/pdf"
              required
              className="border"
            />

            <small className="text-danger pt-0">
              Note: Please upload valid <strong>PDF, JPG, PNG, or JPEG</strong>{" "}
              file only. (<strong>8MB</strong> max.)
            </small>
          </div>
          <div className="col-md-2"></div>
        </div>
        <div className="row mt-3">
          <div className="col-md-2"></div>
          <div className="col-md-4">
            <label htmlFor="comment" className="has-asterisk">
              Comment
            </label>
          </div>
          <div className="col-md-4">
            <textarea
              rows={3}
              cols={5}
              className="border"
              name="comment"
              value={formData.comment}
              onChange={handleInputChange}
              required
            ></textarea>
            {errors.comment && (
              <small className="text-danger">{errors.comment}</small>
            )}
          </div>
          <div className="col-md-2"></div>
        </div>
      </div>

      <div className="justify-content-center modal-footer">
        <button
          type="submit"
          className={isLoading ? "theme-button disabled " : "theme-button "}
        >
          Submit
        </button>
        <button
          type="button"
          className="theme-button bg-grey mx-3 w-35 cancel-modal"
          data-bs-dismiss="modal"
          onClick={handleReset}
        >
          Cancel
        </button>
      </div>
    </form>
  );
};

export default AssetReturnRequestModal;
