import React, { useEffect, useState } from "react";
import Select, { components } from "react-select";
import { getApi, postApi } from "../../utils/api";
import {
  API_DOCUMENT_STATUS,
  API_DOCUMENT_VERSION,
  API_EMPLOYEE_DOCUMENT,
  GET_EMPLOYEE_LIST,
} from "../../config/Endpoints";
import Cookies from "js-cookie";
import { TailSpin, ThreeDots } from "react-loader-spinner";
import { toggleMenu, truncateString } from "../../utils/helpers";
import { toast } from "react-toastify";
import Footer from "../../components/Footer";
import PageTitle from "../../components/PageTitle";

const UploadDocument = ({ title, description }) => {
  const [selectedEmployeeId, setSelectedEmployeeId] = useState("");
  const [selectedEmployeeSelect, setSelectedEmployeeSelect] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [employeeList, setEmployeeList] = useState([]);
  const [documentList, setDocumentList] = useState([]);
  const accessToken = Cookies.get("accessToken");

  const formDataInitialState = {
    documentFile: null,
    comment: "",
  };

  const [formData, setFormData] = useState(formDataInitialState);
  const [selectedDocument, setSelectedDocument] = useState({});

  useEffect(() => {
    fetchList();
  }, []);

  const fetchList = () => {
    setIsLoading(true);
    getApi(GET_EMPLOYEE_LIST, accessToken)
      .then((res) => {
        if (res.status === 200) {
          setEmployeeList(res.data.data);
          setIsLoading(false);
        }
      })
      .catch((e) => {
        console.log(e);
        setIsLoading(false);
      });
  };
  const optionList = employeeList?.map((item) => ({
    value: item.id,
    label: item.empName,
    type: item.employeeType,
  }));

  const Option = (props) => {
    const { data } = props;
    const { label, type } = data;

    return (
      <components.Option {...props}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span>{label}</span>
          <span style={{ fontSize: "12px", color: "gray" }}>({type})</span>
        </div>
      </components.Option>
    );
  };
  const handleChangeEmployee = async (e) => {
    setIsLoading(true);
    let employeeId = e.value;
    setSelectedEmployeeId(employeeId);
    setSelectedEmployeeSelect(e);
    await fetchListDocument(employeeId);
  };

  const fetchListDocument = async (employeeId) => {
    await getApi(API_DOCUMENT_STATUS + `?employeeId=${employeeId}`, accessToken)
      .then((res) => {
        setDocumentList(res?.data?.data);
        setIsLoading(false);
      })
      .catch((e) => {
        toast.error("Something went wrong please try again !!", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setIsLoading(false);
      });
  };
  const handleClose = () => {
    setSelectedDocument({});
  };
  const handleInputChange = (event) => {
    const { name, value, type } = event.target;
    if (type === "file") {
      const file = event.target.files[0];
      // Define an array of accepted file types
      const acceptedFileTypes = getAcceptedDocumentFileFormats();
      const maxFileSize = 8 * 1024 * 1024; // 8 MB in bytes
      if (file) {
        if (file.size > maxFileSize) {
          // File size exceeds the maximum allowed size
          toast.error("File size exceeds the maximum limit of 8MB.", {
            position: toast.POSITION.TOP_RIGHT,
          });
          event.target.value = null; // Clear the file input
        } else if (acceptedFileTypes.includes(file.type)) {
          // File type is supported
          setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: file,
          }));
        } else {
          // File type is not supported, show an alert
          toast.error(
            "Unsupported file type. Please upload a valid " +
              getDocumentFileFormatMessage() +
              ".",
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
          event.target.value = null; // Clear the file input
        }
      }
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };
  const handleUploadClick = (item) => {
    setSelectedDocument(item);
    const openModalButton =
      document.getElementsByClassName("open-modal-upload")[0];
    if (openModalButton) {
      openModalButton.click();
      document.getElementById("file-input").value = "";
    }
  };
  const handleReset = () => {
    setFormData(formDataInitialState);
    document.getElementById("file-input").value = "";
    const modalClose = document.getElementsByClassName("close-class")[0];
    modalClose.click();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const formdata = new FormData();
      formdata.append("docType", selectedDocument?.documentType);
      formdata.append("employeeId", selectedEmployeeId);
      formdata.append("docFile", formData.documentFile);
      formdata.append("comments", formData.comment);

      await postApi(API_DOCUMENT_VERSION, formdata, accessToken)
        .then((res) => {
          if (res.status === 200) {
            setIsLoading(false);
            fetchListDocument(selectedEmployeeId);

            handleReset();
            toast.success(
              selectedDocument?.documentName + " Uploaded Successfully.",
              {
                position: toast.POSITION.TOP_RIGHT,
              }
            );
            handleClose();
            fetchListDocument(selectedEmployeeId);
          }
        })
        .catch((err) => {
          toast.error(
            err.response.data.message ||
              "Something went wrong please try again",
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
          setIsLoading(false);
          console.log(err);
        });
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      toast.error("Something went wrong. Please try again!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const getAcceptedDocumentFileFormats = () => {
    if (selectedDocument.documentType === "PPSIZEPHOTO") {
      return ["image/jpg", "image/png", "image/jpeg"];
    }

    return ["application/pdf", "image/jpg", "image/png", "image/jpeg"];
  };

  const getDocumentFileFormatMessage = () => {
    if (selectedDocument.documentType === "PPSIZEPHOTO") {
      return "JPG, PNG, or JPEG";
    }
    return "PDF, JPG, PNG, or JPEG";
  };

  return (
    <div className="wrapper-body">
      {isLoading && (
        <div className="overlay">
          <div className="loader-container">
            <TailSpin
              height="100"
              width="100"
              radius="1"
              color="blue"
              ariaLabel="tail-spin-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
        </div>
      )}
      <div className="page-heading">
        <div id="showMenuBtn" className="collapse-button" onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <PageTitle title={title} description={description} />
      </div>
      <div className="card">
        <form id="assign-asset">
          <h3 className="heading">Employee</h3>
          <div className="inputs">
            <div className="row">
              <div className="col-md-3"></div>
              <div className="col-md-6">
                <div className="dropdown-container">
                  <Select
                    options={optionList}
                    components={{ Option }}
                    onChange={handleChangeEmployee}
                    value={selectedEmployeeSelect}
                    placeholder="Select Employee"
                    isSearchable={true}
                    isDisabled={employeeList?.length === 0}
                    required
                  />
                </div>
              </div>
              <div className="col-md-2"></div>
            </div>
          </div>
        </form>
      </div>
      {documentList?.length > 0 && (
        <div className="table-responsive">
          <table className="resume custom">
            <thead>
              <tr>
                <th>S.No.</th>
                <th>Document Name</th>
                <th>Mandatory</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>

            <tbody>
              {documentList?.map((item, index) => (
                <tr>
                  <td>{index + 1}</td>
                  <td>{item?.documentName}</td>
                  <td>
                    {item.isMandatory ? (
                      <span className="badge bg-danger">Yes</span>
                    ) : (
                      <span className="badge bg-success"> No</span>
                    )}
                  </td>
                  <td>
                    {item.approvalStatus ? (
                      <small
                        className={
                          "text-capitalize badge " +
                          ((item.approvalStatus === "0" ||
                            item.approvalStatus === "pending") &&
                            "  bg-warning ") +
                          (item.approvalStatus === "rejected" &&
                            " bg-danger ") +
                          (item.approvalStatus === "approved" && " bg-success ")
                        }
                      >
                        {item.approvalStatus}
                      </small>
                    ) : (
                      "-"
                    )}
                  </td>
                  {/* <td>{item?.isUploaded}</td> */}
                  <td>
                    <span className="text-left">
                      {item?.isUploaded && (
                        <a
                          className="fs-5"
                          href={
                            process.env.REACT_APP_IMAGE_URL + item.documentPath
                          }
                          target="_blank"
                        >
                          <i className="fa fa-eye"></i>
                        </a>
                      )}
                    </span>
                    <span className="text-right">
                      <a
                        className={
                          (item?.isUploaded ? "ms-4" : "ms-5") + " fs-5 "
                        }
                        onClick={() => handleUploadClick(item)}
                      >
                        <i class="fa fa-upload"></i>
                      </a>
                    </span>
                    <button
                      className="open-modal-upload"
                      data-bs-toggle="modal"
                      data-bs-target="#staticBackdropUpload"
                      style={{ display: "none" }}
                    >
                      click
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      <div
        className="modal fade"
        id="staticBackdropUpload"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <form onSubmit={handleSubmit}>
              <div className="modal-header">
                <h5
                  className="modal-title text-center flex"
                  id="staticBackdropLabel"
                >
                  Upload Document Action
                </h5>
                <button
                  type="button"
                  className="btn-close close-class"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handleClose}
                ></button>
              </div>
              <div className="modal-body">
                {selectedDocument?.isUploaded && (
                  <>
                    {Object.keys(selectedDocument)?.length > 0 && (
                      <div className="table-responsive mt-1">
                        <table className="resume custom ">
                          {/* Table Headings */}
                          <thead>
                            <tr>
                              {/* <th>Document ID</th> */}
                              <th>Document Type</th>
                              <th>Status</th>
                              <th>Comment</th>
                              <th>Action</th>
                            </tr>
                          </thead>

                          {/* Table Body */}
                          <tbody>
                            <tr>
                              {/* <td>{selectedDocument.documentId}</td> */}
                              <td>{selectedDocument.documentType}</td>

                              <td>
                                <small
                                  className={
                                    " badge " +
                                    ((selectedDocument.approvalStatus === "0" ||
                                      selectedDocument.approvalStatus ===
                                        "pending") &&
                                      " badge-warning ") +
                                    (selectedDocument.approvalStatus ===
                                      "rejected" && " badge-danger ") +
                                    (selectedDocument.approvalStatus ===
                                      "approved" && " badge-success ")
                                  }
                                >
                                  {selectedDocument.approvalStatus === "0"
                                    ? "Pending"
                                    : selectedDocument.approvalStatus}
                                  {/* // .charAt(0) // .toUpperCase() + //
                              selectedDocument.approvalStatus.slice(1) */}
                                </small>
                              </td>
                              <td>
                                {selectedDocument.reason
                                  ? truncateString(selectedDocument.reason)
                                  : "-"}
                              </td>
                              <td>
                                <a
                                  className="fs-5"
                                  href={
                                    process.env.REACT_APP_IMAGE_URL +
                                    selectedDocument.documentPath
                                  }
                                  target="_blank"
                                >
                                  <i className="fa fa-eye"></i>
                                </a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    )}
                  </>
                )}
                <div className="row mt-3">
                  <div className="col-md-2"></div>
                  <div className="col-md-3">
                    <label htmlFor="documentFile" className="has-asterisk">
                      Document File
                    </label>
                  </div>
                  <div className="col-md-5">
                    <div className="input-group">
                      <input
                        className="border border-1"
                        type="file"
                        name="documentFile"
                        id="file-input"
                        onChange={handleInputChange}
                        required
                      />
                      <small className="text-danger pt-0">
                        Note: Please upload valid{" "}
                        <strong>{getDocumentFileFormatMessage()}</strong> file
                        only (max <strong>8 MB</strong>).
                      </small>
                    </div>
                  </div>

                  <div className="col-md-2"></div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-2"></div>
                  <div className="col-md-3">
                    <label htmlFor="comment" className="has-asterisk">
                      Comment
                    </label>
                  </div>
                  <div className="col-md-5">
                    <div className="input-group">
                      <textarea
                        className="border border-1"
                        cols={15}
                        rows={5}
                        required
                        value={formData.comment}
                        onChange={handleInputChange}
                        maxLength={160}
                        name="comment"
                        onKeyPress={(e) => {
                          // Prevent adding space at the beginning
                          if (
                            e.charCode === 32 &&
                            e.target.selectionStart === 0
                          ) {
                            e.preventDefault();
                          }

                          // Regular expression to allow only letters, numbers, and spaces
                          const allowedCharsRegex = /^[a-zA-Z0-9 ]*$/;

                          // Check if the character is allowed
                          const char = String.fromCharCode(e.charCode);
                          if (!allowedCharsRegex.test(char)) {
                            e.preventDefault(); // Prevent non-alphanumeric character input
                          }
                        }}
                      ></textarea>
                    </div>
                  </div>
                  <div className="col-md-2"></div>
                </div>
              </div>

              <div className="justify-content-center modal-footer">
                <button
                  type="submit"
                  className={
                    isLoading ? "theme-button disabled " : "theme-button "
                  }
                >
                  {isLoading ? (
                    <ThreeDots
                      height="20"
                      width="53"
                      radius="9"
                      color="white"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  ) : (
                    "Submit"
                  )}
                </button>
                <button
                  type="button"
                  className="theme-button bg-grey mx-3 w-35 cancel-promote"
                  data-bs-dismiss="modal"
                  onClick={handleClose}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default UploadDocument;
