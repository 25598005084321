import { TailSpin } from "react-loader-spinner";
import SortableTable from "../../components/SortableTable";
import NoRecordsAvailable from "../../components/NoRecordsAvailable";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { formatDate, toggleMenu } from "../../utils/helpers";
import PageTitle from "../../components/PageTitle";
import { API_LEAVE_GET_DL_OOD } from "../../config/Endpoints";
import { getApi } from "../../utils/api";
import { toast } from "react-toastify";
import ApproveOODModal from "./Modals/ApproveOODModal";
import Pagination from "../../components/Pagination";

const ApproveOnOfficialDutyDL = ({ title, description }) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const accessToken = Cookies.get("accessToken");
  const [timesheet, setTimesheet] = useState([]);
  const [selectedEntry, setSelectedEntry] = useState();
  const [formData, setFormData] = useState({
    status: "PENDING",
  });

  const columns = [
    { key: "employeeName", label: "Employee Name" },
    {
      key: "leaveDate",
      label: "On Official Duty-Date",
      compare: (a, b) =>
        new Date(a._original.startDate) - new Date(b._original.startDate),
    },
    {
      key: "timesheet",
      label: "Action",
      render: (item) => (
        <a
          data-bs-toggle="modal"
          data-bs-target="#staticBackdropApproveOODDL"
          onClick={() => {
            setTimesheet(item._original.taskList);
            setSelectedEntry(item._original);
          }}
        >
          View
        </a>
      ),
      sortable: false,
    },
  ];
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    setTableData(
      data?.map((item) => ({
        employeeName: item.employeeName,
        leaveDate: formatDate(item.startDate),
        dlComment: item.firstApprovalComment,
        _original: item,
      })) || []
    );
  }, [data]);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(10);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      await getApi(
        API_LEAVE_GET_DL_OOD +
          `?status=${formData.status}&pageSize=${limit}&page=${currentPage}`,
        accessToken
      )
        .then((res) => {
          setData(res.data.data);
          setTotalPages(res.data.totalPages);
        })
        .catch((err) => {
          toast.error(
            err.response.data.message ||
              "Something went wrong please try again later",
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
          console.error(err);
        });
    } finally {
      setIsLoading(false);
    }
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleLimitChange = (e) => {
    setLimit(e.target.value);
    setCurrentPage(1);
  };

  useEffect(() => {
    fetchData();
  }, [currentPage, limit]);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="wrapper-body">
      {isLoading && (
        <div className="overlay">
          <div className="mt-5">
            <TailSpin
              height="100"
              width="100"
              radius="1"
              color="blue"
              ariaLabel="tail-spin-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
        </div>
      )}
      <div className="page-heading">
        <div id="showMenuBtn" className="collapse-button" onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <PageTitle title={title} description={description} />
        {data && <div className="total">{`Total : ${data.length}`}</div>}
      </div>
      <div
        className="modal fade"
        id="staticBackdropApproveOODDL"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <ApproveOODModal
              type="DL"
              timesheet={timesheet}
              selectedEntry={selectedEntry}
              onClose={() => fetchData()}
            />
          </div>
        </div>
      </div>
      <div className="action-bar">
        {/* <div className="filters-wrapper">
          <div className="filter">
            <label className="label" htmlFor="filterAssetType">
              Asset Type
            </label>
            <select
              id="filterAssetType"
              value={formData.filterAssetType}
              required
              onChange={handleInputChange}
              name="filterAssetType"
              disabled={isListLoading}
              className="select"
            >
              {assetTypeFilterOptions.map((item, index) => {
                const { label, value } = item;
                return (
                  <option value={value} key={`asset-type-${index}`}>
                    {label}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="filter">
            <label className="label" htmlFor="filterOwner">
              Owner
            </label>
            <select
              id="filterOwner"
              value={formData.filterOwner}
              required
              onChange={handleInputChange}
              name="filterOwner"
              className="select"
            >
              {ownerOptions.map((item, index) => {
                const { label, value } = item;
                return (
                  <option value={value} key={`owner-${index}`}>
                    {label}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <div className="actions-wrapper rto-management-actions-wrapper">
          <div className="actions">
            <button
              className="export theme-button "
              type="button"
              onClick={fetchFilteredData}
            >
              Filter
            </button>
          </div>
          <div className="actions">
            <a className="export theme-button" href="/dashboard/returned-asset">
              Returned Asset
            </a>
          </div>
        </div> */}
      </div>
      <div className="row">
        <div className="col-md-12">
          {!isLoading && (
            <>
              {tableData && tableData.length > 0 ? (
                <>
                  <SortableTable
                    columns={columns}
                    data={tableData}
                    currentPage={currentPage}
                    limit={limit}
                  />
                  <div className="row">
                    <div className="col-md-3">
                      <div>
                        {/* Dropdown for selecting items per page */}
                        <label htmlFor="itemsPerPage">Items per page </label>
                        <select
                          id="itemsPerPage"
                          onChange={handleLimitChange}
                          value={limit}
                          className="border ms-3 w-25 p-2"
                        >
                          <option value={5}>5</option>
                          <option value={10}>10</option>
                          <option value={20}>20</option>
                          <option value={30}>30</option>
                          <option value={40}>40</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-9">
                      <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <div className="container">
                  <NoRecordsAvailable />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ApproveOnOfficialDutyDL;
