import { toast } from "react-toastify";
import * as Yup from "yup";
import { useState } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import { API_CREATE_ASSET_TYPE } from "../../../config/Endpoints";
import { TailSpin, ThreeDots } from "react-loader-spinner";
import validations from "../../../config/validation";
import Input from "../../../components/Input";
import { assetCategory } from "../../../config/filters";

const CreateAssetTypeModal = ({ assetTypeList, setRequests }) => {
  const [isLoading, setIsLoading] = useState(false);
  const formDataInitialState = {
    name: "",
    category: assetCategory[0].value,
    comment: "",
  };
  const [formData, setFormData] = useState(formDataInitialState);
  const [errors, setErrors] = useState({});
  const [assetTypeAlreadyExists, setAssetTypeAlreadyExists] = useState(false);
  const accessToken = Cookies.get("accessToken");

  const validationSchema = Yup.object().shape({
    name: validations.SPECIAL_AND_NUMBERS_VALIDATION.required(
      "Asset Name is required"
    ),
    comment: validations.COMMENT_VALIDATION,
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      await validationSchema.validate(formData, {
        abortEarly: false,
      });

      await axios
        .post(
          API_CREATE_ASSET_TYPE,
          {},
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "Content-Type": "application/json",
            },
            params: {
              name: formData.name,
              category: formData.category,
              comment: formData.comment,
            },
          }
        )
        .then((res) => {
          setRequests(res.data.data);
          toast.success(
            "Request to create " +
              formData.name +
              " asset type sent successfully.",
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );

          const modalClose = document.getElementsByClassName("cancel-modal")[0];
          modalClose.click();

          handleReset();
        })
        .catch((err) => {
          const errMsg = err.response.data.message
            ? err.response.data.message
            : "Something went wrong. Please try again!";

          toast.error(errMsg, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    } catch (error) {
      const validationErrors = {};
      error.inner.forEach((err) => {
        validationErrors[err.path] = err.message;
      });
      setErrors(validationErrors);
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    const inputValue = e.target.value.toLowerCase();

    let exists = false;
    if (e.target.name === "name") {
      exists = assetTypeList.some(
        (obj) => obj.name.toLowerCase() === inputValue
      );

      if (exists) setAssetTypeAlreadyExists(true);
      else setAssetTypeAlreadyExists(false);
    }

    setErrors((prevFormData) => ({
      ...prevFormData,
      [e.target.name]: exists ? "Asset already exists." : "",
    }));
  };

  const handleReset = () => {
    setFormData(formDataInitialState);
    setErrors({});
  };

  return (
    <form onSubmit={handleSubmit}>
      {isLoading && (
        <div className="overlay">
          <div className="loader-container">
            <TailSpin
              height="100"
              width="100"
              radius="1"
              color="blue"
              ariaLabel="tail-spin-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
        </div>
      )}
      <div className="modal-header">
        <h5 className="modal-title text-center flex" id="staticBackdropLabel">
          Create Asset Type
        </h5>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={handleReset}
        ></button>
      </div>
      <div className="modal-body">
        <div className="px-2 py-4">
          <div className="d-grid inputs gap-4">
            <div className="input-group">
              <div className="row">
                <div className="col-md-2"></div>
                <div className="col-md-4 pt-1">
                  <label htmlFor="manufacture" className="has-asterisk">
                    Asset Name
                  </label>
                </div>
                <div className="col-md-4">
                  <Input
                    type="text"
                    name="name"
                    className="border"
                    onChange={handleInputChange}
                    required
                    value={formData.name}
                    disabled={isLoading}
                    autoComplete="off"
                  />
                  {errors.name && (
                    <small className="text-danger">{errors.name}</small>
                  )}
                </div>
                <div className="col-md-2"></div>
              </div>
            </div>
            <div className="input-group">
              <div className="row">
                <div className="col-md-2"></div>
                <div className="col-md-4 pt-1">
                  <label htmlFor="assetCategory" className="has-asterisk">
                    Asset Category
                  </label>
                </div>
                <div className="col-md-4">
                  <select
                    id="assetCategory"
                    onChange={handleInputChange}
                    value={formData.category}
                    name="category"
                    className="border"
                  >
                    {assetCategory.map((item, index) => {
                      const { value, label } = item;

                      return (
                        <option value={value} key={`category-${index}`}>
                          {label}
                        </option>
                      );
                    })}
                  </select>
                  {errors.category && (
                    <small className="text-danger">{errors.category}</small>
                  )}
                </div>
                <div className="col-md-2"></div>
              </div>
            </div>
            <div className="input-group">
              <div className="row">
                <div className="col-md-2"></div>
                <div className="col-md-4 pt-1">
                  <label htmlFor="comment" className="has-asterisk">
                    Comment
                  </label>
                </div>
                <div className="col-md-4">
                  <textarea
                    id="comment"
                    rows={3}
                    cols={5}
                    className="border"
                    name="comment"
                    value={formData.comment}
                    onChange={handleInputChange}
                    required
                  ></textarea>
                  {errors.comment && (
                    <small className="text-danger">{errors.comment}</small>
                  )}
                </div>
                <div className="col-md-2"></div>
              </div>
            </div>
          </div>
          <div className="action text-center mt-5"></div>
        </div>
      </div>
      <div className="justify-content-center modal-footer">
        <button
          className={
            isLoading || assetTypeAlreadyExists
              ? "theme-button disabled "
              : "theme-button "
          }
          type="submit"
          disabled={assetTypeAlreadyExists}
        >
          {isLoading ? (
            <ThreeDots
              height="25"
              width="80"
              radius="9"
              color="white"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          ) : (
            "Submit"
          )}
        </button>
        <button
          type="button"
          className="theme-button bg-grey mx-3 w-35 cancel-modal"
          data-bs-dismiss="modal"
          onClick={handleReset}
        >
          Cancel
        </button>
      </div>
    </form>
  );
};

export default CreateAssetTypeModal;
