import React, { useEffect, useState } from "react";
import { TailSpin, ThreeDots } from "react-loader-spinner";
import ApplyOODTimesheet from "../../components/ApplyOODTimesheet";
import DatePicker from "react-datepicker";
import axios from "axios";
import {
  API_HOLIDAY,
  API_LEAVE_APPLY_OOD,
  API_LEAVE_GET_OOD_DL_LIST,
} from "../../config/Endpoints";
import Cookies from "js-cookie";
import { capitalize } from "../../utils/helpers";
import { getApi } from "../../utils/api";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import validations from "../../config/validation";
import * as Yup from "yup";

const ApplyOnOfficialDuty = () => {
  const formDataInitialState = {
    dateOOD: "",
    deliveryLeadId: "",
  };
  const [formData, setFormData] = useState(formDataInitialState);
  const tasksInitialState = [
    { id: uuidv4(), description: "", timeHrs: "", timeMins: "" },
  ];
  const [tasks, setTasks] = useState(tasksInitialState);
  const [deliveryLeadOptions, setDeliveryLeadOptions] = useState([]);
  const [loadingCount, setLoadingCount] = useState(0);
  const [holidayList, setHolidayList] = useState([]);
  const [isSelfDL, setIsSelfDL] = useState(false);
  const userData = JSON.parse(localStorage.getItem("userData"));
  const accessToken = Cookies.get("accessToken");
  const [errors, setErrors] = useState("");
  const validationSchema = Yup.array().of(
    Yup.object()
      .shape({
        description: validations.NULL_VALIDATION,

        timeHrs: Yup.number()
          .nullable()
          .transform((value, originalValue) =>
            String(originalValue).trim() === "" ? null : value
          )
          .typeError("Hrs must be a valid number")
          .positive("Hrs must be positive")
          .integer("Hrs must be an integer")
          .min(1, "Value must be greater than or equal to 1")
          .max(23, "Value must not exceed 23"),

        timeMins: Yup.number()
          .nullable()
          .transform((value, originalValue) =>
            String(originalValue).trim() === "" ? null : value
          )
          .typeError("Mins must be a valid number")
          .positive("Mins must be positive")
          .integer("Mins must be an integer")
          .min(1, "Value must be greater than or equal to 1")
          .max(59, "Value must not exceed 59"),
      })
      .test(
        "at-least-one",
        "Either hours or minutes must be provided",
        function (values) {
          return values.timeHrs !== null || values.timeMins !== null;
        }
      )
  );

  useEffect(() => {
    setIsSelfDL(
      deliveryLeadOptions.some((item) => {
        return item.value === userData.id;
      })
    );
  }, [deliveryLeadOptions]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoadingCount((prev) => prev + 1);

    try {
      await validationSchema.validate(tasks, {
        abortEarly: false,
      });

      const taskList = tasks.map((item) => ({
        taskName: item.description,
        hours: item.timeHrs,
        minutes: item.timeMins,
      }));

      const approverId = isSelfDL ? userData.id : formData.deliveryLeadId;
      // const leaveDate = new Date(formData.dateOOD).toLocaleDateString("en-UK");
      const leaveDate = moment(formData.dateOOD).format("YYYY-MM-DDTHH:mm:ss");

      await axios
        .post(
          API_LEAVE_APPLY_OOD,
          {
            startDate: leaveDate,
            endDate: leaveDate,
            approverId: approverId,
            taskList: taskList,
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        )
        .then((res) => {
          handleReset();
          toast.success("On Official Duty request sent for approval", {
            position: toast.POSITION.TOP_RIGHT,
          });
        })
        .catch((err) => {
          toast.error(
            err.response.data.message ||
              "Something went wrong please try again later",
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
        });
    } catch (error) {
      const validationErrors = {};
      error.inner.forEach((err) => {
        const index = err.path.match(/\d+/)[0];
        const field = err.path.split(".")[1];

        if (!validationErrors[index]) {
          validationErrors[index] = {};
        }
        validationErrors[index][field] = err.message;
      });
      setErrors(validationErrors);
    } finally {
      setLoadingCount((prev) => prev - 1);
    }
  };

  const handleReset = () => {
    setFormData(formDataInitialState);
    setTasks(tasksInitialState);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setFormData((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const fetchDeliveryLeadList = async () => {
    setLoadingCount((prev) => prev + 1);

    try {
      await axios
        .get(API_LEAVE_GET_OOD_DL_LIST, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then((res) => {
          let options = res.data.data?.map((item) => ({
            value: item.id,
            label:
              capitalize(item.firstName.trim()) +
              " " +
              capitalize(item.lastName.trim()),
          }));

          options.unshift({ value: "", label: "Select a Delivery Lead" });
          setDeliveryLeadOptions(options);
        });
    } finally {
      setLoadingCount((prev) => prev - 1);
    }
  };

  useEffect(() => {
    fetchDeliveryLeadList();
    fetchHolidayList();
  }, []);

  const fetchHolidayList = async () => {
    setLoadingCount((prev) => prev + 1);
    const year = new Date().getFullYear();

    try {
      getApi(API_HOLIDAY + `?year=${year}&status=approved`, accessToken).then(
        (res) => {
          const data = res.data.data.data;

          setHolidayList(data.map((item) => new Date(item.date)));
        }
      );
    } finally {
      setLoadingCount((prev) => prev - 1);
    }
  };

  const today = new Date();
  const start = new Date("Sat Mar 01 2025");
  const maxDate = new Date();
  maxDate.setDate(today.getDate() + 31);

  const filterDate = (date) => {
    const day = date.getDay();

    const isWeekend = day === 0 || day === 6;
    const isWithinRange = date >= start && date <= maxDate;
    const isHoliday = holidayList.some(
      (holiday) => holiday.toDateString() === date.toDateString()
    );

    return (isWeekend || isHoliday) && isWithinRange;
  };

  return (
    <div className="px-2 py-4">
      {loadingCount > 0 ? (
        <div className="overlay">
          <div className="mt-5">
            <TailSpin
              height="100"
              width="100"
              radius="1"
              color="blue"
              ariaLabel="tail-spin-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
        </div>
      ) : (
        <form className="d-grid gap-15" onSubmit={handleSubmit}>
          <div className="inputs d-grid">
            <div className="row">
              <div className={isSelfDL ? "col-md-4" : "col-md-2"}></div>
              <div className="col-md-4">
                <div className="input-group">
                  <label htmlFor="" className="has-asterisk">
                    Select Date
                  </label>
                  <DatePicker
                    name="dateOOD"
                    placeholderText="mm/dd/yyyy"
                    selected={formData.dateOOD}
                    onChange={(date) => {
                      setFormData((state) => ({
                        ...state,
                        dateOOD: date,
                      }));
                    }}
                    dateFormat="MMMM d, yyyy"
                    minDate={start}
                    maxDate={maxDate}
                    filterDate={filterDate}
                    toggleCalendarOnIconClick
                    autoComplete="off"
                    showIcon
                    required
                  />
                </div>
              </div>
              {!isSelfDL && (
                <div className="col-md-4">
                  <div className="input-group">
                    <label htmlFor="" className="has-asterisk">
                      Delivery Lead
                    </label>
                    <select
                      id="deliveryLead"
                      name="deliveryLeadId"
                      required
                      value={formData.deliveryLeadId}
                      onChange={handleInputChange}
                    >
                      {deliveryLeadOptions.map((item, index) => {
                        const { label, value } = item;
                        return (
                          <option value={value} key={`owner-${index}`}>
                            {label}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              )}
              <div className={isSelfDL ? "col-md-4" : "col-md-2"}></div>
            </div>
          </div>
          <div className="d-flex justify-content-center align-items-center">
            <ApplyOODTimesheet
              setTasks={setTasks}
              tasks={tasks}
              resetTasks={() => setTasks(tasksInitialState)}
              errors={errors}
              setErrors={setErrors}
            />
          </div>
          <div className="d-flex justify-content-center align-items-center mt-4">
            <button
              className={
                loadingCount > 0 ? "theme-button disabled " : "theme-button "
              }
              type="submit"
            >
              {loadingCount > 0 ? (
                <ThreeDots
                  height="25"
                  width="80"
                  radius="9"
                  color="white"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={true}
                />
              ) : (
                "Submit"
              )}
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default ApplyOnOfficialDuty;
