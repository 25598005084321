import { TailSpin, ThreeDots } from "react-loader-spinner";
import {
  API_TOTAL_ASSET,
  API_V1_TOTAL_ASSET,
  ASSET_TYPE_LIST,
} from "../../config/Endpoints";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { getApi } from "../../utils/api";
import { toast } from "react-toastify";
import { toggleMenu } from "../../utils/helpers";
import AssetReturnRequestModal from "./AssetReturnRequestModal";
import PageTitle from "../../components/PageTitle";
import { ownerOptions, setOwnerOptions } from "../../config/filters";
import SortableTable from "../../components/SortableTable";

const AssetReturnATO = ({ title, description }) => {
  const [assetList, setAssetList] = useState([]);
  const [isListLoading, setIsListLoading] = useState(false);
  const [selectedAsset, setSelectedAsset] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [assetTypeList, setAssetTypeList] = useState([]);
  const [filteredItems, setFilteredItems] = useState();
  const accessToken = Cookies.get("accessToken");

  const columns = [
    { key: "assetType", label: "Asset Type" },
    { key: "serialNo", label: "Serial Number" },
    { key: "owner", label: "Owner" },
    {
      key: "action",
      label: "Action",
      render: (item) => (
        <a
          data-bs-toggle="modal"
          data-bs-target="#staticBackdropAssetRTO"
          onClick={() => {
            setSelectedAsset(item._original);
          }}
        >
          View
        </a>
      ),
      sortable: false,
    },
  ];
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    setTableData(
      filteredItems?.map((asset) => ({
        assetType: asset.assetName,
        serialNo: asset.serialNumber,
        owner: asset.owner,
        _original: asset,
      })) || []
    );
  }, [filteredItems]);

  const filterDefaultValues = {
    ownerFilter: "ALL",
    assetTypeFilter: 0,
    statusFilter: "ALL",
  };

  // filter is computed from data
  const assetTypeFilterOptions = assetTypeList.map((item) => ({
    value: item.assestId,
    label: item.name,
  }));
  assetTypeFilterOptions.unshift({
    value: filterDefaultValues.assetTypeFilter,
    label: "All",
  });

  const [formData, setFormData] = useState({
    filterOwner: filterDefaultValues.ownerFilter,
    filterAssetType: filterDefaultValues.assetTypeFilter,
    status: filterDefaultValues.statusFilter,
  });

  useEffect(() => {
    fetchAssetTypeList();
    fetchFilteredData();
  }, []);

  const fetchAssetTypeList = async () => {
    setIsListLoading(true);

    try {
      await getApi(ASSET_TYPE_LIST, accessToken)
        .then((res) => {
          if (res.status === 200) {
            setAssetTypeList(res.data.data);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } finally {
      setIsListLoading(false);
    }
  };

  const fetchFilteredData = async () => {
    const owner = formData.filterOwner;
    const assetTypeId = formData.filterAssetType;
    setIsLoading(true);

    try {
      if (owner && assetTypeId != null) {
        await getApi(
          API_V1_TOTAL_ASSET + `?owner=${owner}&assestTypeId=${assetTypeId}`,
          accessToken
        )
          .then((res) => {
            if (res.status === 200) {
              setAssetList(res.data.data);
              setFilteredItems(res.data.data);
              setIsLoading(false);
            }
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        toast.error(
          "Please choose an asset type and owner to filter the assets.",
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  return (
    <div className="wrapper-body">
      {isLoading && (
        <div className="overlay">
          <div className="mt-5">
            <TailSpin
              height="100"
              width="100"
              radius="1"
              color="blue"
              ariaLabel="tail-spin-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
        </div>
      )}
      <div className="page-heading">
        <div id="showMenuBtn" className="collapse-button" onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <PageTitle title={title} description={description} />
        {filteredItems && (
          <div className="total">
            {filteredItems.length == 1 ? "Total Asset" : "Total Assets"} :{" "}
            {filteredItems.length}
          </div>
        )}
      </div>
      <div className="action-bar">
        {/* <form action="index.html" id="form_filters"> */}
        {/* Search By Company */}
        <div className="filters-wrapper">
          <div className="filter">
            <label className="label" htmlFor="filterAssetType">
              Asset Type
            </label>
            <select
              id="filterAssetType"
              value={formData.filterAssetType}
              required
              onChange={handleInputChange}
              name="filterAssetType"
              disabled={isListLoading}
              className="select"
            >
              {assetTypeFilterOptions.map((item, index) => {
                const { label, value } = item;
                return (
                  <option value={value} key={`asset-type-${index}`}>
                    {label}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="filter">
            <label className="label" htmlFor="filterOwner">
              Owner
            </label>
            <select
              id="filterOwner"
              value={formData.filterOwner}
              required
              onChange={handleInputChange}
              name="filterOwner"
              className="select"
            >
              {ownerOptions.map((item, index) => {
                const { label, value } = item;
                return (
                  <option value={value} key={`owner-${index}`}>
                    {label}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <div className="actions-wrapper rto-management-actions-wrapper">
          <div className="actions">
            <button
              className="export theme-button "
              type="button"
              onClick={fetchFilteredData}
            >
              Filter
            </button>
          </div>
          <div className="actions">
            <a className="export theme-button" href="/dashboard/returned-asset">
              Returned Asset
            </a>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          {!isLoading && (
            <>
              {tableData && tableData.length > 0 ? (
                <SortableTable columns={columns} data={tableData} />
              ) : (
                <div className="container">
                  <p className="fs-4 m-5 text-secondary text-center">
                    No records available
                  </p>
                </div>
              )}
            </>
          )}
        </div>
      </div>

      <div
        className="modal fade"
        id="staticBackdropAssetRTO"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <AssetReturnRequestModal
              selectedAsset={selectedAsset}
              fetchList={fetchFilteredData}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssetReturnATO;
