import React, { useEffect, useState } from "react";

const ViewOODTimesheet = ({
  timesheet,
  showTotalTime,
  setTotalTime: _setTotalTime,
}) => {
  const [totalTime, setTotalTime] = useState({
    hours: "",
    minutes: "",
  });

  const calcTotalTime = () => {
    let totalHrs = 0;
    let totalMins = 0;

    timesheet?.map((task) => {
      totalHrs += parseInt(task.hours);
      totalMins += parseInt(task.minutes);
    });

    totalHrs += Math.floor(totalMins / 60);
    totalMins = totalMins % 60;

    const finalTotalTime = {
      hours: totalHrs,
      minutes: totalMins,
    };
    setTotalTime(finalTotalTime);
    _setTotalTime(finalTotalTime);
  };

  useEffect(() => {
    calcTotalTime();
  }, [timesheet]);

  return (
    <div className="d-flex flex-column gap-4">
      {showTotalTime && (
        <div className="timesheet-total-time d-flex gap-2 align-items-center justify-content-center">
          <span>Total Time</span>
          <div className="d-flex">
            <div
              className="d-flex align-items-center justify-content-center"
              style={{ gap: "2px" }}
            >
              <span>{totalTime.hours}</span>
              <span>Hrs</span>
            </div>
            {totalTime.minutes > 0 && (
              <>
                <span className="d-flex align-items-center justify-content-center px-2">
                  :
                </span>
                <div
                  className="d-flex align-items-center justify-content-center"
                  style={{ gap: "2px" }}
                >
                  <span>{totalTime.minutes}</span>
                  <span>Mins</span>
                </div>
              </>
            )}
          </div>
        </div>
      )}

      <div className="timesheet view-timesheet">
        <div className="d-grid">
          <div className="row timesheet-header">
            <div className="col-md-1">S.No.</div>
            <div className="col-md-9">Task</div>
            <div className="col-md-2">Time</div>
          </div>
          <div className="timesheet-content timesheet-content-view">
            {timesheet?.map((task, index) => {
              return (
                <div className="row timesheet-row inputs" key={index}>
                  <div className="col-md-1">{index + 1}</div>
                  <div className="col-md-9">{task.taskName}</div>
                  <div className="col-md-2">
                    <div className="d-flex">
                      <div
                        className="d-flex align-items-center justify-content-center"
                        style={{ gap: "2px" }}
                      >
                        <span>{task.hours}</span>
                        <span>Hrs</span>
                      </div>
                      {task.minutes > 0 && (
                        <>
                          <span className="d-flex align-items-center justify-content-center px-2">
                            :
                          </span>
                          <div
                            className="d-flex align-items-center justify-content-center"
                            style={{ gap: "2px" }}
                          >
                            <span>{task.minutes}</span>
                            <span>Mins</span>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewOODTimesheet;
