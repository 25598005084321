import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { TailSpin } from "react-loader-spinner";
import SortableTable from "../../components/SortableTable";
import NoRecordsAvailable from "../../components/NoRecordsAvailable";
import { getApi } from "../../utils/api";
import { API_LEAVE_GET_USER_OOD } from "../../config/Endpoints";
import { toast } from "react-toastify";
import { formatDate } from "../../utils/helpers";
import ViewTimesheetModal from "./Modals/ViewTimesheetModal";
import Pagination from "../../components/Pagination";

const OnOfficialDutyList = ({ title, description }) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const accessToken = Cookies.get("accessToken");
  const [selectedRequest, setSelectedRequest] = useState();
  const [formData, setFormData] = useState({
    status: "ALL",
  });
  const [timesheet, setTimesheet] = useState([]);

  const columns = [
    {
      key: "leaveDate",
      label: "On Official Duty-Date",
      compare: (a, b) =>
        new Date(a._original.startDate) - new Date(b._original.startDate),
    },
    { key: "status", label: "Status" },
    { key: "comment", label: "Comment" },
    {
      key: "timesheet",
      label: "Action",
      render: (item) => (
        <a
          data-bs-toggle="modal"
          data-bs-target="#staticBackdropViewTimesheet"
          onClick={() => {
            setTimesheet(item._original.taskList);
            setSelectedRequest(item._original);
          }}
        >
          View
        </a>
      ),
      sortable: false,
    },
  ];
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    setTableData(
      data?.map((item) => {
        let status = "";
        if (
          item.approverStatus === "REJECTED" ||
          item.leaveFinalStatus === "REJECTED"
        ) {
          status = "REJECTED";
        } else {
          status = item.leaveFinalStatus;
        }

        let comment = item.secondApprovalComment;

        if (item.approverStatus === "REJECTED") {
          comment = item.firstApprovalComment;
        }

        return {
          status: status,
          comment: comment,
          leaveDate: formatDate(item.startDate),
          _original: item,
        };
      }) || []
    );
  }, [data]);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(10);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      await getApi(
        API_LEAVE_GET_USER_OOD +
          `?status=${formData.status}&pageSize=${limit}&page=${currentPage}`,
        accessToken
      )
        .then((res) => {
          setData(res.data.data);
          setTotalPages(res.data.totalPages);
        })
        .catch((err) => {
          toast.error("Something went wrong please try again later", {
            position: toast.POSITION.TOP_RIGHT,
          });
          console.error(err);
        });
    } finally {
      setIsLoading(false);
    }
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleLimitChange = (e) => {
    setLimit(e.target.value);
    setCurrentPage(1);
  };

  useEffect(() => {
    fetchData();
  }, [currentPage, limit]);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="px-2 pt-4">
      {isLoading && (
        <div className="overlay">
          <div className="mt-5">
            <TailSpin
              height="100"
              width="100"
              radius="1"
              color="blue"
              ariaLabel="tail-spin-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
        </div>
      )}
      <div
        className="modal fade"
        id="staticBackdropViewTimesheet"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <ViewTimesheetModal
              timesheet={timesheet}
              selectedRequestId={selectedRequest?.laveId}
              status={selectedRequest?.leaveFinalStatus}
              onClose={() => fetchData()}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          {!isLoading && (
            <>
              {tableData && tableData.length > 0 ? (
                <>
                  <SortableTable
                    columns={columns}
                    data={tableData}
                    currentPage={currentPage}
                    limit={limit}
                  />
                  <div className="row">
                    <div className="col-md-3">
                      <div>
                        {/* Dropdown for selecting items per page */}
                        <label htmlFor="itemsPerPage">Items per page </label>
                        <select
                          id="itemsPerPage"
                          onChange={handleLimitChange}
                          value={limit}
                          className="border ms-3 w-25 p-2"
                        >
                          <option value={5}>5</option>
                          <option value={10}>10</option>
                          <option value={20}>20</option>
                          <option value={30}>30</option>
                          <option value={40}>40</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-9">
                      <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <div className="container">
                  <NoRecordsAvailable />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default OnOfficialDutyList;
