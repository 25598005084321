import { useEffect, useState } from "react";
import { TailSpin } from "react-loader-spinner";
import { useLocation, useParams } from "react-router-dom";
import PageTitle from "../../components/PageTitle";
import { capitalize, toggleMenu } from "../../utils/helpers";
import {
  availabilityOptions,
  ownerOptions,
  superOwnerOptions,
} from "../../config/filters";
import { getApi } from "../../utils/api";
import Cookies from "js-cookie";
import {
  API_ASSET_EXPORT,
  API_ASSET_FILTER_TOTAL,
} from "../../config/Endpoints";
import { toast } from "react-toastify";
import Breadcrumbs from "../../components/Breadcrumbs";
import SortableTable from "../../components/SortableTable";

const AssetTypeDetails = ({ title, description, assetTypeId }) => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const [assets, setAssets] = useState([]);
  const accessToken = Cookies.get("accessToken");
  const [assetType, setAssetType] = useState(location.state?.assetType);
  const [isAssetLaptop, setIsAssetLaptop] = useState(false);

  const ownerOptionsDefaultValue = ownerOptions[0].value;
  const availabilityOptionsDefaultValue = availabilityOptions[0].value;
  const superOwnerOptionsDefaultValue = superOwnerOptions[0].value;
  const [formData, setFormData] = useState({
    owner: ownerOptionsDefaultValue,
    availability: availabilityOptionsDefaultValue,
    superOwner: superOwnerOptionsDefaultValue,
  });

  const [ownerFilterOptions, setOwnerFilterOptions] = useState(ownerOptions);

  useEffect(() => {
    switch (formData.superOwner) {
      case "CAELIUS_OWNED":
        setOwnerFilterOptions([
          { value: "ALL", label: "All" },
          { value: "Caelius", label: "Caelius" },
          { value: "Consultant", label: "Consultant" },
          { value: "SQE", label: "SQE Labs" },
        ]);
        break;
      case "CLIENT_OWNED":
        setOwnerFilterOptions([{ value: "Salesforce", label: "Salesforce" }]);
        break;
      default:
        setOwnerFilterOptions(ownerOptions);
        setFormData((prevState) => ({
          ...prevState,
          owner: ownerOptionsDefaultValue,
        }));
    }
  }, [formData.superOwner]);

  const columns = [
    { key: "manufacturer", label: "Manufacturer" },
    { key: "model", label: "Model" },
    { key: "processor", label: "Processor", hidden: !isAssetLaptop },
    { key: "ram", label: "RAM", hidden: !isAssetLaptop },
    { key: "ssd", label: "SSD", hidden: !isAssetLaptop },
    { key: "serialNo", label: "Serial Number" },
    { key: "superOwner", label: "Super Owner" },
    { key: "owner", label: "Owner" },
    { key: "status", label: "Status" },
    { key: "availability", label: "Availability" },
  ];
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    setTableData(
      assets.map((asset) => ({
        manufacturer: asset.manufacture,
        model: asset.model,
        processor: asset.processor,
        ram: asset.ram,
        ssd: asset.ssd,
        serialNo: asset.serialNumber,
        superOwner: asset.owners?.ownerType,
        owner: asset.owner,
        status: capitalize(asset.status),
        availability: capitalize(asset.availability),
      })) || []
    );
  }, [assets]);

  useEffect(() => {
    fetchAssets();
  }, []);

  useEffect(() => {
    checkIsAssetLaptop();
  }, [assets]);

  const checkIsAssetLaptop = () => {
    try {
      if (!assets || assets.length < 1) return;

      setIsAssetLaptop(assets[0]?.assestType.ram || false);
      if (!assetType) {
        const assetType = assets[0]?.assestType.name;

        if (!assetType) {
          toast.error("An unexpected error ssoccurred", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }

        setAssetType(assetType);
      }
    } catch (error) {
      console.error(error);
      toast.error("An unexpected error occurred", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const fetchAssets = async () => {
    setIsLoading(true);
    const { owner, availability, superOwner } = formData;
    let url = `?owner=${owner}&assestTypeId=${id}`;

    if (availability !== availabilityOptionsDefaultValue) {
      url += `&availability=${availability}`;
    }
    if (superOwner !== superOwnerOptionsDefaultValue) {
      url += `&superOwner=${superOwner}`;
    }

    try {
      await getApi(API_ASSET_FILTER_TOTAL + url, accessToken)
        .then((res) => {
          setAssets(res.data.data);
          // setAssets([]);
        })
        .catch((err) => {
          console.error("err: ", err);
          toast.error("An unexpected error occurred", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleExport = async () => {
    setIsLoading(true);
    const { owner } = formData;

    try {
      await fetch(`${API_ASSET_EXPORT}?owner=${owner}&assestTypeId=${id}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/vnd.ms-excel",
        },
      })
        .then((response) => {
          if (!response.ok) {
            toast.error(" No Record Available!", {
              position: toast.POSITION.TOP_RIGHT,
            });
            throw new Error("Network response was not ok");
          }
          return response.blob();
        })
        .then((blob) => {
          // Create a URL object from the blob
          const url = URL.createObjectURL(blob);

          // Create a temporary link element to trigger the download
          const link = document.createElement("a");
          link.href = url;
          link.download = `asset_list.xlsx`;
          link.click();

          // Clean up the URL object
          URL.revokeObjectURL(url);
        })
        .catch((error) => {
          console.error("Error downloading file:", error);
          toast.error(error?.response?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="wrapper-body">
      {isLoading && (
        <div className="overlay">
          <div className="mt-5">
            <TailSpin
              height="100"
              width="100"
              radius="1"
              color="blue"
              ariaLabel="tail-spin-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
        </div>
      )}
      <div className="page-heading">
        <div id="showMenuBtn" className="collapse-button" onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <Breadcrumbs
          items={[
            { label: title, path: "/dashboard/assetOverview" },
            { label: assetType },
          ]}
        />
        {assets && (
          <div className="total">
            {assets.length == 1 ? "Total Asset" : "Total Assets"} :{" "}
            {assets.length}
          </div>
        )}
      </div>
      <div className="action-bar">
        <div className="filters-wrapper">
          <div className="filter">
            <label className="label" htmlFor="availabilityFilter">
              Super Owner
            </label>
            <div className="select">
              <select
                id="superOwnerFilter"
                value={formData.superOwner}
                required
                onChange={handleInputChange}
                name="superOwner"
              >
                {superOwnerOptions.map((item, index) => {
                  const { label, value } = item;
                  return (
                    <option value={value} key={`owner-${index}`}>
                      {label}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="filter">
            <label className="label" htmlFor="ownerFilter">
              Owner
            </label>
            <div className="select">
              <select
                id="ownerFilter"
                value={formData.owner}
                required
                onChange={handleInputChange}
                name="owner"
              >
                {ownerFilterOptions.map((item, index) => {
                  const { label, value } = item;
                  return (
                    <option value={value} key={`asset-${index}`}>
                      {label}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="filter">
            <label className="label" htmlFor="availabilityFilter">
              Availability
            </label>
            <div className="select">
              <select
                id="availabilityFilter"
                value={formData.availability}
                required
                onChange={handleInputChange}
                name="availability"
              >
                {availabilityOptions.map((item, index) => {
                  const { label, value } = item;
                  return (
                    <option value={value} key={`owner-${index}`}>
                      {label}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        </div>
        <div className="actions-wrapper">
          <div className="actions">
            <button
              className="export theme-button"
              type="button"
              onClick={fetchAssets}
            >
              Filter
            </button>
          </div>
          <div className="actions">
            <a
              disabled={assets?.length === 0}
              className="export theme-button"
              onClick={handleExport}
            >
              Export &nbsp;&nbsp;
              <img
                src="/assets/images/export-ico.svg"
                style={{ filter: "invert(1)" }}
                width="15"
                alt="Export"
              />
            </a>
          </div>
        </div>
      </div>
      {!isLoading && (
        <>
          {tableData && tableData.length > 0 ? (
            <SortableTable columns={columns} data={tableData} />
          ) : (
            <div className="fs-4 mt-5 text-secondary text-center">
              No records available
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default AssetTypeDetails;
