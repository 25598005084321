import React, { useEffect, useState } from "react";
import { TailSpin, ThreeDots } from "react-loader-spinner";
import { Link, useNavigate } from "react-router-dom";
import { getApi, postApi } from "../../utils/api";
import { API_REQUEST_ASSET, ASSET_TYPE_LIST } from "../../config/Endpoints";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { toggleMenu } from "../../utils/helpers";
import Footer from "../../components/Footer";
import PageTitle from "../../components/PageTitle";
import * as Yup from "yup";
import validations from "../../config/validation";

const AssetRequest = ({ title, description }) => {
  const [assetTypeList, setAssetTypeList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isListLoading, setIsListLoading] = useState(false);
  const accessToken = Cookies.get("accessToken");
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    selectedAssetId: "",
    reason: "",
  });
  const [errors, setErrors] = useState("");
  const validationSchema = Yup.object().shape({
    reason: validations.REASON_VALIDATION,
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

    setErrors((prevFormData) => ({
      ...prevFormData,
      [name]: "",
    }));
  };

  useEffect(() => {
    fetchAssetTypeList();
  }, []);

  const fetchAssetTypeList = async () => {
    setIsListLoading(true);
    await getApi(ASSET_TYPE_LIST, accessToken)
      .then((res) => {
        if (res.status === 200) {
          setAssetTypeList(res.data.data);
          setIsListLoading(false);
        }
      })
      .catch((e) => {
        console.log(e);
        setIsListLoading(false);
      });
  };

  const handleSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();

    try {
      let requestData = {
        assestTypeId: formData.selectedAssetId,
        reason: formData.reason,
      };

      await validationSchema.validate(formData, {
        abortEarly: false,
      });

      await postApi(API_REQUEST_ASSET, requestData, accessToken)
        .then((res) => {
          if (res.status === 200) {
            setIsLoading(false);
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            navigate("/dashboard/assetRequest");
          } else {
            setIsLoading(false);
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((e) => {
          setIsLoading(false);
          toast.error(
            e?.response?.data?.message
              ? e?.response?.data?.message
              : "Something went wrong please try again.",
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
        });
    } catch (error) {
      setIsLoading(false);
      const validationErrors = {};
      error.inner.forEach((err) => {
        validationErrors[err.path] = err.message;
      });
      setErrors(validationErrors);
    }
  };
  const handleReset = (e) => {
    e.preventDefault();
    setFormData({
      selectedAssetId: "",
      reason: "",
    });

    setErrors({});
  };

  return (
    <div className="wrapper-body">
      {(isListLoading || isLoading) && (
        <div className="overlay">
          <div className="mt-5">
            <TailSpin
              height="100"
              width="100"
              radius="1"
              color="blue"
              ariaLabel="tail-spin-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
        </div>
      )}

      <div className="page-heading">
        <div id="showMenuBtn" className="collapse-button" onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <PageTitle title={title} description={description} />
        <div className="total">
          <Link to="/dashboard/assetRequest" className="brand-color">
            Back to Asset Request List
          </Link>
        </div>
      </div>

      <form id="assign-asset" onSubmit={handleSubmit}>
        <div className="card">
          <h3 className="heading">Asset Request</h3>
          <div className="inputs">
            <div className="row mb-2">
              <div className="col-md-2"></div>
              <div className="col-md-2">
                <label htmlFor="assetType" className="has-asterisk">
                  Asset Type
                </label>
              </div>
              <div className="col-md-5">
                <div className="input-group">
                  <select
                    id="asset_list"
                    value={formData.selectedAssetId}
                    required
                    onChange={handleInputChange}
                    name="selectedAssetId"
                  >
                    <option value="">Select an asset type</option>
                    {assetTypeList &&
                      assetTypeList.map((item) => (
                        <option value={item.assestId} key={item.assestId}>
                          {item.name}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
              <div className="col-md-3"></div>
            </div>
            <div className="row ">
              <div className="col-md-2"></div>
              <div className="col-md-2 ">
                <label htmlFor="reason" className="has-asterisk">
                  Reason
                </label>
              </div>
              <div className="col-md-5">
                <div className="input-group">
                  <textarea
                    rows={4}
                    cols={5}
                    name="reason"
                    value={formData.reason}
                    required
                    onChange={handleInputChange}
                  ></textarea>
                  {errors.reason && (
                    <small className="text-danger">{errors.reason}</small>
                  )}
                </div>
              </div>
              <div className="col-md-3"></div>
            </div>
            {/* <div className="row">
              <div className="col-md-2"></div>
              <div className="col-md-2">
                <label htmlFor="project">Project</label>
              </div>
              <div className="col-md-5">
                <div className="input-group">
                  <input type="text" />
                </div>
              </div>
              <div className="col-md-3"></div>
            </div> */}
          </div>
          <br />

          <div className="action text-center">
            <button className="btn btn-secondary" onClick={handleReset}>
              Reset
            </button>
            <button
              className={
                isLoading ? "theme-button disabled ms-2 " : "theme-button ms-2 "
              }
              type="submit"
            >
              Submit
            </button>
          </div>
        </div>
      </form>
      <Footer />
    </div>
  );
};

export default AssetRequest;
