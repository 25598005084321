import { useEffect, useState } from "react";
import SortableTable from "../../components/SortableTable";
import NoRecordsAvailable from "../../components/NoRecordsAvailable";
import {
  API_APPROVE_ASSET_TYPE_REQUESTS,
  API_ASSET_TYPE_REQUESTS,
} from "../../config/Endpoints";
import axios from "axios";
import Cookies from "js-cookie";
import { formatDate } from "../../utils/helpers";
import * as Yup from "yup";
import validations from "../../config/validation";
import { toast } from "react-toastify";
import { TailSpin, ThreeDots } from "react-loader-spinner";

const ApproveAssetTypeRequests = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [requests, setRequests] = useState();
  const [selectedRequest, setSelectedRequest] = useState();
  const accessToken = Cookies.get("accessToken");

  const columns = [
    { key: "assetType", label: "Asset Type" },
    { key: "assetCategory", label: "Asset Category" },
    {
      key: "requestDate",
      label: "Request Date",
      compare: (a, b) =>
        new Date(a._original.creationDate) - new Date(b._original.creationDate),
    },
    { key: "comment", label: "Comment" },
    {
      key: "action",
      label: "Action",
      render: (item) => (
        <a
          data-bs-toggle="modal"
          data-bs-target="#staticBackdropAssetTypeRequestAction"
          onClick={() => {
            setSelectedRequest(item._original);
          }}
        >
          View
        </a>
      ),
      sortable: false,
    },
  ];
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    setTableData(
      requests?.map((item) => ({
        assetType: item.name,
        assetCategory: item.assetCategory,
        requestDate: formatDate(item.creationDate),
        comment: decodeURIComponent(item.comment),
        _original: item,
      })) || []
    );
  }, [requests]);

  const fetchRequests = async () => {
    setIsLoading(true);
    try {
      await axios
        .get(API_ASSET_TYPE_REQUESTS, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          params: {
            status: "PENDING",
          },
        })
        .then((res) => {
          setRequests(res.data.data);
        })
        .catch((err) => {});
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchRequests();
  }, []);

  return (
    <div className="px-2 py-4">
      {isLoading && (
        <div className="overlay">
          <div className="mt-5">
            <TailSpin
              height="100"
              width="100"
              radius="1"
              color="blue"
              ariaLabel="tail-spin-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
        </div>
      )}
      <div
        className="modal fade"
        id="staticBackdropAssetTypeRequestAction"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <AssetTypeRequestActionModal
              selectedRequest={selectedRequest}
              setRequests={setRequests}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          {!isLoading && (
            <>
              {tableData && tableData.length > 0 ? (
                <SortableTable columns={columns} data={tableData} />
              ) : (
                <div className="container">
                  <NoRecordsAvailable />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const AssetTypeRequestActionModal = ({
  selectedRequest: request,
  setRequests,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const formDataInitialState = {
    status: "",
    comment: "",
  };
  const [formData, setFormData] = useState(formDataInitialState);
  const [errors, setErrors] = useState({});
  const accessToken = Cookies.get("accessToken");

  const columns = [
    { key: "assetType", label: "Asset Type", sortable: false },
    { key: "assetCategory", label: "Asset Category", sortable: false },
    {
      key: "requestDate",
      label: "Request Date",
      sortable: false,
    },
    { key: "comment", label: "Comment" },
  ];
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    if (request) {
      setTableData(
        [request]?.map((item) => ({
          assetType: item.name,
          assetCategory: item.assetCategory,
          requestDate: formatDate(item.creationDate),
          comment: decodeURIComponent(item.comment),
        })) || []
      );
    }
  }, [request]);

  const validationSchema = Yup.object().shape({
    comment: validations.COMMENT_VALIDATION,
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      await validationSchema.validate(formData, {
        abortEarly: false,
      });

      await axios
        .post(
          API_APPROVE_ASSET_TYPE_REQUESTS,
          {},
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "Content-Type": "application/json",
            },
            params: {
              id: request.assestId,
              comment: formData.comment,
              status: formData.status,
            },
          }
        )
        .then((res) => {
          setRequests(res.data.data);
          const msg =
            formData.status === "APPROVED"
              ? `Request for ${request.name} asset type approved successfully`
              : `Request for ${request.name} asset type denied successfully`;
          toast.success(msg, {
            position: toast.POSITION.TOP_RIGHT,
          });

          const modalClose = document.getElementsByClassName("cancel-modal")[0];
          modalClose.click();

          handleReset();
        })
        .catch((err) => {
          toast.error("Something went wrong. Please try again!", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    } catch (error) {
      const validationErrors = {};
      error.inner.forEach((err) => {
        validationErrors[err.path] = err.message;
      });
      setErrors(validationErrors);
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    setErrors((prevFormData) => ({
      ...prevFormData,
      [name]: "",
    }));
  };

  const handleReset = () => {
    setFormData(formDataInitialState);
    setErrors({});
  };

  return (
    <form onSubmit={handleSubmit}>
      {isLoading && (
        <div className="overlay">
          <div className="loader-container">
            <TailSpin
              height="100"
              width="100"
              radius="1"
              color="blue"
              ariaLabel="tail-spin-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
        </div>
      )}
      <div className="modal-header">
        <h5 className="modal-title text-center flex" id="staticBackdropLabel">
          Asset Type Request Action
        </h5>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={handleReset}
        ></button>
      </div>
      <div className="modal-body">
        <div className="px-2 py-4">
          <div className="d-grid inputs gap-4">
            <SortableTable columns={columns} data={tableData} />
            <div className="input-group">
              <div className="row">
                <div className="col-md-2"></div>
                <div className="col-md-4 pt-1">
                  <label htmlFor="assetCategory" className="has-asterisk">
                    Action
                  </label>
                </div>
                <div className="col-md-4">
                  <select
                    id="status"
                    onChange={handleInputChange}
                    value={formData.status}
                    name="status"
                    className="border"
                    required
                  >
                    {[
                      { value: "", label: "Select Action" },
                      { value: "APPROVED", label: "Approve" },
                      { value: "REJECTED", label: "Reject" },
                    ].map((item, index) => {
                      const { value, label } = item;

                      return (
                        <option value={value} key={`status-${index}`}>
                          {label}
                        </option>
                      );
                    })}
                  </select>
                  {errors.category && (
                    <small className="text-danger">{errors.category}</small>
                  )}
                </div>
                <div className="col-md-2"></div>
              </div>
            </div>
            <div className="input-group">
              <div className="row">
                <div className="col-md-2"></div>
                <div className="col-md-4 pt-1">
                  <label htmlFor="comment" className="has-asterisk">
                    Comment
                  </label>
                </div>
                <div className="col-md-4">
                  <textarea
                    id="comment"
                    rows={3}
                    cols={5}
                    className="border"
                    name="comment"
                    value={formData.comment}
                    onChange={handleInputChange}
                    required
                  ></textarea>
                  {errors.comment && (
                    <small className="text-danger">{errors.comment}</small>
                  )}
                </div>
                <div className="col-md-2"></div>
              </div>
            </div>
          </div>
          <div className="action text-center mt-5"></div>
        </div>
      </div>
      <div className="justify-content-center modal-footer">
        <button
          className={isLoading ? "theme-button disabled " : "theme-button "}
          type="submit"
        >
          {isLoading ? (
            <ThreeDots
              height="25"
              width="80"
              radius="9"
              color="white"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          ) : (
            "Submit"
          )}
        </button>
        <button
          type="button"
          className="theme-button bg-grey mx-3 w-35 cancel-modal"
          data-bs-dismiss="modal"
          onClick={handleReset}
        >
          Cancel
        </button>
      </div>
    </form>
  );
};

export default ApproveAssetTypeRequests;
