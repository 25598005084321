import { useEffect, useState } from "react";
import { TailSpin } from "react-loader-spinner";
import { API_ASSET_TYPE_REQUESTS } from "../../config/Endpoints";
import Cookies from "js-cookie";
import axios from "axios";
import SortableTable from "../../components/SortableTable";
import NoRecordsAvailable from "../../components/NoRecordsAvailable";
import { formatDate } from "../../utils/helpers";
import CreateAssetTypeModal from "./Modals/CreateAssetTypeModal";

const CreateAssetType = ({ assetTypeList, setAssetTypeList }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [requests, setRequests] = useState();
  const accessToken = Cookies.get("accessToken");

  const fetchRequests = async () => {
    setIsLoading(true);
    try {
      await axios
        .get(API_ASSET_TYPE_REQUESTS, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          params: {
            status: "ALL",
          },
        })
        .then((res) => {
          setRequests(res.data.data);
        })
        .catch((err) => {});
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchRequests();
  }, []);

  const columns = [
    { key: "assetType", label: "Asset Type" },
    { key: "assetCategory", label: "Asset Category" },
    {
      key: "requestDate",
      label: "Request Date",
      compare: (a, b) =>
        new Date(a._original.creationDate) - new Date(b._original.creationDate),
    },
    {
      key: "approvedDate",
      label: "Approved Date",
      compare: (a, b) =>
        new Date(a._original.approvedDate) - new Date(b._original.approvedDate),
    },
    { key: "comment", label: "Comment" },
    { key: "status", label: "Status" },
  ];
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    setTableData(
      requests?.map((item) => ({
        assetType: item.name,
        assetCategory: item.assetCategory,
        requestDate: formatDate(item.creationDate),
        approvedDate: formatDate(item.approvedDate),
        comment: item.approverComment,
        status: item.status,
        _original: item,
      })) || []
    );
  }, [requests]);

  return (
    <div className="px-2 py-4">
      {isLoading && (
        <div className="overlay">
          <div className="mt-5">
            <TailSpin
              height="100"
              width="100"
              radius="1"
              color="blue"
              ariaLabel="tail-spin-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
        </div>
      )}
      <div
        className="modal fade"
        id="staticBackdropCreateAssetType"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <CreateAssetTypeModal
              assetTypeList={assetTypeList}
              setRequests={setRequests}
            />
          </div>
        </div>
      </div>
      <div className="action-bar" style={{ justifyContent: "flex-end" }}>
        <div className="actions-wrapper">
          <div className="actions">
            <button
              className="export theme-button "
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#staticBackdropCreateAssetType"
            >
              Create Asset Type
            </button>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          {!isLoading && (
            <>
              {tableData && tableData.length > 0 ? (
                <SortableTable columns={columns} data={tableData} />
              ) : (
                <div className="container">
                  <NoRecordsAvailable />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default CreateAssetType;
