import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { getApi } from "../../utils/api";
import { API_REQUEST_ASSET_LIST, ASSIGN_ASSET } from "../../config/Endpoints";
import { Link } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import AssetRequestForm from "./AssetRequestForm";
import { capitalize, formatDate, toggleMenu } from "../../utils/helpers";
import Pagination from "../../components/Pagination";
import Footer from "../../components/Footer";
import moment from "moment";
import GenerateProgress from "./GenerateProgress";
import PageTitle from "../../components/PageTitle";
import SortableTable from "../../components/SortableTable";

const AssetRequestList = ({ title, description }) => {
  const [employeeId, setEmployeeId] = useState("");
  const [assetList, setAssetList] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isProgressOpen, setIsProgressOpen] = useState(false);
  const [openProgressIndexes, setOpenProgressIndexes] = useState([]);
  const [assetProgressData, setAssetProgressData] = useState({});
  const [selectedIndex, setSelectedIndex] = useState();
  const accessToken = Cookies.get("accessToken");

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalRequest, setTotalRequest] = useState(0);
  const columns = [
    { key: "assetType", label: "Asset Type" },
    { key: "reason", label: "Reason" },
    {
      key: "requestedDate",
      label: "Requested Date",
      compare: (a, b) =>
        new Date(a._original.requestDate) - new Date(b._original.requestDate),
    },
    {
      key: "status",
      label: "Status",
      render: (item) => {
        return item._original.trackingStatus === "Approved2" ||
          item._original.trackingStatus === "Approved1"
          ? "In-Progress"
          : capitalize(item._original.trackingStatus);
      },
    },
    {
      key: "action",
      label: "Action",
      sortable: false,
      render: (item) => {
        const index = item._index;
        return (
          <>
            {!openProgressIndexes.includes(index) && (
              <a onClick={() => handleOpenProgress(index, item._original)}>
                View
              </a>
            )}
            {openProgressIndexes.includes(index) && (
              <a
                onClick={() => handleCloseProgress(index)}
                style={{
                  color: "blue",
                  fontWeight: "bolder",
                }}
              >
                Close
              </a>
            )}
          </>
        );
      },
    },
  ];
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    setTableData(
      filteredItems.map((item, index) => ({
        assetType: item.assestType,
        reason: item.reason,
        requestedDate: formatDate(item.requestDate),
        status: item.empName,
        _original: item,
        _index: index,
      })) || []
    );
  }, [filteredItems]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  useEffect(() => {
    fetchList();
  }, [currentPage, limit]);

  const handleLimitChange = (e) => {
    setLimit(e.target.value);
    setCurrentPage(1);
  };

  const fetchList = () => {
    setIsLoading(true);
    getApi(
      API_REQUEST_ASSET_LIST + `?pageSize=${limit}&page=${currentPage}`,
      accessToken
    )
      .then((res) => {
        if (res.status === 200) {
          setIsLoading(false);
          setAssetList(res.data.data);
          setFilteredItems(res.data.data);
          setTotalRequest(res.data.totalElements);
          setTotalPages(res.data.totalPages);
        }
      })
      .catch((e) => {
        console.log(e);
        setIsLoading(false);
      });
  };

  const handleSearch = (e) => {
    const searchValue = e.target.value;
    setSearchTerm(searchValue);
    const filtered = assetList.filter((item) =>
      item.assestType.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredItems(filtered);
  };

  const handleOpenProgress = (index, item) => {
    // Check if the index is already in openProgressIndexes
    if (!openProgressIndexes.includes(index)) {
      setAssetProgressData(item);
      setIsProgressOpen(true);
      setSelectedIndex(index);

      setOpenProgressIndexes([openProgressIndexes, index]);
    }
  };

  const handleCloseProgress = (index) => {
    // Remove the index from openProgressIndexes
    setAssetProgressData({});
    setIsProgressOpen(false);
    setSelectedIndex();
    setOpenProgressIndexes(openProgressIndexes.filter((idx) => idx !== index));
  };

  return (
    <>
      <div className="wrapper-body">
        {/* Page Heading */}
        <div className="page-heading">
          <div
            id="showMenuBtn"
            className="collapse-button"
            onClick={toggleMenu}
          >
            <span></span>
            <span></span>
            <span></span>
          </div>
          <PageTitle title={title} description={description} />
          <div className="total">
            Total Asset Request: <span>{totalRequest}</span>
          </div>
        </div>

        {/* Filters */}
        <div className="filters">
          {/* <form action="index.html" id="form_filters" className="d-f"> */}
          {/* Search By Company */}
          <div className="search">
            <input
              type="text"
              name="search"
              placeholder="Search By asset type"
              value={searchTerm}
              onChange={handleSearch}
            />
            <img src="/assets/images/search-ico.svg" width="14" alt="Search" />
          </div>

          {/* <button type="submit" form="form_filters">
          Search
        </button> */}
          {/* </form> */}

          {/* Actions */}
          <div className="actions">
            <Link
              to="/dashboard/createAssetRequest"
              className="export theme-button"
            >
              Asset Request
            </Link>
          </div>
        </div>

        {isLoading && (
          <div className="centered-loader">
            <ThreeDots
              height="100"
              width="100"
              radius="9"
              color="blue"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
        )}

        {/* Data Table */}
        {!isLoading && filteredItems?.length > 0 && (
          <>
            <SortableTable
              columns={columns}
              data={tableData}
              currentPage={currentPage}
              limit={limit}
            />
            <div className="row">
              <div className="col-md-3">
                <div>
                  {/* Dropdown for selecting items per page */}
                  <label htmlFor="itemsPerPage">Items per page </label>
                  <select
                    id="itemsPerPage"
                    onChange={handleLimitChange}
                    value={limit}
                    className="border ms-3 w-25  p-2"
                  >
                    <option value={5}>5</option>
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={30}>30</option>
                    <option value={40}>40</option>
                  </select>
                </div>
              </div>
              <div className="col-md-9">
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={handlePageChange}
                />
              </div>
            </div>
          </>
        )}
        {!isLoading && filteredItems?.length === 0 && (
          <div className="fs-4 text-secondary text-center">
            <h4>No Record Available</h4>
          </div>
        )}

        <div
          className="modal fade"
          id="staticBackdropAssetRequest"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-lg">
            <div className="modal-content">
              <AssetRequestForm />
            </div>
          </div>
        </div>
        {/* Footer */}
        <Footer />
      </div>
      {isProgressOpen && (
        <aside className="right-sidebar">
          <div
            className="mb-4 page-heading"
            onClick={() => handleCloseProgress(selectedIndex)}
          >
            <h1>{assetProgressData?.assestType}</h1>
            <span className="close_icon">&#10005;</span>
          </div>
          <div className="detail">
            <h5>{assetProgressData.employeeName}</h5>
            <h6>Status: {assetProgressData.trackingStatus}</h6>
          </div>

          <ul className="track-progress">
            <li className="approved">
              <h6>Requested</h6>
              <p>
                <small>15:30. {assetProgressData.requestDate}</small>
              </p>
              <p>{assetProgressData.reason}</p>
            </li>
            {GenerateProgress(assetProgressData)}
          </ul>
        </aside>
      )}
    </>
  );
};

export default AssetRequestList;
