import React, { useEffect, useState } from "react";
import { ASSET_TYPE_LIST, CREATE_ASSET } from "../../config/Endpoints";
import { getApi, postApi } from "../../utils/api";
import Cookies from "js-cookie";
import { Link, useNavigate } from "react-router-dom";
import { TailSpin, ThreeDots } from "react-loader-spinner";
import { toast } from "react-toastify";
import { toggleMenu } from "../../utils/helpers";
import Footer from "../../components/Footer";
import PageTitle from "../../components/PageTitle";
import * as Yup from "yup";
import validations from "../../config/validation";
import { storageUnitOptions, warrantyUnitOptions } from "../../config/filters";
import Input from "../../components/Input";

const CreateAssetSingle = ({ assetTypeList, setAssetTypeList }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLaptop, setIsLaptop] = useState(false);
  const [isListLoading, setIsListLoading] = useState(false);

  const warrantyUnitDefaultOption = warrantyUnitOptions[0].value;
  const storageUnitDefaultOption = storageUnitOptions[0].value;
  const formDataInitialState = {
    selectedAssetId: "",
    serialNumber: "",
    comment: "",
    manufacture: "",
    model: "",
    superOwner: "",
    owner: "",
    purchaseDate: "",
    purchaseCost: 0,
    warrantyExpired: "",
    warrantyExpiredUnit: warrantyUnitDefaultOption,
    processor: "",
    ram: "",
    ssd: "",
    ssdUnit: storageUnitDefaultOption,
  };

  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState(formDataInitialState);
  const navigate = useNavigate();
  const accessToken = Cookies.get("accessToken");

  const ownerOptions = [
    { value: "", label: "Select an owner" },
    { value: "Caelius", label: "Caelius" },
    { value: "Salesforce", label: "Salesforce" },
    { value: "Consultant", label: "Consultant" },
    { value: "SQE", label: "SQE Labs" },
  ];
  const superOwnerOptions = [
    { value: "", label: "Select a super owner" },
    { value: "CAELIUS_OWNED", label: "CAELIUS_OWNED" },
    { value: "CLIENT_OWNED", label: "CLIENT_OWNED" },
  ];

  const [ownerFilterOptions, setOwnerFilterOptions] = useState(ownerOptions);
  const [superOwnerFilterOptions, setSuperOwnerFilterOptions] =
    useState(superOwnerOptions);

  useEffect(() => {
    switch (formData.superOwner) {
      case "CAELIUS_OWNED":
        setOwnerFilterOptions([
          { value: "", label: "Select an owner" },
          { value: "Caelius", label: "Caelius" },
          { value: "Consultant", label: "Consultant" },
          { value: "SQE", label: "SQE Labs" },
        ]);
        break;
      case "CLIENT_OWNED":
        setOwnerFilterOptions([{ value: "Salesforce", label: "Salesforce" }]);
        setFormData((state) => ({ ...state, owner: "Salesforce" }));
        break;
      default:
        setOwnerFilterOptions(ownerOptions);
        setFormData((prevState) => ({
          ...prevState,
          owner: "",
        }));
    }
  }, [formData.superOwner]);

  // Function to get the current date in the format "YYYY-MM-DD"
  function getCurrentDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      let validationObj = {
        serialNumber: formData.serialNumber,
        manufacture: formData.manufacture,
        model: formData.model,
        purchaseCost: formData.purchaseCost,
        warrantyExpired: formData.warrantyExpired,
      };

      if (isLaptop) {
        validationObj = {
          ...validationObj,
          processor: formData.processor,
          ram: formData.ram,
          ssd: formData.ssd,
        };
      }

      await validationSchema.validate(validationObj, {
        abortEarly: false,
      });

      let requestData = {
        assestTypeId: formData.selectedAssetId,
        comments: formData.comment?.trim() || "",
        faultOrUpgradation: "",
        manufacture: formData.manufacture,
        model: formData.model,
        owner: formData.owner,
        superOwner: formData.superOwner,
        processor: formData.processor,
        purchaseDate: formData.purchaseDate,
        purchaseCost: formData.purchaseCost,
        ram: formData.ram ? formData.ram + " gb" : "",
        serialNumber: formData.serialNumber,
        ssd: formData.ssd ? formData.ssd + " " + formData.ssdUnit : "",
        warrantyExpired: formData.warrantyExpired
          ? formData.warrantyExpired + " " + formData.warrantyExpiredUnit
          : "",
      };

      await postApi(CREATE_ASSET, requestData, accessToken)
        .then((res) => {
          setIsLoading(false);
          setIsLaptop(false);
          setFormData(formDataInitialState);
          toast.success("Successfully Created!", {
            position: toast.POSITION.TOP_RIGHT,
          });
          navigate("/dashboard/assetAllocation");
        })
        .catch((e) => {
          toast.error(e?.response?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsLoading(false);
        });
    } catch (error) {
      const validationErrors = {};
      error.inner.forEach((err) => {
        validationErrors[err.path] = err.message;
      });
      setErrors(validationErrors);
      setIsLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    fetchAssetTypeList();
  }, []);

  const fetchAssetTypeList = async () => {
    setIsListLoading(true);
    await getApi(ASSET_TYPE_LIST, accessToken)
      .then((res) => {
        if (res.status === 200) {
          setAssetTypeList(res.data.data);
          setIsListLoading(false);
        }
      })
      .catch((e) => {
        console.log(e);
        setIsListLoading(false);
      });
  };

  const handleInputChange = (event) => {
    const { name, value, type } = event.target;

    if (type === "date") {
      const year = value.split("-")[0];
      if (year.length <= 4) {
        const currentDate = new Date().toISOString().split("T")[0];
        // If the entered date is greater than the current date, revert to the current date
        if (value > currentDate) {
          toast.error(
            "Future dates are not allowed. Input reverted to current date.",
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
          setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: currentDate,
          }));
        } else {
          setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
          }));
        }
      }
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
    if (name === "selectedAssetId") {
      let dummyData = assetTypeList.filter(
        (item) => `${item.assestId}` === value
      )[0];
      setIsLaptop(dummyData?.ram);
    }

    setErrors((prevFormData) => ({
      ...prevFormData,
      [name]: "",
    }));
  };

  const validationSchema = Yup.object().shape({
    serialNumber: validations.SERIAL_NUMBER_VALIDATION,
    manufacture: validations.MANUFACTURER_VALIDATION,
    model: validations.MODEL_VALIDATION,
    purchaseCost: validations.CURRENCY_VALIDATION,
    processor: validations.SPECIAL_AND_NUMBERS_VALIDATION.required(
      "Processor is required"
    ).optional(),
    ram: validations.RAM_VALIDATION.optional(),
    ssd: validations.storageValidation(formData.ssdUnit).optional(),
    warrantyExpired: validations.warrantyValidation(
      formData.warrantyExpiredUnit
    ),
    comment: Yup.string().transform((value) => value.trim()),
  });

  return (
    <div>
      {isListLoading && (
        <div className="overlay">
          <div className="mt-5">
            <TailSpin
              height="100"
              width="100"
              radius="1"
              color="blue"
              ariaLabel="tail-spin-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
        </div>
      )}
      <form id="assign-asset" onSubmit={handleSubmit}>
        <div className="px-2 py-4">
          <div className="inputs">
            <div className="d-f f-w inputs gap-15">
              <div className="input-group">
                <label htmlFor="asset_list" className="has-asterisk">
                  Asset Type
                </label>
                <select
                  id="asset_list"
                  value={formData.selectedAssetId}
                  required
                  onChange={handleInputChange}
                  name="selectedAssetId"
                >
                  <option value="">Select an asset type</option>
                  {assetTypeList &&
                    assetTypeList.map((item) => (
                      <option value={item.assestId} key={item.assestId}>
                        {item.name}
                      </option>
                    ))}
                </select>
              </div>
              <div className="input-group">
                <label htmlFor="model" className="has-asterisk">
                  Model
                </label>
                <input
                  type="text"
                  name="model"
                  onKeyPress={(e) => {
                    if (e.charCode === 32 && e.target.selectionStart === 0) {
                      e.preventDefault(); // Prevent adding space at the beginning
                    }
                  }}
                  value={formData.model}
                  onChange={handleInputChange}
                  required
                />
                {errors.model && (
                  <small className="text-danger">{errors.model}</small>
                )}
              </div>
              <div className="input-group">
                <label htmlFor="owner" className="has-asterisk">
                  Super Owner
                </label>
                <select
                  id="superOwner"
                  value={formData.superOwner}
                  required
                  onChange={handleInputChange}
                  name="superOwner"
                >
                  {superOwnerFilterOptions.map((item, index) => {
                    const { label, value } = item;
                    return (
                      <option value={value} key={`owner-${index}`}>
                        {label}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>

            <div className="d-f f-w inputs gap-15">
              <div className="input-group">
                <label htmlFor="owner" className="has-asterisk">
                  Owner
                </label>
                <select
                  id="owner"
                  value={formData.owner}
                  required
                  onChange={handleInputChange}
                  name="owner"
                >
                  {ownerFilterOptions.map((item, index) => {
                    const { label, value } = item;
                    return (
                      <option value={value} key={`owner-${index}`}>
                        {label}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="input-group">
                <label htmlFor="manufacture" className="has-asterisk">
                  Manufacturer
                </label>
                <input
                  type="text"
                  name="manufacture"
                  value={formData.manufacture}
                  onChange={handleInputChange}
                  required
                  onKeyPress={(e) => {
                    if (e.charCode === 32 && e.target.selectionStart === 0) {
                      e.preventDefault(); // Prevent adding space at the beginning
                    }
                  }}
                />
                {errors.manufacture && (
                  <small className="text-danger">{errors.manufacture}</small>
                )}
              </div>
              <div className="input-group">
                <label htmlFor="serialNumber" className="has-asterisk">
                  Serial Number
                </label>
                <Input
                  type="text"
                  name="serialNumber"
                  value={formData.serialNumber}
                  onChange={handleInputChange}
                  restrict={["space"]}
                  required
                />
                {errors.serialNumber && (
                  <small className="text-danger">{errors.serialNumber}</small>
                )}
              </div>
            </div>

            {isLaptop && (
              <div className="d-f f-w inputs gap-15">
                <div className="input-group">
                  <label htmlFor="processor" className="has-asterisk">
                    Processor
                  </label>
                  <input
                    type="text"
                    name="processor"
                    value={formData.processor}
                    onChange={handleInputChange}
                    required
                    onKeyPress={(e) => {
                      if (e.charCode === 32 && e.target.selectionStart === 0) {
                        e.preventDefault(); // Prevent adding space at the beginning
                      }
                    }}
                  />
                  {errors.processor && (
                    <small className="text-danger">{errors.processor}</small>
                  )}
                </div>
                <div className="input-group">
                  <label htmlFor="ram" className="has-asterisk">
                    RAM (GB)
                  </label>
                  <input
                    type="number"
                    name="ram"
                    value={formData.ram}
                    onChange={handleInputChange}
                    required
                  />
                  {errors.ram && (
                    <small className="text-danger">{errors.ram}</small>
                  )}
                </div>
                <div className="input-group">
                  <label htmlFor="ssd" className="has-asterisk">
                    SSD
                  </label>
                  <div className="d-flex gap-2">
                    <input
                      type="number"
                      name="ssd"
                      value={formData.ssd}
                      onChange={handleInputChange}
                      required
                    />
                    <select
                      id="ssdUnit"
                      onChange={handleInputChange}
                      value={formData.ssdUnit}
                      name="ssdUnit"
                      disabled={!formData.ssd}
                    >
                      {storageUnitOptions.map((item, index) => {
                        const { value, label } = item;

                        return (
                          <option value={value} key={`ssd-${index}`}>
                            {label}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  {errors.ssd && (
                    <small className="text-danger">{errors.ssd}</small>
                  )}
                </div>
              </div>
            )}
            <div className="d-f f-w inputs gap-15">
              <div className="input-group">
                <label htmlFor="warranty">Warranty</label>
                <div className="d-flex gap-2">
                  <input
                    type="number"
                    name="warrantyExpired"
                    value={formData.warrantyExpired}
                    onChange={handleInputChange}
                  />
                  <select
                    id="warrantyUnit"
                    onChange={handleInputChange}
                    value={formData.warrantyExpiredUnit}
                    name="warrantyExpiredUnit"
                    disabled={!formData.warrantyExpired}
                  >
                    {warrantyUnitOptions.map((item, index) => {
                      const { value, label } = item;

                      return (
                        <option value={value} key={`warranty-${index}`}>
                          {label}
                        </option>
                      );
                    })}
                  </select>
                </div>
                {errors.warrantyExpired && (
                  <small className="text-danger">
                    {errors.warrantyExpired}
                  </small>
                )}
              </div>
              <div className="input-group">
                <label htmlFor="purchaseCost" className="has-asterisk">
                  Purchase Cost
                </label>
                <input
                  type="text"
                  name="purchaseCost"
                  value={formData.purchaseCost}
                  onChange={handleInputChange}
                  required
                />
                {errors.purchaseCost && (
                  <small className="text-danger">{errors.purchaseCost}</small>
                )}
              </div>
              <div className="input-group">
                <label htmlFor="purchaseDate">Purchase Date</label>
                <input
                  type="date"
                  name="purchaseDate"
                  value={formData.purchaseDate}
                  placeholder="08/02/2019"
                  onChange={handleInputChange}
                  minLength={new Date()}
                  max={getCurrentDate()} // Set the max attribute to the current date
                />
              </div>
            </div>
            <div className="d-f f-w inputs gap-15">
              <div className="input-group" style={{ flex: 2 }}>
                <label htmlFor="comment">Comment</label>
                <textarea
                  id="comment"
                  name="comment"
                  value={formData.comment}
                  onChange={handleInputChange}
                  rows={5}
                  cols={30}
                ></textarea>
                {errors.comments && (
                  <small className="text-danger">{errors.comments}</small>
                )}
              </div>
            </div>
          </div>

          <div className="action text-center mt-5">
            <button
              className={isLoading ? "theme-button disabled " : "theme-button "}
              type="submit"
            >
              {isLoading ? (
                <ThreeDots
                  height="25"
                  width="80"
                  radius="9"
                  color="white"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={true}
                />
              ) : (
                "Submit"
              )}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CreateAssetSingle;
