import React, { useEffect, useState } from "react";
import { TailSpin, ThreeDots } from "react-loader-spinner";
import ViewOODTimesheet from "../../../components/ViewOODTimesheet";
import { toast } from "react-toastify";
import axios from "axios";
import {
  API_LEAVE_GET_DL_OOD,
  API_LEAVE_GET_HR_OOD,
} from "../../../config/Endpoints";
import Cookies from "js-cookie";
import { formatDate } from "../../../utils/helpers";
import SortableTable from "../../../components/SortableTable";
import * as Yup from "yup";
import validations from "../../../config/validation";

const ApproveOODModal = ({ type, timesheet, selectedEntry, onClose }) => {
  const [isLoading, setIsLoading] = useState(false);
  let formDataInitialState;
  switch (type) {
    case "DL":
      formDataInitialState = {
        action: "",
        comment: "",
      };
      break;
    case "HR":
      formDataInitialState = {
        action: "",
        comment: "",
        plAmount: "",
      };
      break;
    default:
      toast.error("Incorrect Configuration, Please contact portal admin.", {
        position: toast.POSITION.TOP_RIGHT,
      });
  }
  const [formData, setFormData] = useState(formDataInitialState);
  const [totalTime, setTotalTime] = useState({
    hours: "",
    minutes: "",
  });
  const accessToken = Cookies.get("accessToken");
  const [errors, setErrors] = useState("");
  const validationSchema = Yup.object().shape({
    comment: validations.COMMENT_VALIDATION,
  });

  let columns = [];
  switch (type) {
    case "HR":
      columns = [
        { key: "employeeName", label: "Employee Name", sortable: false },
        {
          key: "leaveDate",
          label: "On Official Duty-Date",
          sortable: false,
        },
        { key: "dlName", label: "Delivery Lead", sortable: false },
        { key: "dlComment", label: "Comment", sortable: false },
      ];

      break;
    case "DL":
      columns = [
        { key: "employeeName", label: "Employee Name" },
        {
          key: "leaveDate",
          label: "On Official Duty-Date",
          sortable: false,
        },
      ];
      break;
  }

  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    if (selectedEntry) {
      switch (type) {
        case "HR":
          setTableData(
            [selectedEntry]?.map((selectedEntry) => ({
              employeeName: selectedEntry.employeeName,
              leaveDate: formatDate(selectedEntry.startDate),
              dlName: selectedEntry.approverName,
              dlComment: selectedEntry.firstApprovalComment,
              _original: selectedEntry,
            })) || []
          );
          break;
        case "DL":
          setTableData(
            [selectedEntry]?.map((selectedEntry) => ({
              employeeName: selectedEntry.employeeName,
              leaveDate: formatDate(selectedEntry.startDate),
              dlComment: selectedEntry.firstApprovalComment,
              _original: selectedEntry,
            })) || []
          );
          break;
      }
    }
  }, [selectedEntry]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setIsLoading(true);

      await validationSchema.validate(formData, {
        abortEarly: false,
      });

      switch (type) {
        case "HR":
          const payload =
            formData?.action !== "REJECTED"
              ? {
                  comment: formData.comment,
                  amount: parseFloat(formData.plAmount),
                }
              : { comment: formData.comment };

          await axios
            .put(
              API_LEAVE_GET_HR_OOD +
                `?leaveId=${selectedEntry.laveId}&Status=${formData.action}`,
              payload,
              {
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                },
              }
            )
            .then((res) => {
              const status =
                formData.action === "APPROVED" ? "Approved" : "Rejected";
              toast.success(`On Official Duty request ${status}`, {
                position: toast.POSITION.TOP_RIGHT,
              });

              const modalClose =
                document.getElementsByClassName("cancel-modal")[0];
              modalClose.click();

              handleReset();
              onClose();
            })
            .catch((err) => {
              toast.error(
                err.response.data.message ||
                  "Something went wrong please try again later",
                {
                  position: toast.POSITION.TOP_RIGHT,
                }
              );
            });

          break;
        case "DL":
          await axios
            .put(
              API_LEAVE_GET_DL_OOD +
                `?leaveId=${selectedEntry.laveId}&Status=${formData.action}`,
              {
                comment: formData.comment,
              },
              {
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                },
              }
            )
            .then((res) => {
              const status =
                formData.action === "APPROVED" ? "Approved" : "Reject";
              toast.success(`On Official Duty request ${status}`, {
                position: toast.POSITION.TOP_RIGHT,
              });

              const modalClose =
                document.getElementsByClassName("cancel-modal")[0];
              modalClose.click();

              handleReset();
              onClose();
            })
            .catch((err) => {
              toast.error(
                err.response.data.message ||
                  "Something went wrong please try again later",
                {
                  position: toast.POSITION.TOP_RIGHT,
                }
              );
            });
          break;
      }
    } catch (error) {
      const validationErrors = {};
      error.inner.forEach((err) => {
        validationErrors[err.path] = err.message;
      });
      setErrors(validationErrors);
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setFormData((state) => ({
      ...state,
      [name]: value,
    }));

    setErrors((prevFormData) => ({
      ...prevFormData,
      [name]: "",
    }));
  };

  const handleReset = () => {
    setFormData(formDataInitialState);
    setErrors({});
  };

  return (
    <form onSubmit={handleSubmit}>
      {isLoading && (
        <div className="overlay">
          <div className="loader-container">
            <TailSpin
              height="100"
              width="100"
              radius="1"
              color="blue"
              ariaLabel="tail-spin-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
        </div>
      )}
      <div className="modal-header">
        <h5 className="modal-title text-center flex" id="staticBackdropLabel">
          Approve On Official Duty
        </h5>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={handleReset}
        ></button>
      </div>
      <div className="modal-body">
        <div className="px-2 py-4 d-flex flex-column gap-4">
          <div>
            <SortableTable columns={columns} data={tableData} />
            <div className="d-flex flex-column timesheet pb-4 gap-4">
              <div className="d-flex">
                <h2 className="label fs-6">Timesheet</h2>
                <div
                  className="timesheet-total-time d-flex gap-2 align-items-center justify-content-center"
                  style={{ backgroundColor: "#e5e9f1" }}
                >
                  <span>Total Time</span>
                  <div className="d-flex">
                    <div
                      className="d-flex align-items-center justify-content-center"
                      style={{ gap: "2px" }}
                    >
                      <span>{totalTime.hours}</span>
                      <span>Hrs</span>
                    </div>
                    {totalTime.minutes > 0 && (
                      <>
                        <span className="d-flex align-items-center justify-content-center px-2">
                          :
                        </span>
                        <div
                          className="d-flex align-items-center justify-content-center"
                          style={{ gap: "2px" }}
                        >
                          <span>{totalTime.minutes}</span>
                          <span>Mins</span>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>

              <ViewOODTimesheet
                timesheet={timesheet}
                setTotalTime={setTotalTime}
              />
            </div>
          </div>
          <div>
            <div className="row mt-3">
              <div className="col-md-2"></div>
              <div className="col-md-4">
                <label htmlFor="action" className="has-asterisk">
                  Action
                </label>
              </div>
              <div className="col-md-4">
                <select
                  id="action"
                  className="border"
                  required
                  name="action"
                  value={formData.action}
                  onChange={handleInputChange}
                >
                  <option value="">Select Action</option>
                  <option value="APPROVED">Approve</option>
                  <option value="REJECTED">Reject</option>
                </select>
              </div>
              <div className="col-md-2"></div>
            </div>
            {type === "HR" && formData?.action !== "REJECTED" && (
              <div className="row mt-3">
                <div className="col-md-2"></div>
                <div className="col-md-4">
                  <label htmlFor="plAmount" className="has-asterisk">
                    PL Credit
                  </label>
                </div>
                <div className="col-md-4">
                  <select
                    id="plAmount"
                    className="border"
                    required
                    name="plAmount"
                    value={formData.plAmount}
                    onChange={handleInputChange}
                  >
                    <option value="">Select PL-count</option>
                    <option value="0.5">0.5</option>
                    <option value="1">1</option>
                  </select>
                </div>
                <div className="col-md-2"></div>
              </div>
            )}
            <div className="row mt-3">
              <div className="col-md-2"></div>
              <div className="col-md-4">
                <label htmlFor="comment" className="has-asterisk">
                  Comment
                </label>
              </div>
              <div className="col-md-4">
                <textarea
                  value={formData.comment}
                  name="comment"
                  rows={3}
                  cols={5}
                  required
                  className="border"
                  onChange={handleInputChange}
                ></textarea>
                {errors.comment && (
                  <small className="text-danger">{errors.comment}</small>
                )}
              </div>
              <div className="col-md-2"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="justify-content-center modal-footer">
        <button
          className={isLoading ? "theme-button disabled " : "theme-button "}
          type="submit"
        >
          {isLoading ? (
            <ThreeDots
              height="25"
              width="80"
              radius="9"
              color="white"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          ) : (
            "Submit"
          )}
        </button>
        <button
          type="button"
          className="theme-button bg-grey mx-3 w-35 cancel-modal"
          data-bs-dismiss="modal"
          onClick={handleReset}
        >
          Cancel
        </button>
      </div>
    </form>
  );
};

export default ApproveOODModal;
