import Timesheets from "../Pages/AttendenceManagement/Timesheets";
import CreateEmployee from "../Pages/EmployeeOnboard/CreateEmployee";
import Performance from "../Pages/Employee/Performance";
import LeaveList from "../Pages/Leave/LeaveList";
import AssetList from "../Pages/AssetManagement/AssetList";
import Profile from "../Pages/Auth/Profile";
import AssignAsset from "../Pages/AssetManagement/AssignAsset";
import UpdateEmployeeOnboarding from "../Pages/EmployeeOnboard/UpdateEmployeeOnboarding";
import AssetDeallocation from "../Pages/AssetManagement/AssetDeallocation";
import CreateAsset from "../Pages/AssetManagement/CreateAsset";
import EmployeesOnboarding from "../Pages/EmployeeOnboard/EmployeesOnboarding";
import AdminProfile from "../Pages/Employee/AdminProfile";
import EmployeeProfile from "../Pages/EmployeeOnboard/EmployeeProfile";
import MainDashboard from "../Pages/MainDashboard";
import AssetDashboard from "../Pages/AssetManagement/AssetDashboard";
import PromoteEmployee from "../Pages/PromoteEmployee/PromoteEmployee";
import ProjectList from "../Pages/Project/ProjectList";
import CreateProject from "../Pages/Project/CreateProject";
import EmployeeList from "../Pages/Employee/EmployeeList";
import TimeSheetView from "../Pages/AttendenceManagement/TimeSheetView";
import ApproveLeave from "../Pages/Leave/ApproveLeave";
import AssetRequest from "../Pages/AssetManagement/AssetRequest";
import UploadSalary from "../Pages/Employee/UploadSalary";
import Reimbursement from "../Pages/ExpenseManagement/Reimbursement";
import AssetRequestList from "../Pages/AssetManagement/AssetRequestList";
import ApproveAssetRequestList from "../Pages/AssetManagement/ApproveAssetRequestList";
import ApproveReimbursementList from "../Pages/ExpenseManagement/ApproveReimbursementList";
import ReimbursementRequestList from "../Pages/ExpenseManagement/ReimbursementRequestList";
import ModifyPolicy from "../Pages/PolicyManagement/ModifyPolicy";
import ViewPolicy from "../Pages/PolicyManagement/ViewPolicy";
import UploadDocument from "../Pages/Employee/UploadDocument";
import LeaveUpdate from "../Pages/Leave/LeaveUpdate";
import FinalApproveLeave from "../Pages/Leave/FinalApproveLeave";
import DownloadReimbursement from "../Pages/ExpenseManagement/DownloadReimbursement";
import RoleList from "../Pages/Role/RoleList";
import MenuAssign from "../Pages/Menu/MenuAssign";
import PunchDetail from "../Pages/AttendenceManagement/PunchDetail";
import BulkPaid from "../Pages/ExpenseManagement/BulkPaid";
import EvaluateEmployee from "../Pages/Employee/EvaluateEmployee";
import EvaluationView from "../Pages/Employee/EvaluationView";
import RoleAssignmentToEmp from "../Pages/Employee/RoleAssignmentToEmp";
import AppraisalUpload from "../Pages/AppraisalManagement/AppraisalUpload";
import ApproveDocument from "../Pages/Employee/ApproveDocument";
import InvitationList from "../Pages/EmployeeOnboard/InvitationList";
import RaiseEmailRequest from "../Pages/RaiseEmailRequest/RaiseEmailRequest";
import EmailCreationRequestApproval from "../Pages/RaiseEmailRequest/EmailCreationRequestApproval";
import ApproveAssetRequestL1 from "../Pages/AssetLevelManagement/ApproveAssetRequestL1";
import ApproveAssetRequestL2 from "../Pages/AssetLevelManagement/ApproveAssetRequestL2";
import ApproveAssetRequestIT from "../Pages/AssetLevelManagement/ApproveAssetRequestIT";
import AssetDeliveryStore from "../Pages/AssetLevelManagement/AssetDeliveryStore";
import AssetRequestITApproverForm from "../Pages/AssetLevelManagement/AssetRequestITApproverForm";
import UsersRoleReport from "../Pages/ManagementReports/UsersRoleReport";
import AssetReport from "../Pages/ManagementReports/AssetReport";
import AssetReturnATO from "../Pages/AssetManagement/AssetReturnATO";
import AssetReturnedList from "../Pages/AssetManagement/AssetReturnedList";
import CreateAnnouncement from "../Pages/AnnouncementManagement/CreateAnnouncement";
import ViewAnnouncement from "../Pages/AnnouncementManagement/ViewAnnouncement";
import DepartmentList from "../Pages/Settings/DepartmentList";
import Designationlist from "../Pages/Settings/Designationlist";
import BiometricEmployeeProfile from "../Pages/Employee/Biometric/BiometricEmployeeProfile";
import EmployeeBiometricList from "../Pages/Employee/Biometric/EmployeeBiometricList";
import MyAttendance from "../Pages/AttendenceManagement/MyAttendance";
import ExportAttendance from "../Pages/AttendenceManagement/ExportAttendance";
import AssignMain from "../Pages/AssignManager/AssignMain";
import LeaveCreditRequest from "../Pages/AttendenceManagement/LeaveCreditRequest";
import ApproveLeaveCreditRequest from "../Pages/AttendenceManagement/ApproveLeaveCreditRequest";
import ReimbursementHistory from "../Pages/ExpenseManagement/ReimbursementHistory";
import OnLeaveToday from "../Pages/Leave/OnLeaveToday";
import HolidayList from "../Pages/AttendenceManagement/HolidayList";
import HolidayManagement from "../Pages/AttendenceManagement/HolidayManagement";
import ApplyRegularization from "../Pages/AttendenceManagement/ApplyRegularization";
import ApproveRegularization from "../Pages/AttendenceManagement/ApproveRegularization";
import TeamRegularizations from "../Pages/AttendenceManagement/TeamRegularizations";
import EmployeeEvent from "../Pages/Employee/Event/EmployeeEvent";
import AssetTypeDetails from "../Pages/AssetManagement/AssetTypeDetails";
import OnOfficialDuty from "../Pages/Leave/OnOfficialDuty";
import ApproveOnOfficialDutyHR from "../Pages/Leave/ApproveOnOfficialDutyHR";
import ApproveOnOfficialDutyDL from "../Pages/Leave/ApproveOnOfficialDutyDL";

// Prefix: /dashboard
export const dashboardRouteComponents = {
  "/": MainDashboard,
  "/myProfile": AdminProfile,
  "/performance": Performance,

  // Employee management
  "/employee-directory": EmployeeList,
  "/assignManager": AssignMain,
  "/promotionEmployee": PromoteEmployee,
  "/documentUpload": UploadDocument,
  "/performanceEvaluation": EvaluateEmployee,
  "/roleManagement": RoleAssignmentToEmp,
  "/approveDocument": ApproveDocument,
  "/department": DepartmentList,
  "/designation": Designationlist,

  // Asset management
  "/assetOverview": AssetDashboard,
  "/assetOverview/assetType/:id": AssetTypeDetails,
  "/assetAllocation": AssetList,
  "/assign-asset": AssignAsset,
  "/assetDeallocation": AssetDeallocation,
  "/assetRequest": AssetRequestList,
  "/createAssetRequest": AssetRequest,
  "/newAssetEnrollment": CreateAsset,
  "/approve-asset-request-l1": ApproveAssetRequestL1,
  "/approve-asset-request-l2": ApproveAssetRequestL2,
  "/approve-asset-request-it": ApproveAssetRequestIT,
  "/deliver-asset": AssetDeliveryStore,
  "/approve-asset-IT": AssetRequestITApproverForm,
  "/rto-managemrnt": AssetReturnATO,
  "/returned-asset": AssetReturnedList,

  // Attendance & leave management
  "/punchDetail": PunchDetail,
  "/leaveApply": LeaveList,
  "/approveLeave": ApproveLeave,
  "/finalAproverLeave": FinalApproveLeave,
  "/myAttendance": MyAttendance,
  "/exportAttendance": ExportAttendance,
  "/requestLeaveCredits": LeaveCreditRequest,
  "/approveLeaveCredits": ApproveLeaveCreditRequest,
  "/holiday-management": HolidayManagement,
  "/holiday-list": HolidayList,
  "/on-leave-today": OnLeaveToday,
  "/ood": OnOfficialDuty,
  "/ood-dl": ApproveOnOfficialDutyDL,
  "/ood-hr": ApproveOnOfficialDutyHR,
  "/applyRegularization": ApplyRegularization,
  "/my-team-regularization": TeamRegularizations,
  "/approve-regularization": ApproveRegularization,

  // Project management
  "/projectList": ProjectList,
  "/createProject": CreateProject,

  // Salary management
  "/uploadSalary": UploadSalary,

  // Reimbursement management
  "/reimbursementRequest": ReimbursementRequestList,
  "/createReimbursementRequest": Reimbursement,
  "/approveReimbursements": ApproveReimbursementList,
  "/bulkReimbursement": BulkPaid,
  "/reimbursement-history": ReimbursementHistory,
  "/downloadReimbursement": DownloadReimbursement,

  // Policy management
  "/PolicyEditor": ModifyPolicy,
  "/policyViewer": ViewPolicy,

  // Role management
  "/roleList": RoleList,
  "/menuAssign": MenuAssign,

  // Appraisal management
  "/uploadAppraisal": AppraisalUpload,

  // Employee onboarding
  "/onboardingForm": InvitationList,
  "/iTApproval": UpdateEmployeeOnboarding,
  "/hrSetup": EmployeesOnboarding,
  "/create-employee": CreateEmployee,
  "/emailSetupRequest": RaiseEmailRequest,
  "/approveEmailSetup": EmailCreationRequestApproval,
  "/biometirc-integration": EmployeeBiometricList,
  "/biometric-employee-profile": BiometricEmployeeProfile,

  // Management reports
  "/user-role-report": UsersRoleReport,
  "/asset-report": AssetReport,

  // Announcement management
  "/create-announcement": CreateAnnouncement,
  "/view-announcement": ViewAnnouncement,

  // Miscellaneous
  "/leave-list": LeaveUpdate,
  "/employee-event": EmployeeEvent,
  "/profile": Profile,
  "/evaluationView": EvaluationView,
  "/approveAssestRequest": ApproveAssetRequestList,
  "/employeeProfile": EmployeeProfile,
  "/timeSheet": Timesheets,
  "/timeSheetView": TimeSheetView,
};

// These routes are not managed by the database
export const dbIndependentRoutes = [
  { path: "/assign-asset", title: "Assign Asset", description: "" },
  { path: "/createAssetRequest", title: "", description: "" },
  { path: "/approve-asset-IT", title: "Approve Asset IT", description: "" },
  { path: "/returned-asset", title: "Returned Asset", description: "" },
  {
    path: "/applyRegularization",
    title: "Apply Regularization",
    description: "",
  },
  {
    path: "/my-team-regularization",
    title: "My Team Regularization",
    description: "",
  },
  {
    path: "/approve-regularization",
    title: "Approve Regularization",
    description: "",
  },
  { path: "/createProject", title: "Create Project", description: "" },
  { path: "/createReimbursementRequest", title: "", description: "" },
  { path: "/create-employee", title: "Create Employee", description: "" },
  { path: "/leave-list", title: "Leave Update", description: "" },
  { path: "/employee-event", title: "Employees Event", description: "" },
  { path: "/profile", title: "", description: "" },
  { path: "/evaluationView", title: "Evaluation", description: "" },
  {
    path: "/approveAssestRequest",
    title: "Approve Asset Request",
    description: "",
  },
  { path: "/employeeProfile", title: "", description: "" },
  { path: "/timeSheet", title: "Timesheets", description: "" },
  { path: "/timeSheetView", title: "Timesheet", description: "" },
  { path: "/biometric-employee-profile", title: "Timesheet", description: "" },
  {
    path: "/assetOverview/assetType/:id",
    title: "Asset Overview",
    description: "",
  },

  // {
  //   path: "/on-official-duty",
  //   title: "On Official Duty",
  //   description: "",
  // },
  // {
  //   path: "/approve-on-official-dl",
  //   title: "Approve On Official Duty DL",
  //   description: "",
  // },
  // {
  //   path: "/approve-on-official-hr",
  //   title: "Approve On Official Duty HR",
  //   description: "",
  // },
  // : OnOfficialDuty,
  //   : ApproveOnOfficialDutyDL,
  //   "/approve-on-official-hr": ApproveOnOfficialDutyHR,
];
