import { useEffect, useState } from "react";

const SortableTable = ({ columns, data, currentPage = 1, limit = 1 }) => {
  const [tableData, setTableData] = useState(data);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });

  useEffect(() => {
    setTableData(data);
  }, [data]);

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }

    const column = columns.find((col) => col.key === key);

    const sortedData = [...tableData].sort((a, b) => {
      // custom comparator
      if (column?.compare) {
        return direction === "asc"
          ? column.compare(a, b)
          : column.compare(b, a);
      }

      const valueA = a[key];
      const valueB = b[key];

      if (valueA == null) return direction === "asc" ? -1 : 1;
      if (valueB == null) return direction === "asc" ? 1 : -1;

      // String sorting (case-insensitive)
      if (typeof valueA === "string" && typeof valueB === "string") {
        return direction === "asc"
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      }

      // Number sorting
      if (typeof valueA === "number" && typeof valueB === "number") {
        return direction === "asc" ? valueA - valueB : valueB - valueA;
      }

      // Boolean sorting (false comes first, true comes after)
      if (typeof valueA === "boolean" && typeof valueB === "boolean") {
        return direction === "asc" ? valueA - valueB : valueB - valueA;
      }

      // Date sorting
      if (valueA instanceof Date && valueB instanceof Date) {
        return direction === "asc" ? valueA - valueB : valueB - valueA;
      }

      // Default fallback (stringify values and compare)
      return direction === "asc"
        ? String(valueA).localeCompare(String(valueB))
        : String(valueB).localeCompare(String(valueA));
    });

    setSortConfig({ key, direction });
    setTableData(sortedData);
  };

  return (
    <div className="table-responsive">
      <table className="resume custom">
        <thead>
          <tr>
            <th>S.No.</th>
            {columns
              .filter((col) => !col.hidden)
              .map((col) => (
                <th
                  key={col.key}
                  onClick={() => col.sortable !== false && handleSort(col.key)}
                  style={{
                    cursor: col.sortable !== false ? "pointer" : "default",
                  }}
                >
                  {col.label}{" "}
                  {sortConfig.key === col.key && col.sortable !== false ? (
                    sortConfig.direction === "asc" ? (
                      <span title="Ascending Order">▲</span>
                    ) : (
                      <span title="Descending Order">▼</span>
                    )
                  ) : (
                    ""
                  )}
                </th>
              ))}
          </tr>
        </thead>
        <tbody>
          {tableData.map((row, index) => (
            <tr key={index}>
              <td>{(currentPage - 1) * limit + index + 1}</td>
              {columns
                .filter((col) => !col.hidden)
                .map((col) => (
                  <td
                    key={col.key}
                    style={{
                      maxWidth: col.maxWidth || "400px",
                      wordWrap: "break-word",
                      whiteSpace: "normal",
                      overflowWrap: "break-word",
                    }}
                  >
                    {col.render
                      ? col.render(row)
                      : row[col.key] !== null && row[col.key] !== undefined
                      ? typeof row[col.key] === "string"
                        ? row[col.key].trim()
                        : row[col.key]
                      : "-"}
                  </td>
                ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default SortableTable;
