import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getApi, putApi } from "../../utils/api";
import Cookies from "js-cookie";
import {
  API_LEAVE_WITHDRAW,
  LEAVE_BALANCE,
  LEAVE_LIST,
} from "../../config/Endpoints";
import ApplyLeave from "./ApplyLeave";
import { TailSpin, ThreeDots } from "react-loader-spinner";
import { formatDate, formatLeaveType, toggleMenu } from "../../utils/helpers";

import Pagination from "../../components/Pagination";
import { getUser } from "../../utils/Storage";
import Footer from "../../components/Footer";
import { toast } from "react-toastify";
import TruncateText from "../../components/TruncateText";
import PageTitle from "../../components/PageTitle";
import * as Yup from "yup";
import validations from "../../config/validation";
import SortableTable from "../../components/SortableTable";

const LeaveList = ({ title, description }) => {
  const [leaveList, setLeaveList] = useState([]);
  const [leaveBalance, setLeaveBalance] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [filteredItems, setFilteredItems] = useState([]);
  const [selectedleaveRequest, setSelectedleaveRequest] = useState({});
  const [reason, setReason] = useState("");

  const accessToken = Cookies.get("accessToken");
  const userData = JSON.parse(getUser("userData"));
  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalLeaves, setTotalLeaves] = useState(0);
  const [limit, setLimit] = useState(10);
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "",
  });
  const [errors, setErrors] = useState({});

  const validationSchema = Yup.object().shape({
    reason: validations.REASON_VALIDATION,
  });

  const columns = [
    {
      key: "from",
      label: "From",
      compare: (a, b) =>
        new Date(a._original.startDate) - new Date(b._original.startDate),
    },
    {
      key: "to",
      label: "To",
      compare: (a, b) =>
        new Date(a._original.endDate) - new Date(b._original.endDate),
    },
    { key: "type", label: "Type" },
    { key: "reason", label: "Reason" },
    { key: "leaveCount", label: "Leave Count" },
    { key: "status", label: "Status" },
    {
      key: "action",
      label: "Action",
      render: (item) => {
        return item._original.leaveFinalStatus === "PENDING" ? (
          <a
            data-bs-toggle="modal"
            data-bs-target="#staticBackdropLeaveRequest"
            onClick={() => {
              setSelectedleaveRequest(item._original);
            }}
          >
            Withdraw
          </a>
        ) : (
          <p className="text-muted text-center fw-bolder">-</p>
        );
      },
      sortable: false,
    },
  ];
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    setTableData(
      filteredItems?.map((item) => {
        return {
          from: formatDate(item.startDate),
          to: formatDate(item.endDate),
          type: formatLeaveType(item.typeOfLeave),
          reason: item.reasonOfLeave,
          leaveCount: item.noOfDays,
          status:
            item.leaveFinalStatus === "WITHDRAW"
              ? "WITHDRAWN"
              : item.leaveFinalStatus,
          _original: item,
        };
      }) || []
    );
  }, [filteredItems]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  const handleLimitChange = (e) => {
    setLimit(e.target.value);
    setCurrentPage(1);
  };

  useEffect(() => {
    fetchLeaveBalance();
  }, []);

  useEffect(() => {
    fetchList();
  }, [currentPage, limit]);

  const fetchList = () => {
    setIsLoading(true);
    getApi(LEAVE_LIST + `?pageSize=${limit}&page=${currentPage}`, accessToken)
      .then((res) => {
        if (res.status === 200) {
          setLeaveList(res.data?.data);
          setFilteredItems(res.data?.data);
          setTotalPages(res.data?.totalPages);
          setTotalLeaves(res.data?.totalLeave);
        }
        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setIsLoading(false);
      });
  };

  const fetchLeaveBalance = () => {
    setIsLoading(true);
    getApi(LEAVE_BALANCE + `/${userData?.id}`, accessToken)
      .then((res) => {
        if (res.status === 200) {
          setLeaveBalance(res.data?.data);
        }
        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setIsLoading(false);
      });
  };

  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
    const sortedData = [...filteredItems].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === "ascending" ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === "ascending" ? 1 : -1;
      }
      return 0;
    });
    setFilteredItems(sortedData);
  };

  const handleReset = () => {
    setSelectedleaveRequest({});
    setReason("");
    setErrors({});
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      await validationSchema.validate(
        {
          reason: reason,
        },
        {
          abortEarly: false,
        }
      );

      await putApi(
        API_LEAVE_WITHDRAW +
          `?leaveId=${selectedleaveRequest?.laveId}&reason=${encodeURIComponent(
            reason
          )}`,
        {},
        accessToken
      )
        .then((res) => {
          setIsLoading(false);
          toast.success(res?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          fetchList();
          fetchLeaveBalance();
          const modalClose = document.getElementsByClassName(
            "cancel-modal-withdraw"
          )[0];
          modalClose.click();
        })
        .catch((e) => {
          setIsLoading(false);
          toast.error(e?.response?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    } catch (error) {
      const validationErrors = {};
      error.inner.forEach((err) => {
        validationErrors[err.path] = err.message;
      });

      setErrors(validationErrors);
      setIsLoading(false);
    }
  };

  return (
    <div className="wrapper-body">
      {/* Page Heading */}
      <div className="page-heading">
        <div id="showMenuBtn" className="collapse-button" onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <PageTitle title={title} description={description} />
        <div className="total">
          Total Requests : <span>{totalLeaves}</span>
        </div>
      </div>
      {/* Filters */}
      <div className="filters">
        {/* <form action="index.html" id="form_filters" className="d-f"> */}
        {/* Search By Company */}
        {/* <div className="search">
            <input type="text" name="search" placeholder="Search By Name."  />
            <img src="/assets/images/search-ico.svg" width="14" alt="Search" />
          </div>

          <button type="submit" form="form_filters">
            Search
          </button> */}
        {/* </form> */}
        <div className="search p-2">
          <span className="badge badge-danger">
            WFH (MTD):{" "}
            {leaveBalance?.workFromHomeLeave
              ? leaveBalance?.workFromHomeLeave
              : 0}
          </span>
          <span className="badge badge-success mx-2">
            WFH (QTD):{" "}
            {leaveBalance?.workFromHomeLeaveForQuarter
              ? leaveBalance?.workFromHomeLeaveForQuarter
              : 0}
          </span>
          <span className="badge badge-warning mx-2">
            Privilege Leave : {leaveBalance?.privilegeLeave}
          </span>
          <span className="badge badge-success mx-2">
            Unpaid Leave (MTD) : {leaveBalance?.unpaidLeaveBalanceMonth}
          </span>
          <span className="badge badge-warning mx-2">
            Unpaid Leave (YTD): {leaveBalance?.unpaidLeaveBalanceYear}
          </span>
          <span className="badge badge-danger mx-2">
            Half Yearly Leave : {leaveBalance?.halfYearlyLeave}
          </span>
        </div>
        {/* Actions */}
        <div className="actions">
          <Link
            className="export theme-button"
            data-bs-toggle="modal"
            data-bs-target="#staticBackdropApplyLeave"
          >
            Apply Leave
          </Link>
        </div>
      </div>
      {/* Data Table */}
      {!isLoading && filteredItems?.length > 0 && (
        <>
          <SortableTable
            columns={columns}
            data={tableData}
            currentPage={currentPage}
            limit={limit}
          />
          <div className="row">
            <div className="col-md-3">
              <div>
                {/* Dropdown for selecting items per page */}
                <label htmlFor="itemsPerPage">Items per page </label>
                <select
                  id="itemsPerPage"
                  onChange={handleLimitChange}
                  value={limit}
                  className="border ms-3 w-25  p-2"
                >
                  <option value={5}>5</option>
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value={30}>30</option>
                  <option value={40}>40</option>
                </select>
              </div>
            </div>
            <div className="col-md-9">
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
        </>
      )}
      {!isLoading && filteredItems?.length === 0 && (
        <div className="fs-4 text-secondary text-center">
          No Record Available
        </div>
      )}
      {isLoading && (
        <div className="centered-loader">
          <ThreeDots
            height="100"
            width="100"
            radius="9"
            color="blue"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
        </div>
      )}

      <div
        className="modal fade"
        id="staticBackdropApplyLeave"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <ApplyLeave
              fetchList={fetchList}
              leaveBalance={leaveBalance}
              fetchLeaveBalance={fetchLeaveBalance}
            />
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="staticBackdropLeaveRequest"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLeaveRequest"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <form onSubmit={handleSubmit}>
              {isLoading && (
                <div className="overlay">
                  <div className="loader-container">
                    <TailSpin
                      height="100"
                      width="100"
                      radius="1"
                      color="blue"
                      ariaLabel="tail-spin-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  </div>
                </div>
              )}
              <div className="modal-header">
                <h5
                  className="modal-title text-center flex"
                  id="staticBackdropLabel"
                >
                  Withdraw Leave Request
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handleReset}
                ></button>
              </div>
              <div className="modal-body">
                {Object.keys(selectedleaveRequest)?.length > 0 && (
                  <div className="table-responsive">
                    <table className="resume custom">
                      {/* Table Headings */}
                      <thead>
                        <tr>
                          <th>From</th>
                          <th>To</th>
                          <th>Type</th>
                          <th>Reason</th>
                          {/* <th>Approver</th> */}
                          <th> Leave Count</th>
                          <th>Status</th>
                        </tr>
                      </thead>

                      {/* Table Body */}
                      <tbody>
                        <tr>
                          <td>{formatDate(selectedleaveRequest?.startDate)}</td>
                          <td>{formatDate(selectedleaveRequest?.endDate)}</td>
                          <td>
                            {formatLeaveType(selectedleaveRequest?.typeOfLeave)}
                          </td>
                          <td style={{ maxWidth: "250px" }}>
                            {selectedleaveRequest?.reasonOfLeave}
                          </td>
                          {/* <td>{selectedleaveRequest?.approverName}</td> */}
                          <td>{selectedleaveRequest?.noOfDays}</td>
                          <td>{selectedleaveRequest?.leaveFinalStatus}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                )}

                <div className="row mt-3">
                  <div className="col-md-2"></div>
                  <div className="col-md-4">
                    <label htmlFor="reason" className="has-asterisk">
                      Reason
                    </label>
                  </div>
                  <div className="col-md-4">
                    <textarea
                      rows={3}
                      cols={5}
                      className="border"
                      name="reason"
                      value={reason}
                      onChange={(e) => {
                        setReason(e.target.value);
                        setErrors((prevFormData) => ({
                          ...prevFormData,
                          [e.target.name]: "",
                        }));
                      }}
                      required
                    ></textarea>
                    {errors.reason && (
                      <small className="text-danger">{errors.reason}</small>
                    )}
                  </div>
                  <div className="col-md-2"></div>
                </div>
              </div>

              <div className="justify-content-center modal-footer">
                <button
                  type="submit"
                  className={
                    isLoading ? "theme-button disabled " : "theme-button "
                  }
                >
                  Submit
                </button>
                <button
                  type="button"
                  className="theme-button bg-grey mx-3 w-35 cancel-modal-withdraw"
                  data-bs-dismiss="modal"
                  onClick={handleReset}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* Footer */}
      <Footer />
    </div>
  );
};

export default LeaveList;
