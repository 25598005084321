import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getApi, postApi } from "../../utils/api";
import { SIGN_IN, USER_PROFILE } from "../../config/Endpoints";
import Cookies from "js-cookie";
import * as Yup from "yup";
import { emailRegExp } from "../../utils/helpers";
import { TailSpin } from "react-loader-spinner";
import Footer from "../../components/Footer";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const [error, setError] = useState("");
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { state } = useLocation();
  const { message } = state || {};
  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await validationSchema.validate(
        { email, password },
        {
          abortEarly: false,
        }
      );
      setErrors("");
      let requestData = {
        email,
        password,
      };
      setIsLoading(true);
      await postApi(SIGN_IN, requestData)
        .then(async (res) => {
          Cookies.set("accessToken", res.data.accessToken, { expires: 7 });
          let userResponse = res.data.user;

          const userProfile = await fetchUser(res.data.accessToken); // Now this will return the user profile
          userResponse.photoPath = userProfile?.personalDetail?.photoPath;
          await localStorage.setItem("userData", JSON.stringify(userResponse));
        

          setEmail("");
          setPassword("");
          setIsLoading(false);
          navigate("/dashboard/");
        })
        .catch((e) => {
          setIsError(true);
          if (e?.response?.status === 403) {
            setError(e?.response?.data);
            setTimeout(() => {
              setIsError(false);
              navigate("/forgot-password");
            }, 4000);
          } else {
            setError(e?.response?.data.message);
            setTimeout(() => {
              setIsError(false);
            }, 4000);
          }

          setIsLoading(false);
        });
    } catch (error) {
      const validationErrors = {};
      error.inner.forEach((err) => {
        validationErrors[err.path] = err.message;
      });
      setIsLoading(false);
      setErrors(validationErrors);
    }
  };

  const fetchUser = async (accessToken) => {
    setIsLoading(true);
    try {
      const res = await getApi(USER_PROFILE, accessToken);
      if (res.status === 200) {
        return res.data.data; // Return the user profile data
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  const validateEmail = () => {
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
    if (!emailRegex.test(email)) {
      setError("Invalid email address");
    } else {
      setError("");
    }
  };
  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === "Enter") {
        document.getElementById("submitButton").click();
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  return (
    <div className="wrapper login_bg">
      {isLoading && (
        <div className="overlay">
          <div className="mt-5">
            <TailSpin
              height="100"
              width="100"
              radius="1"
              color="blue"
              ariaLabel="tail-spin-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
        </div>
      )}
      <div className="wrapper-body auth">
        <img
          src="https://www.caeliusconsulting.com/image/logo.svg"
          alt="Caelius Consulting Logo"
          width="150"
          height="35"
          style={{ margin: "0 auto" }}
        />
        <div className="login">
          {message && (
            <p
              className="text-center"
              style={{
                color: "#8186d5",
                fontSize: "14px",
                fontWeight: "bold",
              }}
            >
              {message}
            </p>
          )}
          <form id="loginForm">
            <input
              type="email"
              placeholder="Email Address"
              maxLength={100}
              required
              name="email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                setErrors((prevData) => ({ ...prevData, email: "" }));
              }}
              className={errors.email && "error-text"}
              autoComplete="false"
            />
            {errors.email && (
              <div className="error text-danger">{errors.email}</div>
            )}
            <div className="password-container">
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Enter Password"
                required
                name="password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                  setErrors((prevData) => ({ ...prevData, password: "" }));
                }}
                className={errors.password && "error-text"}
                autoComplete="false"
                style={{ paddingRight: "30px" }} // add some padding to make room for the icon
              />
              <span
                className="password-action"
                onClick={togglePasswordVisibility}
              >
                {showPassword ? (
                  <img src="/assets/images/hidden.png" alt="hidden" />
                ) : (
                  <img src="/assets/images/show.png" alt="show" />
                )}
              </span>
            </div>
            {errors.password && (
              <div className="error text-danger">{errors.password}</div>
            )}
            {/* <label className="remember">
              <input type="checkbox" id="remember" />
              &nbsp;Remember Me
            </label> */}
            <button
              type="submit"
              form="loginForm"
              className="theme-button"
              onClick={handleLogin}
              id="submitButton"
              disabled={isLoading}
            >
              Submit
            </button>
            <div className="text-center">
              {isError && (
                <div className="badge fs-6 text-danger mt-3">{error}</div>
              )}
            </div>
            <a href="/forgot-password" className="forgot-password">
              Forgot Password
            </a>
          </form>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Login;

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .required("Email address is required")
    .email("Invalid email address")
    .matches(/^$|.+@.+\..+/, "Invalid email address"),
  password: Yup.string().required("Password is required"),
});
