import { useEffect, useState } from "react";
import { toggleMenu } from "../../utils/helpers";
import Cookies from "js-cookie";
import { TailSpin, ThreeDots } from "react-loader-spinner";
import {
  API_PERFORMANCE_EXPORT,
  API_PERFORMANCE_VIEW,
} from "../../config/Endpoints";
import { getApi } from "../../utils/api";
import Pagination from "../../components/Pagination";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import PageTitle from "../../components/PageTitle";

const EvaluationView = ({title, description}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredItems, setFilteredItems] = useState();
  const [evaluationList, setEvaluationList] = useState();
  const accessToken = Cookies.get("accessToken");
  const [isLoading, setIsLoading] = useState(false);
  const [isExportLoading, setIsExportLoading] = useState(false);
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "",
  });

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalEvaluation, setTotalEvaluation] = useState(0);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  const handleLimitChange = (e) => {
    setLimit(e.target.value);
    setCurrentPage(1);
  };

  useEffect(() => {
    fetchList();
  }, [currentPage, limit]);

  const fetchList = () => {
    setIsLoading(true);
    getApi(
      API_PERFORMANCE_VIEW + `?pageSize=${limit}&page=${currentPage}`,
      accessToken
    )
      .then((res) => {
        if (res.status === 200) {
          setEvaluationList(res.data.data.data);
          setFilteredItems(res.data.data.data);
          setTotalEvaluation(res.data.data.totalPerformance);
          setTotalPages(res.data.data.totalPages);
          setIsLoading(false);
        }
      })
      .catch((e) => {
        console.log(e);
        setIsLoading(false);
      });
  };
  const handleSearch = (e) => {
    const searchValue = e.target.value;
    setSearchTerm(searchValue);
    const filtered = evaluationList.filter((item) =>
      item.employeeName.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredItems(filtered);
  };

  const handleExport = async () => {
    setIsExportLoading(true);
    await fetch(`${API_PERFORMANCE_EXPORT}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/vnd.ms-excel",
      },
    })
      .then((response) => {
        if (!response.ok) {
         
          toast.error(" No Record Available!", {
            position: toast.POSITION.TOP_RIGHT,
          });
          throw new Error("Network response was not ok");
        }
        return response.blob();
      })
      .then((blob) => {
        // Create a URL object from the blob
        const url = URL.createObjectURL(blob);

        // Create a temporary link element to trigger the download
        const link = document.createElement("a");
        link.href = url;
        link.download = `performance_evaluation.xlsx`;
        link.click();

        // Clean up the URL object
        URL.revokeObjectURL(url);
        setIsExportLoading(false);
      })
      .catch((error) => {
        console.error("Error downloading file:", error);
        setIsExportLoading(false);
        toast.error(error?.response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };
  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
    const sortedData = [...filteredItems].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === "ascending" ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === "ascending" ? 1 : -1;
      }
      return 0;
    });

    setFilteredItems(sortedData);
  };
  return (
    <div className="wrapper-body">
      {isExportLoading && (
        <div className="overlay">
          <div className="mt-5">
            <TailSpin
              height="100"
              width="100"
              radius="1"
              color="blue"
              ariaLabel="tail-spin-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
        </div>
      )}
      <div className="page-heading">
        <div id="showMenuBtn" className="collapse-button" onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <a
          className="me-2"
          style={{
            cursor: "pointer",
            fontWeight: "bolder",
            fontSize: "20px",
          }}
          href="/dashboard/evaluateEmployee"
        >
          &lt;
        </a>
        <PageTitle title={title} description={description} />
        {/* <div className="total">
          <Link to="/dashboard/evaluateEmployee" className="brand-color">
            Back to Evaluate Employee
          </Link>
        </div> */}
        <div className="total">Total Employee : {totalEvaluation} </div>
      </div>
      <div className="filters">
        <div className="search ">
          <input
            type="text"
            name="search"
            placeholder="Search by Name"
            value={searchTerm}
            onChange={handleSearch}
          />
          <img src="/assets/images/search-ico.svg" width="14" alt="Search" />
        </div>

        {/* Actions */}
        <div className="actions">
          <a
            disabled={filteredItems?.length === 0}
            className="export theme-button"
            onClick={handleExport}
          >
            Export &nbsp;&nbsp;
            <img
              src="/assets/images/export-ico.svg"
              style={{ filter: "invert(1)" }}
              width="15"
              alt="Export"
            />
          </a>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          {isLoading && (
            <div className="centered-loader">
              <ThreeDots
                height="100"
                width="100"
                radius="9"
                color="blue"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />
            </div>
          )}
          {!isLoading && filteredItems?.length === 0 && (
            <div className="fs-4 text-secondary text-center">
              No Record Available
            </div>
          )}
          {!isLoading && filteredItems?.length > 0 && (
            <>
              <div className="table-responsive">
                <table className="resume custom">
                  {/* Table Headings */}
                  <thead>
                    <tr>
                      <th>S.No.</th>
                      <th>
                        Name
                        <img
                          src="/assets/images/sort.png"
                          alt="sort"
                          width={18}
                          height={18}
                          onClick={() => handleSort("employeeName")}
                        />
                      </th>
                      <th>
                        Quantitative Score (11200)
                        <img
                          src="/assets/images/sort.png"
                          alt="sort"
                          width={18}
                          height={18}
                          onClick={() => handleSort("quantitativeScore")}
                        />
                      </th>
                      <th>
                        Quantitative %age
                        <img
                          src="/assets/images/sort.png"
                          alt="sort"
                          width={18}
                          height={18}
                          onClick={() => handleSort("quantativePer")}
                        />
                      </th>
                      <th>
                        Mock Interviews (200)
                        <img
                          src="/assets/images/sort.png"
                          alt="sort"
                          width={18}
                          height={18}
                          onClick={() => handleSort("mockInterviews")}
                        />
                      </th>
                      <th>
                        ClassRoom Session (200)
                        <img
                          src="/assets/images/sort.png"
                          alt="sort"
                          width={18}
                          height={18}
                          onClick={() => handleSort("classroom")}
                        />
                      </th>
                      <th>
                        CoffeeNLearn (300)
                        <img
                          src="/assets/images/sort.png"
                          alt="sort"
                          width={18}
                          height={18}
                          onClick={() => handleSort("coffeeNLearn")}
                        />
                      </th>
                      <th>
                        MuleSoft Quiz (300)
                        <img
                          src="/assets/images/sort.png"
                          alt="sort"
                          width={18}
                          height={18}
                          onClick={() => handleSort("muleSoftQuizRank")}
                        />
                      </th>
                      <th>
                        Meetups (300)
                        <img
                          src="/assets/images/sort.png"
                          alt="sort"
                          width={18}
                          height={18}
                          onClick={() => handleSort("muleSoftMeetups")}
                        />
                      </th>
                      <th>
                        Mentorship (300)
                        <img
                          src="/assets/images/sort.png"
                          alt="sort"
                          width={18}
                          height={18}
                          onClick={() => handleSort("mentors")}
                        />
                      </th>
                      <th>
                        Projects (300)
                        <img
                          src="/assets/images/sort.png"
                          alt="sort"
                          width={18}
                          height={18}
                          onClick={() => handleSort("project")}
                        />
                      </th>
                      <th>
                        Self Evaluation (300)
                        <img
                          src="/assets/images/sort.png"
                          alt="sort"
                          width={18}
                          height={18}
                          onClick={() => handleSort("selfEvaluation")}
                        />
                      </th>
                      <th>
                        Future Goals (300)
                        <img
                          src="/assets/images/sort.png"
                          alt="sort"
                          width={18}
                          height={18}
                          onClick={() => handleSort("futureGoal")}
                        />
                      </th>
                      <th>
                        Total Qualitative Score (2500)
                        <img
                          src="/assets/images/sort.png"
                          alt="sort"
                          width={18}
                          height={18}
                          onClick={() => handleSort("totalQualitativeScore")}
                        />
                      </th>
                      <th>
                        Qualitative %age
                        <img
                          src="/assets/images/sort.png"
                          alt="sort"
                          width={18}
                          height={18}
                          onClick={() => handleSort("qualitativePer")}
                        />
                      </th>
                      <th>
                        Total Score % age
                        <img
                          src="/assets/images/sort.png"
                          alt="sort"
                          width={18}
                          height={18}
                          onClick={() => handleSort("totalScore")}
                        />
                      </th>
                      <th>
                        Remarks
                        <img
                          src="/assets/images/sort.png"
                          alt="sort"
                          width={18}
                          height={18}
                          onClick={() => handleSort("remarks")}
                        />
                      </th>
                    </tr>
                  </thead>

                  {/* Table Body */}
                  <tbody>
                    {filteredItems &&
                      filteredItems?.map((item, index) => (
                        <tr key={index}>
                          <td data-title="Serial_No">
                            {(currentPage - 1) * limit + index + 1}
                          </td>
                          <td>{item.employeeName}</td>
                          <td>{item.quantitativeScore}</td>
                          <td>{item.quantativePer}</td>
                          <td>{item.mockInterviews}</td>
                          <td>{item.classroom}</td>
                          <td>{item.coffeeNLearn}</td>
                          <td>{item.muleSoftQuizRank}</td>
                          <td>{item.muleSoftMeetups}</td>
                          <td>{item.mentors}</td>
                          <td>{item.project}</td>
                          <td>{item.selfEvaluation}</td>
                          <td>{item.futureGoal}</td>
                          <td>{item.totalQualitativeScore}</td>
                          <td>{item.qualitativePer}</td>
                          <td>{item.totalScore}</td>
                          <td>{item.remarks}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <div>
                    {/* Dropdown for selecting items per page */}
                    <label htmlFor="itemsPerPage">Items per page </label>
                    <select
                      id="itemsPerPage"
                      onChange={handleLimitChange}
                      value={limit}
                      className="border ms-3 w-25  p-2"
                    >
                      <option value={5}>5</option>
                      <option value={10}>10</option>
                      <option value={20}>20</option>
                      <option value={30}>30</option>
                      <option value={40}>40</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-9">
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default EvaluationView;
