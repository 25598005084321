import React, { useEffect, useState } from "react";
import { getApi } from "../../utils/api";
import { ASSIGN_ASSET } from "../../config/Endpoints";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import { capitalize, toggleMenu } from "../../utils/helpers";
import Pagination from "../../components/Pagination";
import Footer from "../../components/Footer";
import PageTitle from "../../components/PageTitle";
import { toast } from "react-toastify";
import SortableTable from "../../components/SortableTable";
import { date } from "yup";
import NoRecordsAvailable from "../../components/NoRecordsAvailable";

const AssetList = ({ title, description }) => {
  const [assignAssetList, setAssignAssetList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredItems, setFilteredItems] = useState([]);

  const accessToken = Cookies.get("accessToken");
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "",
  });
  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalAssest, setTotalAssest] = useState(0);
  const columns = [
    { key: "assetType", label: "Asset Type" },
    { key: "serialNo", label: "Serial Number" },
    { key: "owner", label: "Owner" },
    { key: "employeeName", label: "Employee Name" },
    { key: "comment", label: "Comment" },
  ];
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    setTableData(
      filteredItems.map((item) => ({
        assetType: item.assestName,
        serialNo: item.serialNumber,
        owner: item.owner,
        employeeName: item.empName,
        comment: item.comments,
      })) || []
    );
  }, [filteredItems]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  const handleLimitChange = (e) => {
    setLimit(e.target.value);
    setCurrentPage(1);
  };

  useEffect(() => {
    fetchList();
  }, [currentPage, limit]);

  const fetchList = () => {
    setIsLoading(true);
    getApi(ASSIGN_ASSET + `?pageSize=${limit}&page=${currentPage}`, accessToken)
      .then((res) => {
        if (res.status === 200) {
          setAssignAssetList(res.data.data);
          setFilteredItems(res.data.data);
          setTotalAssest(res.data.totalAssest);
          setTotalPages(res.data.totalPages);
          setIsLoading(false);
        }
      })
      .catch((e) => {
        setIsLoading(false);
        toast.error(
          e?.response?.data?.message
            ? e?.response?.data?.message
            : "Something went wrong please try again !!",
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
      });
  };

  const handleSearch = (e) => {
    const searchValue = e.target.value;
    setSearchTerm(searchValue);
    const filtered = assignAssetList.filter(
      (item) =>
        item.assestName.toLowerCase().includes(searchValue.toLowerCase()) ||
        item.empName.toLowerCase().includes(searchValue.toLowerCase()) ||
        item.serialNumber.toLowerCase().includes(searchValue.toLowerCase()) ||
        item.owner.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredItems(filtered);
  };

  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
    const sortedData = [...filteredItems].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === "ascending" ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === "ascending" ? 1 : -1;
      }
      return 0;
    });

    setFilteredItems(sortedData);
  };

  return (
    <div className="wrapper-body">
      {/* Page Heading */}
      <div className="page-heading">
        <div id="showMenuBtn" className="collapse-button" onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <PageTitle title={title} description={description} />
        <div className="total">
          Total Asset Assigned : <span>{totalAssest}</span>
        </div>
      </div>

      {/* Filters */}
      <div className="filters">
        {/* <form action="index.html" id="form_filters" className="d-f"> */}
        {/* Search By Company */}
        <div className="search">
          <input
            type="text"
            name="search"
            placeholder="Search By Asset type, Serial Number, Owner or Employee Name"
            value={searchTerm}
            onChange={handleSearch}
          />
          <img src="/assets/images/search-ico.svg" width="14" alt="Search" />
        </div>

        {/* <button type="submit" form="form_filters">
            Search
          </button> */}
        {/* </form> */}

        {/* Actions */}
        <div className="actions">
          <Link to="/dashboard/assign-asset" className="export theme-button">
            Assign Asset
          </Link>
          {/* <Link to="/dashboard/create-asset" className="export theme-button">
            Create Asset
          </Link> */}
        </div>
      </div>

      {isLoading && (
        <div className="centered-loader">
          <ThreeDots
            height="100"
            width="100"
            radius="9"
            color="blue"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
        </div>
      )}

      {/* Data Table */}
      {!isLoading && filteredItems?.length > 0 && (
        <>
          <SortableTable
            columns={columns}
            data={tableData}
            currentPage={currentPage}
            limit={limit}
          />
          <div className="row">
            <div className="col-md-3">
              <div>
                {/* Dropdown for selecting items per page */}
                <label htmlFor="itemsPerPage">Items per page </label>
                <select
                  id="itemsPerPage"
                  onChange={handleLimitChange}
                  value={limit}
                  className="border ms-3 w-25 p-2"
                >
                  <option value={5}>5</option>
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value={30}>30</option>
                  <option value={40}>40</option>
                </select>
              </div>
            </div>
            <div className="col-md-9">
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
        </>
      )}
      {!isLoading && filteredItems === null && (
        <div className="fs-4 text-secondary text-center">
          <h4>No Record Available.</h4>
        </div>
      )}
      {!isLoading && filteredItems?.length === 0 && <NoRecordsAvailable />}

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default AssetList;
