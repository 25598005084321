import React, { useRef, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import { toast } from "react-toastify";
import { postApi } from "../../utils/api";
import { API_BULK_CREATE_ASSET } from "../../config/Endpoints";
import Cookies from "js-cookie";
import axios from "axios";

const CreateAssetBulk = () => {
  const modalBtnRef = useRef(null);
  const [modalMessage, setModalMessage] = useState({
    data: [],
    message: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const fileInputRef = useRef(null);
  const formDataInitialState = {
    file: "",
  };
  const [formData, setFormData] = useState(formDataInitialState);
  const accessToken = Cookies.get("accessToken");

  const handleShowModal = () => {
    modalBtnRef.current.click();
  };

  const acceptedFileType = [
    // 97-2003 (.xls)
    { type: "application / vnd.ms - excel", label: ".xls" },
    // 2007+ (.xlsx)
    {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      label: ".xlsx",
    },
  ];

  const acceptedFileTypesMessage = acceptedFileType
    .map((item) => item.label)
    .join(", ");

  // 8mb
  const maxFileSizeInMB = 0;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const file = formData.file;
    if (!file) {
      toast.error(`An unexpected error occurred.`, {
        position: toast.POSITION.TOP_RIGHT,
      });

      return;
    }

    await axios
      .post(API_BULK_CREATE_ASSET, formData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        const data = res.data.data;

        setModalMessage({
          data: data.data,
          message: data.message,
        });
        handleShowModal();
        // toast.success(file.name + " Uploaded Successfully.", {
        //   position: toast.POSITION.TOP_RIGHT,
        // });
      })
      .catch((err) => {
        toast.error("Something went wrong. Please try again!", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
    handleReset();

    setIsLoading(false);
    fileInputRef.current.value = null;
  };

  const handleChange = (e) => {
    const file = e.target.files[0];
    const maxFileSizeInBytes = maxFileSizeInMB * 1024 * 1024;

    // file exceeds the maximum size limit
    if (file) {
      if (parseInt(maxFileSizeInMB) > 0 && file.size > maxFileSizeInBytes) {
        toast.error(
          `File size exceeds the maximum limit of ${maxFileSizeInMB}MB.`,
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
        e.target.value = null;
      } else if (acceptedFileType.some((item) => item.type === file.type)) {
        setFormData({ ...formData, [e.target.name]: file });
      } else {
        toast.error(
          `Invalid file type. Please upload valid ${acceptedFileTypesMessage} file only.`,
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
        e.target.value = null;
      }
    }
  };

  const handleReset = () => {
    setFormData(formDataInitialState);
    fileInputRef.current.value = null;
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="px-2 py-4">
          <div className="d-flex justify-content-center align-items-center">
            <div style={{ width: "min(500px, 100%)" }}>
              <div className="inputs">
                <div className="input-group">
                  <label htmlFor="manufacture" className="has-asterisk">
                    Bulk Asset File
                  </label>
                  <input
                    type="file"
                    name="file"
                    onChange={handleChange}
                    required
                    disabled={isLoading}
                    ref={fileInputRef}
                  />
                  <small className="text-danger mt-1">
                    Note: Please upload valid{" "}
                    <strong>{acceptedFileTypesMessage}</strong> file only
                    {parseInt(maxFileSizeInMB) > 0 && (
                      <span>
                        {" "}
                        (max <strong>{maxFileSizeInMB} MB</strong>)
                      </span>
                    )}
                    .
                  </small>
                </div>
              </div>
            </div>
          </div>
          <div className="action text-center mt-5">
            <button
              className={isLoading ? "theme-button disabled " : "theme-button "}
              type="submit"
            >
              {isLoading ? (
                <ThreeDots
                  height="25"
                  width="80"
                  radius="9"
                  color="white"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={true}
                />
              ) : (
                "Submit"
              )}
            </button>
          </div>
        </div>
      </form>
      <button
        className="open-modal-upload"
        data-bs-toggle="modal"
        data-bs-target="#res-error-modal"
        style={{ display: "none" }}
        ref={modalBtnRef}
      >
        click
      </button>
      <ResponseErrorModal {...modalMessage} />
    </div>
  );
};

const ResponseErrorModal = ({ data, message }) => {
  return (
    <div
      className="modal fade"
      id="res-error-modal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div
              className="p-4 d-flex flex-column justify-content-center align-items-center gap-15"
              style={{
                maxHeight: "calc(90vh - 150px)",
                backgroundColor: "#ffffff",
              }}
            >
              <h1 className="fs-5">{message}</h1>
              {data && data.length > 0 && (
                <div className="d-flex flex-column w-100 h-100 ">
                  <label className="label">
                    {data.length == 1 ? "Serial Number" : "Serial Numbers"}
                  </label>
                  <div
                    className="d-flex"
                    style={{ maxHeight: "80%", overflowY: "auto" }}
                  >
                    <ol>
                      {data?.map((item) => (
                        <li>{item}</li>
                      ))}
                    </ol>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="justify-content-center modal-footer">
            <button
              type="button"
              className="theme-button bg-grey mx-3 w-35"
              data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateAssetBulk;
